export default {
  title0001: 'Order number',
  title0002: 'Payment order number',
  title0003: 'Whether after-sales',
  title0004: 'After-sales order number',
  title0005: 'Delivery method',
  title0006: 'Order Status',
  title0007: 'Store',
  title0008: 'User ID',
  title0009: 'User Phone',
  title0010: 'Member card number',
  title0011: 'Amount due',
  title0012: 'Order time',
  title0013: 'Order channel',
  title0014: 'Ebit status',
  title0015: 'Bulk Export',
  title0016: 'Order amount',
  title0017: 'Is this an out-of-stock order',
  title0018: 'Is this a timeout order',
  title0019: 'Is picking timeout',
  title0020: 'Chargeback status',
  title0021: 'Refund status for cancelled orders',
  title0022: 'Apply for after-sales service',
  title0023: 'Out of stock details',
  title0024: 'Order number',
  title0025: 'Product',
  title0026: 'Product front-end display name',
  title0027: 'UPC Code',
  title0028: 'Product ID',
  title0029: 'Frontend Category',
  title0030: 'Product Type',
  title0031: 'Order Quantity',
  title0032: 'Order weight',
  title0033: 'selling price',
  title0034: 'Actual Pick Quantity',
  title0035: 'Actual picking weight',
  title0036: 'Actual picking amount',
  title0037: 'Picking result',
  title0038: 'Timeout details',
  title0039: 'Receiving address',
  title0040: 'Estimated driver delivered time',
  title0041: 'Timeout time',
  title0042: 'Delivery fee',
  title0043: 'Additional delivery fee',
  title0044: 'Total delivery fee',
  title0045: 'Store selection',
  title0046: 'Search for stores',
  title0047: 'Selected stores',
  title0048: 'Store name',
  title0049: 'Store code',
  title0050: 'Operation',
  title0051: 'Remove',
  title0052: 'Chargeback',
  title0053: 'Refund amount',
  title0054: 'Refund',
  title0055: 'View out-of-stock details',
  title0056: 'View timeout details',
  title0057: 'Failed',
  title0058: 'Refund request',
  title0059: 'Success',
  title0060: 'Product ID',
  title0061: 'Out of stock',
  title0062: 'Normal',
  title0063: 'No',
  title0064: 'Yes',
  title0065:
    'Please select at least one record that meets the operating conditions',
  title0066: 'Please enter the store name or store code',
  title0067: 'Please select the product for refund',
  title0068: 'Main image of the product',
  title0069: 'Specifications',
  title0070: 'main UPC code',
  title0071: 'Price per unit quantity',
  title0072: 'Is it a free product',
  title0073: 'Main item name',
  title0074: 'Please fill in the reason for refund',
  title0075: 'Products in the order',
  title0076: 'Please fill in the actual picking quantity & weight',
  title0077: 'Picking',
  title0078: 'Self-pickup/Daily Delivery Time',
  title0079: 'Delivery notes',
  title0080: 'Order notes',
  title0081: 'Notes',
  title0082: 'Check',
  title0083: 'Quantity',
  title0084: 'Weight',
  title0085: 'totalAmount',
  title0086: 'Capture Time',
  title0087: 'Cancel order',
  title0088: 'Self-pickup/Daily Delivery Time (Store time zone)',
  title0089: 'Complete order',
  refundCount: 'Quantity after sale',
  refundCountError: 'Please Input',
};
