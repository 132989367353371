/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type pageQueryPointsFlowBody = defs.account.PageParam;
export type pageQueryPointsFlowPath = {
  /** id */
  id: number;
};
export type pageQueryPointsFlowOptions = Record<string, any>;

export type pageQueryPointsFlowResponse = Promise<
  defs.account.Response<
    defs.account.PageInfo<defs.account.PlatformAccountPointsFlowVO>
  >
>;

/**
 * @desc 分页查询积分预算变动流水
 */
export function request(
  body: pageQueryPointsFlowBody,
  path: pageQueryPointsFlowPath,
  options?: pageQueryPointsFlowOptions,
): pageQueryPointsFlowResponse {
  const host = getEnvHost();
  const url =
    host +
    '/account/bo/platform-points/account/points/flow/page/{id}'.replace(
      /\{[\w]+\}/,
      String(path.id),
    );
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
