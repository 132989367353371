export default {
  afterSalesOrderNumber: '售后单号',
  orderNumber: '订单号',
  applicationTime: '申请时间',
  refundTime: '退款时间',
  actualRefundAmount: '实退金额',
  refundableAmount: '应退金额',
  aftermarketType: '售后类型',
  afterSalesStatus: '售后状态',
  shopName: '商家名称',
  businessType: '商家类型',
  storeName: '店铺名称',
  storeCode: '店铺编码',
  storeGroup: '门店分组名称',
  userID: '用户ID',
  userPhone: '用户手机号',
  sku: 'SKU个数',
  shipFromCity: 'ship from(city)',
  shipFromState: 'ship from(state)',
  shipToCity: 'ship to(city)',
  shipToState: 'ship to(state)',
  export: '导出',
  orderDeductionTime: '订单扣款时间',
};
