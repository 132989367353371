// 积分规则配置
export default {
  id: 'Rule Id',
  ruleName: 'Rule Name',
  ruleDescription: 'Rule Description',
  ruleLogic: 'Rule Logic',
  updater: 'Updater',
  latestUpdate: 'Latest Update',
  operations: 'Operations',
  edit: 'Edit',
  enName: 'Name (EN)',
  cnName: 'Name (CN)',
  twName: 'Name (TW)',
  coupon: 'Coupon',
  quantityIssuedPerMonth: 'Quantity Issued Per Month',
  freeShippingThreshold: 'Free Shipping Threshold',
  switch: 'Switch',
  ruleConfig: 'Rules Config',
  namePlaceholder: 'Please Input Rule Name',
  descriptionPlaceholder: 'Please Input Rule Description',
  couponPlaceholder: 'Please Select Coupon',
  quantityPlaceholder: 'Please Input The Quantity',
  amountPlaceholder: 'Please Input The Amount',
  categoryPlaceholder: 'Please Select Category',
  xPlaceholder: 'Please Input X',
  exclusionCategory: 'Exclusion Category',
  monthlyUpperLimit: 'Monthly Upper Limit',
  onlyObtainOnce: 'Can only be obtained once',
  purchases: 'Purchases',
  purchaseThreshold: 'Purchase Threshold',
};
