export default {
  title0001: '新建Banner',
  title0002: '下架后前台将不可见，是否确认下架？',
  title0003: '上架成功',
  title0004: '操作',
  title0005: '国家',
  title0006: '省/州/市',
  title0007: '邮编',
  title0008: '创建人',
  title0009: '创建时间',
  title0010: '更新人',
  title0011: '更新时间',
  title0012: '状态',
  title0013: '确认提交',
  title0014: '地区搜索',
  title0015: '邮编搜索',
  title0016: '无指向',
};
