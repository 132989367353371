/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type listProductParam = {
  /** current */
  current?: number;

  /** productName */
  productName?: string;

  /** size */
  size?: number;

  /** status */
  status?: number;

  /** storeIds */
  storeIds?: Array<number>;

  /** templateId */
  templateId: number;
};
export type listProductOptions = Record<string, any>;

export type listProductResponse = Promise<
  defs.product.Response<defs.product.Page<defs.product.ProductVo>>
>;

/**
 * @desc 返回模板已关联的商品 根据storeIds过滤返回 productName status 均为过滤条件
 */
export function request(
  params: listProductParam,
  options?: listProductOptions,
): listProductResponse {
  const host = getEnvHost();
  const url = host + '/product/bo/function/template/product/list';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
