export default {
  title0001: '新建弹窗',
  title0002: '编辑弹窗',
  title0003: '下架后前台将不可见，是否确认下架？',
  title0004: '上架成功',
  title0005: '操作',
  title0006: '编辑',
  title0007: '覆盖业务',
  title0008: 'O2O区域',
  title0009: '弹窗名称',
  title0010: '展示图',
  title0011: '指向类型',
  title0012: '创建人',
  title0013: '更新人',
  title0014: '更新时间',
  title0015: '状态',
  title0016: '上传简体中文图片',
  title0017: '选取文件',
  title0018: '指向链接',
  title0019: '限制输入12个字',
  title0020: '确认提交',
  title0021: '排序',
  title0022: '上传繁体中文图片',
  title0023: '上传英文图片',
  title0024: '显示顺序',
  title0025: '限输入1-99的正整数',
  title0026: '图片上传jpg/png/gif文件',
  title0027: '上架状态',
  title0028: '点击量',
  title0029: '无指向',
  title0030: '请输入对应分类的ID',
  title0031: '发布类型',
  title0032: '发布时间',
  title0033: '请选择发布时间',
  title0034: '请输入对应的商品ID',
  title0035: '请输入自定义页面的链接地址',
};
