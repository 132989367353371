export default {
  userNickName: '会员昵称',
  auditStatus: '审核状态',
  submitTime: '提交时间',
  automaticApproval: '自动审核通过',
  comments: '评论内容',
  commentPictures: '评论图片',
  location: '所在位置',
  reviewOpinion: '审核意见',
  reviewer: '审核人',
  reviewTime: '审核时间',
  operations: '操作',
  audit: '审核',
  auditResults: '审核结果',
  agree: '同意',
  reject: '拒绝',
  status: '审核状态',
  batchReview: '批量审核',
};
