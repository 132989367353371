export default {
  title0001: 'New',
  title0002: 'Edit',
  title0003: 'Prohibited words',
  title0004: 'Operation',
  title0005: 'Simplified Chinese',
  title0006: 'Traditional Chinese',
  title0007: 'English',
  title0008: 'Updated by',
  title0009: 'Update Time',
  title0010: 'Delete',
  title0011: 'Words (CN)',
  title0012: 'Words (TW)',
  title0013: 'Words (EN',
  title0014: 'Please input, limited to 10 words',
  title0015: 'Please input, limited to 50 characters',
  title0016: 'Import banned word data',
  title0017: 'Select data',
  title0018: 'Select file',
  title0019: 'Upload xls/xlsx file',
  title0020: 'Will delete, please confirm',
  title0021: 'Click here to download the import template',
};
