export default {
  categoryDetails: '类目详情',
  basicInfo: '基本信息',
  newCategory: '新建类目',
  updateCategory: '编辑类目',
  categoryId: '类目 ID',
  categoryStatus: '类目状态',
  categoryLevel: '类目层级',
  parentCategory: '上级类目',
  categoryName: '类目名称',
  finalCategory: '末级类目',
  categoryIcon: '类目 ICON',
  MCHNumber: 'MCH Number',
  rateInfo: '费率信息',
  plusRate: '加收比率',
  categoryAttributeInfo: '类目属性信息',
  associateAttribute: '关联属性',
  attributeName: '属性名称',
  attributeValue: '属性值数量',
  attributeCategory: '属性分类',
  frontDisplay: '前台展示',
  searchDisplay: '搜索展示',
  multipleSelection: '是否多选',
  required: '是否必填',
  operations: '操作',
  remove: '移除',
  inheritAttributeInfo: '继承属性信息',
  categoryBrandInfo: '类目品牌信息',
  associateBrand: '关联品牌',
  brandName: '品牌名称',
  brandLogo: '品牌 Logo',
  inheritBrandInfo: '继承品牌信息',
  enable: '启用',
  disable: '禁用',
  delete: '删除',
  category: '类目',
  deleteTip: '删除后当前类目将不可见，操作不可恢复，是否确认删除？',
  bCategory: '后台分类',
  fCategory: '前台分类',
};
