/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type mockDeliveredParam = {
  /** orderId */
  orderId: string;
};
export type mockDeliveredOptions = Record<string, any>;

export type mockDeliveredResponse = Promise<defs.order.Response<void>>;

/**
 * @desc mock-商品送达（捡货/出库/寄出/送达）
 */
export function request(
  params: mockDeliveredParam,
  options?: mockDeliveredOptions,
): mockDeliveredResponse {
  const host = getEnvHost();
  const url = host + '/order/bo/support/mock/delivered';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
