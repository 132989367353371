export default {
  title0001: 'Content title',
  title0002: 'Category',
  title0003: 'Creation time',
  title0004: 'Content status',
  title0005: 'Update time',
  title0006: 'New content',
  title0007: 'Content Category',
  title0008: 'Material',
  title0009: 'Upload clip',
  title0010: 'View/comment count',
  title0011: 'Updated by',
  title0012: 'Update time',
  title0013: 'Status',
  title0014: 'Sort value',
  title0015: 'Operation',
  title0016: 'Edit',
  title0017: 'Edit content',
  title0018: 'Please enter, limited to 20 characters',
  title0019: 'Enter numbers only',
  title0020: 'Simplified content',
  title0021: 'Traditional content',
  title0022: 'English content',
  title0023: 'Shelf',
  title0024: 'off-shelf',
  title0025:
    'After the shelf will not be visible in the foreground, whether to confirm the shelf',
  title0026: 'Shelf success',
  title0027: 'Save draft',
  title0028:
    'Up to 9 pictures, support jpg/png format; Up to 1 video, support MP4 format',
  title0029: 'Please enter product ID or trademark title keyword',
  title0030: 'Simplified Chinese title',
  title0031: 'Traditional Chinese title',
  title0032: 'English title',
  title0033: 'Please enter, limited to 100 characters',
  title0034: 'Enter only numbers, unit minutes',
  title0035: 'Cooking time',
  title0036: 'Associated products',
  title0037: 'Keywords',
  title0038: 'Title',
  title0039: 'Description',
  title0040: 'Select Product',
  title0041: 'Exact Match',
  title0042: 'Fuzzy Match',
};
