/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type updateConfigStatusBody = Array<number>;
export type updateConfigStatusOptions = Record<string, any>;

export type updateConfigStatusResponse = Promise<defs.store.Response<void>>;

/**
 * @desc 上下架启动页
 */
export function request(
  body: updateConfigStatusBody,
  options?: updateConfigStatusOptions,
): updateConfigStatusResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/app/launch/config/update/status';
  const fetchOption = {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
