/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type detailProductsPath = {
  /** id */
  id: number;
};
export type detailProductsOptions = Record<string, any>;

export type detailProductsResponse = Promise<
  defs.promotion.Response<Array<defs.promotion.ActivityGlobalDiscountProductVo>>
>;

/**
 * @desc 查看活动商品详情
 */
export function request(
  path: detailProductsPath,
  options?: detailProductsOptions,
): detailProductsResponse {
  const host = getEnvHost();
  const url =
    host +
    '/promotion/bo/activity/global/discount/detail/{id}/products'.replace(
      /\{[\w]+\}/,
      String(path.id),
    );
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  return sendRequest(url, fetchOption);
}
