export default {
  title0001: '仓库名称',
  title0002: '仓库编号',
  title0003: '联系人',
  title0004: '联系电话',
  title0005: '所在地址',
  title0006: '修改人',
  title0007: '修改时间',
  title0008: '状态',
  title0009: '仓库名称英文',
  title0010: '仓库名称简体中文',
  title0011: '仓库名称繁体中文',
  title0012: '地址',
  title0013: '仓库编号不能重合',
};
