export default {
  storeName: 'Store Name',
  storeID: 'Store ID',
  introID: 'Introduction ID',
  updater: 'Updater',
  updateTime: 'Update Time',
  operation: 'Operation',
  intro: 'Introduction',
  edit: 'Edit',
  suffix: "'s introduction",
};
