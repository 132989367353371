const Properties = {
  store_id: '店铺ID',
  store_name: '店铺名称',
  article_number_product_id: '商品编号(商品ID)',
  english_title: '英文标题',
  simplified_chinese_title: '简体中文标题',
  traditional_chinese_title: '繁体中文标题',
  product_main_image: '商品主图',
  backend_category: '所属后台分类',
  frontend_category: '所在前台分类',
  upc_code: 'UPC编码',
  upc_thumbnail: 'UPC缩略图',
  product_price: '商品售价',
  store_promotion_price: '门店活动价',
  online_promotion_price: '线上活动价',
  current_price: '当前售价',
  available_inventory: '可用库存',
  locked_inventory: '锁定库存',
  total_inventory: '总库存',
  saleable_inventory: '可售库存',
  warehouse: '所在仓库',
  location_code: '位置编码',
  mch_number: 'MCH号',
  is_weighted_product: '是否称重商品',
  is_alcoholic_product: '是否含酒精商品',
  product_brand: '商品品牌',
  product_video: '商品视频',
  merchant_code: '商家编码',
  merchant_name: '商家名称',
  english_brief_description: '英文简要描述',
  simplified_chinese_brief_description: '简体中文简要描述',
  traditional_chinese_brief_description: '繁体中文简要描述',
  english_detailed_description: '英文详细描述',
  simplified_chinese_detailed_description: '简体中文详细描述',
  traditional_chinese_detailed_description: '繁体中文详细描述',
  product_upc_status: '商品UPC状态',
  promotion_type: '促销类型',
  promotion_range: '促销区间',
  product_net_weight: '商品净重',
  product_net_weight_unit: '商品净重单位',
  product_sales_unit: '商品销售单位',
  updater: '更新人',
  updated_time: '更新时间',
  yes: '是',
  no: '否',
};

const FormProperties = {
  product_name: '商品名称',
  article_number: 'Article Number',
  upc_code: 'UPC编码',
  status: '状态',
  store_id: '门店ID',
  backend_category: '后台分类',
  search: '查 询',
  reset: '重 置',
  export: '导出',
  batch_off_shelf: '批量下架',
  batch_on_shelf: '批量上架',
  more_search: '更多查询',
  already_listed: '已上架',
  already_delisted: '已下架',
};

const BatchSearchForm = {
  store_bulk_query: '门店批量查询',
  store_id_line: '每行一个门店ID',
  article_number_bulk_query: 'Article Number批量查询',
  article_number_line: '每行一个编码',
  upc_bulk_query: 'UPC批量查询',
  upc_line: '每行一个编码',
  update_time: '更新时间',
  warehouse: '所在仓库',
  location_code: '位置编码',
};

const others = {
  shelve: '上架',
  unshelve: '下架',
  please_select_at_least_one_published_record: '请至少选择一条已上架记录',
  select_at_least_one_unpublished_record: '至少选择一条已下架记录',
  successfully_published: '上架成功',
  successfully_unpublished: '下架成功',
  confirm_unpublish_message:
    '下架后对应店铺将不再销售该商品规格，是否确认下架？',
  list_display_field_settings: '列表显示字段设置',
};

export default {
  ...Properties,
  ...FormProperties,
  ...BatchSearchForm,
  ...others,
};
