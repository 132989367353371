/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type taskStatusOptions = Record<string, any>;

export type taskStatusResponse = Promise<defs.promotion.Response<string>>;

/**
 * @desc 轮询任务执行状态
 */
export function request(options?: taskStatusOptions): taskStatusResponse {
  const host = getEnvHost();
  const url = host + '/promotion/bo/time/limit/discount/config/task/status';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  return sendRequest(url, fetchOption);
}
