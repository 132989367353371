export default {
  title0001: 'Order placed successfully',
  title0002: 'To be pushed into picking pool',
  title0003: 'Pending picking',
  title0004: 'Picking in progress',
  title0005: 'Pending delivery',
  title0006: 'Delivered',
  title0007: 'Order Number',
  title0008: 'Merchant name',
  title0009: 'User ID',
  title0010: 'Amount due',
  title0011: 'Delivery Method',
  title0012: 'Merchant Type',
  title0013: "User's cell phone number",
  title0014: 'Authorization amount',
  title0015: 'Order time',
  title0016: 'Store code',
  title0017: 'Membership card number',
  title0018: 'Withholding refund',
  title0019: 'Store name',
  title0020: 'Product main image',
  title0021: 'Product front-end display name',
  title0022: 'Specification',
  title0023: 'main UPC code',
  title0024: 'Product ID',
  title0025: 'Price',
  title0026: 'Price per unit quantity',
  title0027: 'Number of orders placed',
  title0028: 'Order weight',
  title0029: 'Actual picking quantity',
  title0030: 'Actual picking weight',
  title0031: 'Order amount',
  title0032: 'Actual pick amount',
  title0033: 'Is this a free item',
  title0034: 'Main item name',
  title0035: 'Initial order amount',
  title0036: 'Total price of the item',
  title0037: 'Taxes and fees',
  title0038: 'Bottle Return Fee',
  title0039: 'Service charge',
  title0040: 'Delivery fee',
  title0041: 'Driver tip',
  title0042: 'Coupon & Coupon Code Amount',
  title0043: 'Store order level discount amount',
  title0044: 'Platform order level discount amount',
  title0045: 'Points Credit',
  title0046: 'Total Order Amount',
  title0047: 'Est. Item Adjustment',
  title0048: 'Pre-authorized amount',
  title0049: 'Adjusted order amount',
  title0050: 'Price adjustment details after picking',
  title0051: 'Product name',
  title0052: 'UPC Code',
  title0053: 'Adjustment amount',
  title0054: 'Adjustment item',
  title0055: 'Product price adjustment',
  title0056: 'Piece adjustment',
  title0057: 'Weight Adjustment',
  title0058: 'Tax Adjustment',
  title0059: 'Bottle Return Fee Adjustment',
  title0060: 'Total adjustment amount',
  title0061: 'Offers participating in this order',
  title0062: 'Straight down',
  title0063: 'Put away',
  title0064: 'Collapse',
  title0065: 'Offer object',
  title0066: 'Quantity',
  title0067: 'Starting amount',
  title0068: 'Offer amount',
  title0069: 'Amount after the event',
  title0070: 'Total',
  title0071: 'Marketing Campaign ID',
  title0072: 'Xth piece discount',
  title0073: 'Buy to give',
  title0074: 'Direct Discount Coupon',
  title0075: 'Coupon Activity ID',
  title0076: 'Coupon Code',
  title0077: 'Full Discount Coupon',
  title0078: 'Shipping discount',
  title0079: 'Free Shipping Rules',
  title0080: 'Payment information',
  title0081: 'Payment order number',
  title0082: 'Payment Method',
  title0083: 'Payment card number',
  title0084: 'Card Brand',
  title0085: 'Payment channel',
  title0086: 'Business type',
  title0087: 'Time',
  title0088: 'Amount',
  title0089: 'Payment Status',
  title0090: 'Payment Status',
  title0091: 'Refund information',
  title0092: 'After Sales Order Number',
  title0093: 'Refund order number',
  title0094: 'Refund Path',
  title0095: 'Refund card number',
  title0096: 'Refund Channel',
  title0097: 'Refund time',
  title0098: 'Refund amount',
  title0099: 'Refund status',
  title0100: 'Delivery information',
  title0101: 'Package',
  title0102: 'Delivery Tools',
  title0103: 'Waybill number',
  title0104: 'Delivery start time',
  title0105: 'Delivery time',
  title0106: 'Additional delivery fee',
  title0107: 'Total amount',
  title0108: 'Delivery picture',
  title0109: 'Logistics information',
  title0110: 'Pickup time',
  title0111: 'Receiving information',
  title0112: 'Receiving address',
  title0113: 'Name',
  title0114: 'Contact phone',
  title0115: 'zipcod',
  title0116: 'Self-pickup contact',
  title0117: 'Pickup store',
  title0118: 'Store address',
  title0119: 'Order Cancellation',
  title0120: 'Cancellation type',
  title0121: 'Cancellation time',
  title0122: 'Reason for cancellation',
  title0123: 'Operation log',
  title0124: 'Total Charged Amount',
  title0125: 'View Logistics Information',
  title0126: 'View Delivery Information',
  title0127: 'Address',
  title0128: 'Delivery picture',
  title0129: 'Logistics information',
};
