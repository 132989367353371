export default {
  title0001: 'New',
  title0002: 'Edit',
  title0003:
    'The frontend will not be visible after the downgrade, do you want to confirm the downgrade?',
  title0004: 'Shelf success',
  title0005: 'Operation',
  title0006: 'Name',
  title0007: 'Simplified Chinese image',
  title0008: 'Traditional Chinese image',
  title0009: 'English image',
  title0010: 'Hits',
  title0011: 'Links to',
  title0012: 'Updated by',
  title0013: 'Update time',
  title0014: 'Status',
  title0015: 'Sort by',
  title0016: 'Simplified Chinese image',
  title0017: 'Please enter, limited to 12 characters',
  title0018: 'Please input, limited to 100 characters',
  title0019: 'Select file',
  title0020: 'Upload jpg/png file, and no more than 6M',
  title0021: 'Please enter a link',
  title0022: 'Display order',
  title0023: 'Please enter a positive integer from 1-99',
  title0024: 'Posting type',
  title0025: 'Posting time',
  title0026: 'Instant posting',
  title0027: 'Timed posting',
  title0028: 'Please upload',
  title0029:
    'At least 5 functional areas, and cannot continue to be removed from the shelves',
};
