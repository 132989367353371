import { DEFAULT_LANGUAGE } from '@/constants';

export const readFileToText = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, 'utf8');
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (e) => reject(e);
  });

export function parseJSON(json: string): Record<string, unknown> {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}

export const jsonValidate = (value: string): boolean => {
  try {
    return !!JSON.parse(value);
  } catch (e) {
    return false;
  }
};

interface DownloadProps {
  content: BlobPart;
  filename: string;
  preview?: boolean;
}
export function download({ content, filename, preview }: DownloadProps): void {
  const blob = new window.Blob([content]);

  const url = URL.createObjectURL(blob.slice(0, blob.size));
  if (!preview) {
    const link = document.createElement('a');
    link.download = filename;
    link.style.display = 'none';
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(url);
  }
  URL.revokeObjectURL(url);
}

export function copy(text: string, callback: () => void): void {
  const textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.opacity = '0';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
  callback && callback();
}
/**
 * @desc 千位分隔符
 * @param value
 * @param decimal
 * @param symbol
 * @returns string
 */

export function generateThousandsSeparator(
  value: string | number = 0,
  decimal = 2,
  symbol = ',',
) {
  const input = typeof value === 'string' ? Number(value) : value;
  // eslint-disable-next-line no-bitwise
  const isFloat = ~~input !== input;
  const target = isFloat ? input.toFixed(decimal) : `${input}`;

  return target.replace(/(^|\s)\d+/g, (m) =>
    m.replace(/(?=(?!\b)(\d{3})+$)/g, symbol),
  );
}

export function getContainer(node?: any): HTMLElement {
  if (node) {
    return node.parentNode;
  }
  return document.body;
}

export function convertCurrentLanguage(lang: string): string {
  return lang === DEFAULT_LANGUAGE ? 'enUS' : 'zhCN';
}

export const getAuthPermission = (pageCode: string, authCode: string) => {
  if (!window?.PERMISSIONS) return true;
  // @ts-ignore
  const { components = [] } =
    window?.PERMISSIONS?.find((permission) => permission.code === pageCode) ??
    {};

  return components.find(
    (component: { code: string }) => component?.code === authCode,
  )?.visible;
};

/**
 * @desc 根据比较函数filter 合并数组 Curried
 * @param filter 比较函数 (a: T, b: T) => boolean)
 * @return Object { duplicated: 重复个数, result: 合并后的数组 }
 */
export const mergeArray =
  <T>(filter: (a: T, b: T) => boolean) =>
  (arr1: T[], arr2: T[]) => {
    const result = [...arr1];
    let duplicated = 0;
    arr2.forEach((item) => {
      const index = result.findIndex((i) => filter(i, item));
      if (index === -1) {
        result.push(item);
      } else {
        duplicated++;
      }
    });
    return { duplicated, result };
  };

export type VariantObj = {
  netWeight?: number;
  netWeightUom?: string;
  saleUom?: string;
  type?: number /** 商品类型 1: 标准商品, 2: 称重商品 */;
  productType?: number;
  variantName?: string;
};

export const getVariantName = (variant: VariantObj = {}): string => {
  if (variant?.netWeight && variant?.netWeightUom && variant?.saleUom) {
    const variantName = `${variant?.netWeight?.toFixed(2)} ${
      variant?.netWeightUom
    }/${variant?.saleUom}`.toLowerCase();
    const type = variant?.productType || variant?.type;
    if (type === 2) {
      return `Approx. ${variantName}`;
    }
    return variantName;
  }
  return variant?.variantName || '';
};
