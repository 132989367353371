export default {
  store_name: '门店名称',
  activity_title: '活动标题',
  belong_group: '归属分组',
  channel: '渠道',
  activity_period: '活动区间',
  activity_id: '活动ID',
  store_id: '门店ID',
  updater: '更新人',
  update_time: '更新时间',
  status: '状态',
  operation: '操作',
  publish: '上架',
  unpublish: '下架',
  unpublished: '已下架',
  published: '已上架',
  create: '新建',
  edit: '编辑',
  store_type: '门店类型',
  set_by_store: '按门店设置',
  set_by_store_group: '按门店分组设置',
  related_stores: '关联门店',
  add: '添加',
  activity_image: '活动图',
  image_supports_jpg_png_format: '图片支持jpg/png格式',
  select_import_file: '选择导入文件',
  supports_uploading_xls_xlsx_files: '支持上传xls/xlsx文件',
  click_here_to_download_import_template: '点此下载导入模板',
  publish_type: '发布类型',
  scheduled_publish: '定时发布',
  immediate_publish: '即时发布',
  select_file: '选择文件',
  store_group: '门店分组',
  release_time: '发布时间',
  confirm_unpublish_message: '下架后对应店铺将不再展示该活动，是否确认下架？',
};
