import header from './header';
import storeSelect from './storeSelect';
import goodsSelect from './goodsSelect';

export default {
  header,
  selectAllOrNot: '全选/反选',
  storeSelect,
  goodsSelect,
};
