/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type changeStatusBody = defs.store.BoChangeBannerStatusRequest;
export type changeStatusOptions = Record<string, any>;

export type changeStatusResponse = Promise<defs.store.Response<void>>;

/**
 * @desc 修改状态
 */
export function request(
  body: changeStatusBody,
  options?: changeStatusOptions,
): changeStatusResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/banner/modifyStatus';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
