export default {
  title0001: 'Bulk set limit',
  title0002: 'Set price in bulk',
  title0003: 'Bulk downgrade',
  title0004: 'Bulk shelf',
  title0005: 'Sell Stores',
  title0006: 'Store grouping',
  title0007: 'Product price',
  title0008: 'Store activity price',
  title0009: 'Online activity price',
  title0010: 'Current price',
  title0011: 'Daily Limit',
  title0012: 'Discount',
  title0013: 'Available Stock',
  title0014: 'Available Stock',
  title0015: 'Locked Inventory',
  title0016: 'Total Inventory',
  title0017: 'Updated by',
  title0018: 'Update Time',
  title0019: 'Status',
  title0020: 'Operation',
  title0021: 'Shelved',
  title0022: 'Shelf',
  title0023: 'Set price',
  title0024: 'Set limit',
  title0025: 'Current price',
  title0026: 'Current limit quantity',
  title0027: 'Daily limit quantity',
  title0028: 'Please select at least one record',
  title0029: 'Please select at least one record that is already on the shelf',
  title0030:
    'After shelving, the corresponding store will no longer sell the product specifications, whether to confirm the shelving?',
  title0031: 'Please select at least one shelf record',
  title0032: 'Shelved',
  title0033: 'Shelved',
};
