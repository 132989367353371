/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type findByUpcParam = {
  /** upc */
  upc: string;
};
export type findByUpcOptions = Record<string, any>;

export type findByUpcResponse = Promise<
  defs.search.Response<defs.search.ProductSearchTag>
>;

/**
 * @desc 查询商品
 */
export function request(
  params: findByUpcParam,
  options?: findByUpcOptions,
): findByUpcResponse {
  const host = getEnvHost();
  const url = host + '/search/bo/search_tag/product/find';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
