export default {
  title0001: '用户使用协议的维护',
  title0002: '点此维护',
  title0003: '英文版',
  title0004: '简体中文版',
  title0005: '繁体中文版',
  title0006: '隐私政策的内容维护',
  title0007: '注销须知的内容维护',
  title0008: '编辑',
  title0009: '内容',
};
