export default {
  title: '商品标题',
  articleNumber: 'Article Number',
  upc: 'UPC编码',
  batchImportTags: '批量导入标签',
  tagsTemplate: '标签模板',
  id: '商品ID',
  titleEN: '商品英文标题',
  sales: '销量',
  brandTags: '品牌标签',
  attributeTags: '属性标签',
  categoryTags: '类目标签',
  action: '操作',
  editTags: '编辑标签',
  clearTags: '清空标签',
  editProductTags: '编辑商品标签',
  clear: '清空',
  clearTip: '是否清空该商品下所有标签',
  en: '英文',
  ch: '简体中文',
  tw: '繁体中文',
  placeholder: '请输入标签词',
  importSuccessful: '共计{{num}}个标签导入成功。',
  importFail: '共计{{num}}个标签导入成功，失败的标签请导出Excel表查看详情！',
  downloadErrorFile: '导出含有错误信息的Excel表格',
  notice: '提示',
};
