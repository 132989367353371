export default {
  title0001: 'Attribute name',
  title0002: 'Category',
  title0003: 'Status',
  title0004: 'Enable',
  title0005: 'Category Management',
  title0006: 'Attribute ID',
  title0007: 'Attribute name',
  title0008: 'Attribute Value',
  title0009: 'Attribute Category',
  title0010: 'Frontend Display',
  title0011: 'Search display',
  title0012: 'Multi-select or not',
  title0013: 'Required or not',
  title0014: 'Edit attribute',
  title0015: 'Updated by',
  title0016: 'Update time',
  title0017: 'Action',
  title0018: 'New Attribute',
  title0019: 'Disable',
  title0020: 'Attribute English name',
  title0021: 'Property Traditional Name',
  title0022: 'Attribute Simplified Name',
  title0023: 'Property Category',
  title0024: 'Frontend display',
  title0025: 'Please enter a number',
  title0026: 'Delete is not recoverable, confirm deletion or not',
  title0027: 'Search display',
  title0028: 'Whether to multi-select',
  title0029: 'Required or not',
  title0030: 'List of attribute values',
  title0031: 'Value (CN)',
  title0032: 'Value (EN)',
  title0033: 'Value (TW)',
  title0034: 'Sort',
};
