export default {
  createPolicyBtn: '添加政策',
  type: '类型',
  storeGroup: '门店分组',
  statementEN: '英文',
  statementSCH: '简体中文',
  statementTCH: '繁体中文',
  confirmDelete: '确认要删除么?',
  policyEditModalTitle: '编辑',
};
