/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type updateConfigSequenceBody = Array<number>;
export type updateConfigSequenceOptions = Record<string, any>;

export type updateConfigSequenceResponse = Promise<defs.store.Response<void>>;

/**
 * @desc 更新启动页排序
 */
export function request(
  body: updateConfigSequenceBody,
  options?: updateConfigSequenceOptions,
): updateConfigSequenceResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/app/launch/config/update/sequence';
  const fetchOption = {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
