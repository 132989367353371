export default {
  addDriverTip: 'Add Driver Tip',
  addressCity: 'ship to(city)',
  addressState: 'ship to(state)',
  adjustPrice: 'Adjust Price',
  adjustQuantity: 'Adjust Quantity',
  adjustWeight: 'Adjust Weight',
  afterAllPromotionAmount: 'After All Promotion Amount',
  authedAmount: 'Pre-authorization amount',
  captureTime: 'Capture Time',
  channelType: 'Channel Type',
  createdTime: 'Created Time',
  crvFee: 'Crv Fee',
  currentSalePrice: 'Current Sale Price',
  deliveredTime: 'Delivered Time',
  deliveryFee: 'Delivery Fee',
  deliveryTypeDesc: 'Delivery Type Desc',
  diffAfterAllPromotionAmount: 'DiffAfter All Promotion Amount',
  diffCrvFee: 'Diff Crv Fee',
  diffDiscountedPrice: 'Diff Discounted Price',
  diffMarketPrice: 'Diff Market Price',
  diffPlatformDiscount: 'Diff Platform Discount',
  diffPointsDeduction: 'Diff Points Deduction',
  diffStoreDiscount: 'Diff Store Discount',
  diffTaxFee: 'Diff Tax Fee',
  discountedPrice: 'Discounted Price',
  driverTip: 'Driver Tip',
  id: 'Id',
  isGift: 'Is Gift',
  isWeighedProduct: 'Is Weighed Product',
  itemWeight: 'Item Weight',
  mainProductName: 'Main Product Name',
  marketPrice: 'Market Price',
  merchantName: 'Merchant Name',
  orderId: 'Order Id',
  payAmount: 'Pay Amount',
  payingParty: 'Paying Party',
  paymentSuccessful: 'Payment Successful',
  performAddDriverTip: 'Perform Add Driver Tip',
  performAfterAllPromotionAmount: 'Perform After All Promotion Amount',
  performCrvFee: 'Perform Crv Fee',
  performDeliveryFee: 'Perform Delivery Fee',
  performDiscountedPrice: 'Perform Discounted Price',
  performDriverTip: 'Perform Driver Tip',
  performMarketPrice: 'Perform Market Price',
  performPlatformDiscount: 'Perform Platform Discount',
  performPointsDeduction: 'Perform Points Deduction',
  performServiceFee: 'Perform Service Fee',
  performStoreDiscount: 'Perform Store Discount',
  performTaxFee: 'Perform Tax Fee',
  performanceQuantity: 'Performance Quantity',
  performanceWeigh: 'Performance Weigh',
  platformCommissionAmount: 'Platform Commission Amount',
  platformCommissionPercent: 'Platform Commission Percent',
  platformDiscount: 'Platform Discount',
  pointsDeduction: 'Points Deduction',
  productId: 'Article Number',
  productName: 'Product Name',
  quantity: 'Quantity',
  receivingParty: 'Receiving Party',
  saleUom: 'Sale Uom',
  netWeight: 'Net Weight',
  serviceFee: 'Service Fee',
  splitAmount: 'Split Amount',
  storeAddrCity: 'ship from(city)',
  storeAddrState: 'ship from(State)',
  storeDiscount: 'Store Discount',
  storeId: 'Store Id',
  storeName: 'Store Name',
  storeTimeZone: 'TimeZone',
  taxFee: 'Tax Fee',
  upc: 'UPC',
  userId: 'User Id',
  userPhone: 'User Phone',
  variantNameEn: 'Variant',
  itemAdjustment: 'Item Adjustment',
  platformDeliveryFee: 'Platform Delivery Fee',
};
