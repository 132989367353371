export default {
  userId: '用户 Id',
  memberNickname: '会员昵称',
  phoneNO: '手机号',
  couponCode: '券码',
  issueCouponsWays: '发券方式',
  couponActivityName: '券活动名称',
  couponActivityCode: '券活动编码',
  couponParams: '券参数',
  getCouponTime: '获得时间',
  couponActivityStatus: '券活动状态',
  operations: '操作',
  destroy: '销毁',
  batchDestroy: '批量销毁',
  destroyLogs: '销毁日志',
  operatingReason: '操作原因',
  view: '查看',
  couponActivityInfo: '券活动信息',
  couponType: '券类型',
  discountValue: '折扣值',
  maxDeductAmount: '最大抵扣金额',
  destroyTip:
    '券销毁后，用户将不可见被销毁的券。若需销毁，请在下方填写原因并提交，若不需销毁则取消。',
};
