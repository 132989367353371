/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type detailStoresPath = {
  /** id */
  id: number;
};
export type detailStoresOptions = Record<string, any>;

export type detailStoresResponse = Promise<
  defs.promotion.Response<Array<defs.promotion.ActivityDiscountCodeStoreVo>>
>;

/**
 * @desc 查看活动门店详情
 */
export function request(
  path: detailStoresPath,
  options?: detailStoresOptions,
): detailStoresResponse {
  const host = getEnvHost();
  const url =
    host +
    '/promotion/bo/discount/code/config/detail/{id}/stores'.replace(
      /\{[\w]+\}/,
      String(path.id),
    );
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  return sendRequest(url, fetchOption);
}
