/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type updateConfigBody = defs.store.BoAPPLaunchConfigDetailVo;
export type updateConfigOptions = Record<string, any>;

export type updateConfigResponse = Promise<defs.store.Response<void>>;

/**
 * @desc 修改启动页
 */
export function request(
  body: updateConfigBody,
  options?: updateConfigOptions,
): updateConfigResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/app/launch/config/update';
  const fetchOption = {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
