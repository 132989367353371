export default {
  storeName: '门店名称',
  status: '门店状态',
  storeGroup: '归属分组',
  storeType: '门店类型',
  storeID: '门店ID',
  storeAddress: '门店地址',
  storeNature: '门店性质',
  create: '新建门店',
  logo: '门店头图',
  address: '门店地址',
  deliverySetting: '配送设置',
  updater: '更新人',
  updateTime: '更新时间',
  timeZone: '所属时区',
  storeBusinessTime: '营业时间',
  faxNumber: '传真号码',
  telephone: '联系电话',
  longlatitude: '地址经纬度',
  email: '邮箱地址',
  serviceFee: '服务费',
  deliveryProvider: '配送服务商',
  orderLimit: '下单数限制',
  availableDays: '可预约天数',
  pickingTime: '拣货时效',
  relatedWarehouse: '关联仓库',
  deliveryTime: '配送时效',
  storeDeliverTimes: '履约时间',
  flashDistance: '闪送范围',
  pickupDistance: '自提范围',
  noServiceFee: '不收取',
  weeks: '星期',
  timeRange: '时间区间',
  excludedRange: '排除范围',
  viewZipCodeList: '查看已设置的zipcode列表',
  pleaseSelect: '请先选择门店性质',
  companyCode: '公司编码',
  noPay: '不收取',
  payServiceFee: '收取',
  unitHour: '单位小时',
  unitDay: '单位天',
  confirmDisable: '禁用后对应门店将不可见，是否确认？',
  confirmEnable: '确定启用该门店吗？',
  bypass: '遇到付费道路时绕开或不配送',
  placeNotFound: '搜索的地址不存在，请输入英语的地址名称',
  setOrderLimit: '设置下单限制',
  setStoreDeliverTimesFirst: '请先设置履约时间',
  getFromMap: '从地图获取',
  lastOrderTime: '截单时间',
  excludeRange: '排除范围',
  chooseFile: '选择文件',
  selectImportFile: '选择导入文件',
  downloadTemplateFile: '下载模版导入文件',
  deliverRange: '配送范围',
  sureToDeleteZipCode: '确定删除选择的Zipcode吗',
  storeBussinessTimeRequired: '请选择营业时间',
  invalidTimeRange: '请选择有效的时间区域',
  setTemplate: '设置下单模版',
  repeatDay: '选择了重复的星期',
  pleaseCompleteForm: '请填写完所有时间段的限制数量',
  houseNo: '门牌号',
  getLongLatitude: '获取经纬度',
  correctPlace: '请输入完整的地址进行搜索',
  viewOrderLimit: '查看下单限制',
  correctZipCode: '请输入正确格式的zipcode',
  storeDeliverTimeRequired: '请选择履约时间',
  importComplete: '导入完成',
  failedList: '导入失败的条目',
  noLimitRules: '请设置下单限制数量',
  zipcode: '邮编',
  storeChannel: '门店渠道',
  storeOfflineBusinessTime: '线下营业时间',
  storeOnlineBusinessTime: '线上营业时间',
};
