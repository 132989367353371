export default {
  title0001: 'Activity name',
  title0002: 'Activity code',
  title0003: 'Name for user',
  title0004: 'Voucher type',
  title0005: 'Activity Status',
  title0006: 'Creation time',
  title0007: 'New coupon activity',
  title0008: 'Number of available',
  title0009: 'Number of issued',
  title0010: 'Checked off quantity',
  title0011: 'Set coupon rules',
  title0012: 'Review coupon activity',
  title0013: 'Activity expiration time',
  title0014: 'Edit coupon activity',
  title0015: 'Brief description',
  title0016: 'Operation',
  title0017: 'Description of the voucher activity',
  title0018: 'Description for user',
  title0019: 'Number of issued',
  title0020: 'Activity details',
  title0021: 'Background image',
  title0022: 'Please fill in the reason for operation (required)',
  title0023: 'Name of operator',
  title0024: 'Operation name',
  title0025: 'Operation time',
  title0026: 'Reason for operation',
  title0027: 'Upload image',
  title0028: 'Please fill in the reason for the operation before submitting',
  title0029: 'Voucher parameters definition',
  title0030: 'Voucher type',
  title0031: 'Product to be purchased',
  title0032: 'Select product',
  title0033: 'Condition type',
  title0034: 'Product amount',
  title0035: 'Number of items',
  title0036: 'Support for positive numbers up to {{num}} digits',
  title0037: 'Support positive integers from 1-99',
  title0038: 'Discount strength',
  title0039: 'Allowable threshold amount',
  title0040: 'Maximum credit amount',
  title0041: 'Credit amount',
  title0042: 'Delivery method selection',
  title0043: 'Submitted successfully',
  title0044:
    'Submission failed, please complete the required fields and submit again',
  title0045: '[Tip] Only the selected delivery method can use this coupon',
  title0046:
    '[Hint] This coupon can be used when the purchase amount is within this range',
  title0047: 'Conditions of the coupon',
  title0048: 'Limit per user',
  title0049: 'Start time',
  title0050: 'Within days, can be claimed',
  title0051: 'coupon',
  title0052:
    '[Tip] Not filled means there is no limit on the time and number of coupons a user can receive',
  title0053: 'Conditions of use of the coupon',
  title0054: 'Covered operations',
  title0055: 'Available stores',
  title0056: 'Selected {{num}} stores',
  title0057: 'Time of use setting for vouchers',
  title0058: 'Date type',
  title0059: 'Claimed',
  title0060: 'Effective after days',
  title0061: 'Support integers ≥ 0',
  title0062: 'Expires after days',
  title0063: 'Limit after effective',
  title0064: 'Only available',
  title0065: 'After claiming the coupon in ',
  title0066: 'Valid for the period',
  title0067: 'Product selection',
  title0068: 'Product name',
  title0069: 'Product Category',
  title0070: 'Please enter a product name, product ID or product UPC code',
  title0071: 'Product ID',
  title0072: 'Product Category',
  title0073: 'Image',
  title0074: 'Selected Products',
  title0075: 'Remove',
  title0076: 'Support no more than {{num}} characters',
  title0077: 'End time',
  title0079: 'Only support JPG/PNG/GIF/PBM images in 2M format',
  title0080: 'Logs',
  title0081: 'Coupon level',
  title0082: 'Cost Sharing',
  title0083: 'Platform bears',
  title0084: 'borne by the store',
  title0085: 'Message Notification Copy',
  title0086: 'Simplified Chinese Copy',
  title0087: 'Traditional Chinese Copy',
  title0088: 'English Copy',
};
