export default {
  title0001: 'Manually adjust points list',
  title0002: 'Manual Points Adjustment',
  title0003: 'Member ID',
  title0004: 'Points adjustment time',
  title0005: 'Mobile phone number',
  title0006: 'Point Adjustment Order Number',
  title0007: 'Point adjustment initiation time',
  title0008: 'Member nickname',
  title0009: 'Adjustment quantity',
  title0010: 'Initiator',
  title0011: 'Reason for points adjustment',
  title0012: 'Reviewer',
  title0013: 'Review status',
  title0014: 'Review time',
  title0015: 'Reason for audit operation',
  title0016: 'Operation',
  title0017: 'Audit',
  title0018: 'User ID',
  title0019: 'Mobile phone number',
  title0020: 'Reason for adjustment',
  title0021: 'Number of adjustments',
  title0022: 'Reason for operation',
  title0023: 'Please select the user who needs to adjust the points',
  title0024: 'Membership level',
  title0025: 'User status',
  title0026: 'Operation type',
  title0027: 'Number of changes',
  title0028: 'Increase points',
  title0029: 'Decrease points',
};
