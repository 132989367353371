export default {
  templateName: '模版名称',
  defaultTemplate: '默认模板',
  status: '状态',
  shippingLabel: '配送标签',
  coverageArea: '覆盖区域',
  operations: '操作',
  newTemplate: '新建模版',
  updateTemplate: '编辑模版',
  copy: '复制',
  edit: '编辑',
  enable: '启用',
  disable: '禁用',
  setAsDefault: '设为默认',
  editCategory: '编辑分类',
  storeGroup: '门店分组',
  disableTip: '禁用后对应模板将不可用，是否确认禁用？',
  categoryDetails: '类目详情',
  basicInfo: '基本信息',
  newCategory: '新建类目',
  updateCategory: '编辑类目',
  categoryId: '类目 ID',
  categoryStatus: '类目状态',
  categoryLevel: '类目层级',
  parentCategory: '上级类目',
  categoryName: '类目名称',
  categoryNameEN: '类目英文名称',
  categoryNameCH: '类目简体名称',
  categoryNameTW: '类目繁体名称',
  finalCategory: '末级类目',
  categoryIcon: '类目 ICON',
  backgroundCategory: '后台类目',
  associateBackgroundCategory: '关联后台类目',
  associateProducts: '关联商品',
  batchRemove: '批量移除',
  productId: '商品 ID',
  productTitle: '商品标题',
  thumbnail: '缩略图',
  category: '分类',
  upc: 'UPC',
  articleNumber: 'Article Number',
  selectItemsManually: '手动选择商品',
  batchImportItems: '批量导入商品',
  import: '导入',
  delete: '删除',
  deleteTip: '删除后当前类目将不可见，操作不可恢复，是否确认删除？',
  downloadFileTemplate: '下载导入文件模板',
  supportFileType: '支持上传xls/xlsx文件',
  batchRemoveTip: '确认移除？',
  selectAll: '选择全部',
};
