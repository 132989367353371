export default {
  title0001: 'Countries',
  title0002: 'Province/state/City',
  title0003: 'Zip code',
  title0004: 'Email',
  title0005: 'Nick name',
  title0006: 'Submit time',
  title0007: 'Status',
  title0008: 'Source',
  title0009: 'Area search',
  title0010: 'Zip code search',
};
