export default {
  afterSalesOneLineCoding: 'After-sales single line code',
  companyCode: 'Company code',
  payer: 'Payer',
  payee: 'Payee (User ID)',
  splitAmount: 'Sub-account amount',
  channelType: 'Channel type',
  timeZone: 'TimeZone',
  refundSuccessfully: 'Refund successfully',
  orderTime: 'Order time',
  completeTime: 'Complete time',
  deductionTime: 'Deduction time',
  performingParty: 'Performing party',
  productName: 'Product name',
  upc: 'UPC',
  articleNumber: 'Article Number',
  weighingGoods: 'Weighing goods',
  sellingPrice: 'Selling price',
  unit: 'Unit',
  specification: 'Specification',
  afterSalesQuantity: 'After-sales quantity',
  originalPrice: 'Product original price',
  productPriceAfterDirectDrop: 'Product price after direct reduction',
  platformAmount: 'Platform shares the promotional amount',
  merchantsAmount: 'Merchants share the promotional amount',
  allPostPromotionAmounts: 'All post-promo amounts',
  pointsDeduction: 'Points deduction',
  saleTax: 'Sale tax',
  bottleReturnFee: 'Bottle return fee',
  serviceCharge: 'Service charge',
  driverTip: 'Driver tip',
  additionalDeliveryFee: 'Additional delivery fee',
  platformDistributionFee: 'Platform delivery fee',
  merchantDeliveryFee: 'Merchant delivery fee',
  merchantShippingDiscount: 'Merchant shipping discounts',
  platformShippingDiscount: 'Platform shipping discounts',
  platformDeductionPoints: 'Platform deduction points%',
  platformDeductionAmount: 'Platform deduction points (fixed amount)',
  refundAmount: 'Refund amount',
  afterSalesOrderNumber: 'After-sales order number',
  orderNumber: 'Order number',
  applicationTime: 'Application time',
  refundTime: 'Refund time',
  actualRefundAmount: 'Actual refund amount',
  refundableAmount: 'Amount due to be refunded',
  aftermarketType: 'After-sales type',
  afterSalesStatus: 'After-sales status',
  shopName: 'Shop name',
  businessType: 'Business type',
  storeName: 'Store Name',
  storeCode: 'Store code',
  userID: 'User ID',
  userPhone: 'User phone number',
  sku: 'Number of SKUs',
  shipFromCity: 'ship from(city)',
  shipFromState: 'ship from(state)',
  shipToCity: 'ship to(city)',
  shipToState: 'ship to(state)',
  export: 'Export',
  orderDeductionTime: 'Order deduction time',
};
