export default {
  title0001: 'Product Title (CN)',
  title0002: 'Article Number',
  title0003: 'UPC Code',
  title0004: 'Backend Category',
  title0005: 'Status',
  title0006: 'Bulk Search',
  title0007: 'Import Limit Settings',
  title0008: 'Product ID',
  title0009: 'Product title (TW)',
  title0010: 'Product title (EN)',
  title0011: 'Thumbnail',
  title0012: 'Backend Category',
  title0013: 'UPC',
  title0014: 'Product Details',
  title0015: 'Update time',
  title0016: 'Query Type',
  title0017: 'One code per row',
  title0018: 'View Sub-UPC',
  title0019: 'Sub-UPC',
  title0020: 'Import',
  title0021: 'Select import file',
  title0022: 'Select file',
  title0023: 'Support uploading xls/xlsx files',
  title0024: 'Click here to download import template',
  title0025: 'Import completed',
  title0026: 'This import was successful {{success}} and failed {{error}},',
  title0027: 'Click here to download failed records',
  title0028: 'Enable',
  title0029: 'Disable',
  title0030: 'View details',
  title0031: 'Search Type',
};
