export default {
  templateName: 'Template Name',
  defaultTemplate: 'Default Template',
  status: 'Status',
  shippingLabel: 'Shipping Label',
  coverageArea: 'Coverage Area',
  operations: 'Operations',
  newTemplate: 'New Template',
  updateTemplate: 'Update Template',
  copy: 'Copy',
  edit: 'Edit',
  enable: 'Enable',
  disable: 'Disable',
  setAsDefault: 'Set As Default',
  editCategory: 'Edit Category',
  storeGroup: 'Store Group',
  disableTip:
    'After disabling, the corresponding template will not be available. Are you sure to disable it?',
  categoryDetails: 'Category Details',
  basicInfo: 'Basic Info',
  newCategory: 'New Category',
  updateCategory: 'Update Category',
  categoryId: 'Category ID',
  categoryStatus: 'Category Status',
  categoryLevel: 'Category Level',
  parentCategory: 'Parent Category',
  categoryName: 'Category Name',
  categoryNameEN: 'Category Name (EN)',
  categoryNameCH: 'Category Name (CH)',
  categoryNameTW: 'Category Name (TW)',
  finalCategory: 'final Category',
  categoryIcon: 'category ICON',
  backgroundCategory: 'Background Category',
  associateBackgroundCategory: 'Associate Background Category',
  associateProducts: 'Associate Products',
  batchRemove: 'Batch Remove',
  productId: 'Product Id',
  productTitle: 'Product Title',
  thumbnail: 'Thumbnail',
  category: 'Category',
  upc: 'UPC',
  selectItemsManually: 'Select Items Manually',
  batchImportItems: 'Batch Import Items',
  import: 'Import',
  delete: 'Delete',
  deleteTip:
    'After deletion, the current category will not be visible, and the operation cannot be restored. Are you sure to delete?',
  downloadFileTemplate: 'Download the import file template',
  supportFileType: 'Support uploading xls/xlsx format files',
  batchRemoveTip: 'Confirm to remove?',
  selectAll: 'Select All',
};
