// 积分流水报表
export default {
  platformPointsAccountFlow: 'Platform points account flow',
  userAccountPointsFlow: 'User account points flow',
  principalAccountID: 'Subject account ID',
  orderNumber: 'Order number',
  afterSalesOrderNumber: 'After-sales order number',
  updateTime: 'Points change time',
  reconciliationAccountID: 'Reconciliation account ID',
  itemType: 'Matter type',
  integralSerialNumber: 'Points slip number',
  principalAccountName: 'Main account name',
  reconciliationAccountName: 'Reconciliation account name',
  pointsChange: 'Points change',
  accelerate: 'Accelerate',
  accountBalance: 'Account Balance',
  remark: 'Remark',
};
