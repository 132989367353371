export default {
  title0001: '属性名称',
  title0002: '分类',
  title0003: '状态',
  title0004: '启用',
  title0005: '分类管理',
  title0006: '属性ID',
  title0007: '属性名称',
  title0008: '属性值',
  title0009: '属性分类',
  title0010: '前台展示',
  title0011: '搜索展示',
  title0012: '是否多选',
  title0013: '是否必填',
  title0014: '编辑属性',
  title0015: '更新人',
  title0016: '更新时间',
  title0017: '操作',
  title0018: '新建属性',
  title0019: '禁用',
  title0020: '属性英文名称',
  title0021: '属性繁体名称',
  title0022: '属性简体名称',
  title0023: '属性分类',
  title0024: '前台展示',
  title0025: '请输入数字',
  title0026: '删除后不可恢复，是否确认删除',
  title0027: '搜索展示',
  title0028: '是否多选',
  title0029: '是否必填',
  title0030: '属性值列表',
  title0031: '属性值名称',
  title0032: '属性值英文',
  title0033: '属性值繁体',
  title0034: '排序值',
};
