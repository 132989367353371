export default {
  title0001: '反馈内容',
  title0002: '会员昵称',
  title0003: '提交时间',
  title0004: '处理人',
  title0005: '处理时间',
  title0006: '图片',
  title0007: '来源',
  title0008: '状态',
  title0009: '操作',
  title0010: '处理',
  title0011: '查看',
  title0012: '查看处理意见',
  title0013: '填写处理意见',
  title0014: '处理意见',
  title0015: '限输入200个字',
  title0016: '全部({{num}})',
  title0017: '待处理({{num}})',
  title0018: '已处理({{num}})',
};
