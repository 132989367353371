export default {
  afterSalesOrderNO: '售后单号',
  associateOrderNO: '关联订单号',
  afterSalesStatus: '售后状态',
  userID: '用户 ID',
  userPhoneNO: '用户电话',
  applyRefundAmount: '申请退款金额',
  actualRefundAmount: '实际退款金额',
  minApplyRefundAmount: '最小申请退款金额',
  maxApplyRefundAmount: '最大申请退款金额',
  minActualRefundAmount: '最小实际退款金额',
  maxActualRefundAmount: '最大实际退款金额',
  initiateAfterSalesTime: '发起售后时间',
  minInitiateAfterSalesTime: '最小发起售后时间',
  maxInitiateAfterSalesTime: '最大发起售后时间',
  subSalesOrderNO: '子售后单号',
  memberCardNO: '会员卡号',
  reason: '原因',
  all: '全部',
  refundTypeReview: '退款类型审核',
  refundReviewOnly: '仅退款审核',
  pendingReturnConfirmation: '待退货确认',
  operations: '操作',
  returnToWarehouse: '退货入库',
  productMainImage: '商品主图',
  productName: '商品名称',
  specifications: '规格',
  mainUPCCode: '主UPC编码',
  productId: '商品 ID',
  sellingPrice: '售价',
  pricePerUnitQuantity: '单位数量价格',
  refundableQuantity: '应退数量',
  refundableWeight: '应退重量',
  freeGifts: '赠品',
  returnableItems: '应退商品',
  refundTime: '退款完成时间',
};
