export default {
  title0001: '结算单号',
  title0002: '收款方',
  title0003: '付款方',
  title0004: '更新时间',
  title0005: '结算时间',
  title0006: '核价状态',
  title0007: '支付状态',
  title0008: '批量支付',
  title0009: '结算方式',
  title0010: '支付时间',
  title0011: '结算金额',
  title0012: '支付金额',
  title0013: '未支付余额',
  title0014: '结算方式',
  title0015: '核价时间',
  title0016: '抓取时间段',
  title0017: '结算单状态',
  title0018: '支付方式',
  title0019: '支付失败原因',
  title0020: '支付',
  title0021: '取消支付',
  title0022: '详情',
  title0023: '自动',
  title0024: '手动',
  title0025: '已核价',
  title0026: '正常',
  title0027: '已作废',
  title0028: '待支付',
  title0029: '支付中',
  title0030: '支付失败',
  title0031: '已支付',
  title0032: '无',
  title0033: '作废结算单',
  title0034: '结算任务',
  title0035: '结算任务号',
  title0036: '订单号',
  title0037: '售后单号',
  title0038: '分账金额',
  title0039: '结算单详情',
  title0040: '核价人',
  title0041: '支付详情',
  title0042: '支付单号',
  title0043: '支付流水号',
  title0044: '退款详情',
  title0045: '退款单号',
  title0046: '退款流水号',
  title0047: '退款方式',
  title0048: '退款时间',
  title0049: '退款状态',
  title0050: '作废操作人',
  title0051: '作废时间',
  title0052: '待核算',
  title0053: '核算成功',
  title0054: '核算失败',
  title0055: '请至少选择一条符合操作条件的记录',
  title0056: '退款金额',
  title0057: '成功',
  title0058: '失败',
  title0059: '退款成功',
  title0060: '退款失败',
  title0061: '已支付',
  title0062: '支付失败',
  title0063: '无',
};
