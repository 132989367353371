/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type errorFileOptions = Record<string, any>;

export type errorFileResponse = Promise<any>;

/**
 * @desc 返回上次上传文件失败的excel
 */
export function request(options?: errorFileOptions): errorFileResponse {
  const host = getEnvHost();
  const url = host + '/search/bo/search_tag/product/import_error_file';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  return sendRequest(url, fetchOption);
}
