export default {
  fullScreen: 'Full Screen Mode',
  cancelFullScreen: 'Exit Full Screen Mode',
  ok: 'Ok',
  cancel: 'Cancel',
  export: 'Export',
  import: 'Import',
  reset: 'Reset',
  search: 'Search',
  enabled: 'Enabled',
  disabled: 'Disabled',
  remove: 'Remove',
  select: 'Select',
  submit: 'Submit',
  close: 'Close',
  pass: 'Pass',
  fail: 'Fail',
  save: 'Save',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  yes: 'Yes',
  no: 'No',
  selectFiles: 'Select Files',
  operation: 'Operation',
  confirm: 'confirm',
  check: 'check',
  uploadFiles: 'Upload Files',
};
