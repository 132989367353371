/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type getParam = {
  /** id */
  batchId?: string;
};

export type listOptions = Record<string, any>;

export type pageProductResponse = Promise<
  defs.promotion.Response<Array<number>>
>;

/**
 * @desc 获取上传商品的id
 */
export function request(
  params: getParam,
  options?: listOptions,
): pageProductResponse {
  const host = getEnvHost();
  const url = host + '/promotion/bo/import/file/import/product/id';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
