export default {
  title0001: 'Theme name',
  title0002: 'Shelf status',
  title0003:
    'The product will not be visible in the foreground after shelving, do you want to confirm the shelving?',
  title0004: 'Shelf success',
  title0005: 'Operation',
  title0006: 'New topic',
  title0007: 'Simplified Chinese name',
  title0008: 'Traditional Chinese name',
  title0009: 'English name',
  title0010: 'Number of items',
  title0011: 'Override business',
  title0012: 'O2O area',
  title0013: 'Update Person',
  title0014: 'Update time',
  title0015: 'Status',
  title0016: 'Sort value',
  title0017: 'Edit',
  title0018: 'Delete',
  title0019: 'View thematic product details',
  title0020: 'Manual selection of items',
  title0021: 'Item ID',
  title0022: 'Product title',
  title0023: 'Historical Sales',
  title0024: 'Discount',
  title0025: 'Thumbnail',
  title0026: 'Category',
  title0027: 'Clicks',
  title0028: 'Remove',
  title0029: 'Sort',
  title0030:
    'It cannot be recovered after deletion, do you want to confirm the deletion? ',
  title0031:
    'After removal, the list no longer contains this product, are you sure to remove it? ',
  title0032: 'limit to 30 characters',
  title0033: 'Enter 12 characters only',
  title0034: 'Display order',
  title0035: 'Please enter a positive integer from 1-99',
  title0036: 'Add item',
  title0037: 'Select product',
  title0038: 'Save commit',
  title0039: 'Item selected',
  title0040: 'Search title',
  title0041: 'Select',
  title0042: 'Save commit',
  title0043: 'UPC',
  title0044: 'Image',
  title0045: 'Search by product name、UPC、article number',
  title0046: 'Fixed topics cannot select products',
};
