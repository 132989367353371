export default {
  acceptOrder: '接单',
  top: '置顶',
  orderNo: '订单编号',
  paymentOrderNo: '付款单号',
  userID: '用户 ID',
  userPhoneNO: '用户电话',
  memberCardNo: '会员卡号',
  deliveryMethod: '配送方式',
  orderStatus: '订单状态',
  orderChannel: '下单渠道',
  store: '门店',
  orderTime: '下单时间',
  operations: '操作',
  amountReceivable: '应收金额',
  afterSalesOrderNO: '售后单号',
  afterSale: '售后',
  title0001: '商品前端展示名',
  title0002: 'UPC 编码',
  title0003: '商品ID',
  title0004: '前台类目',
  title0005: '商品类型',
  title0006: '下单数量',
  title0007: '拣货数量',
  title0008: '下单金额',
  title0009: '拣货金额',
  title0010: '拣货结果',
  title0011: '商品',
  title0012: '超时时间',
  title0013: '预计送达时间',
  title0014: '司机是否接单',
  title0015: '配送费',
  title0016: '附加配送费',
  title0017: '配送费总计',
  title0018: '超时时长',
  title0019: '附加配送费订单',
  title0020: '是',
  title0021: '否',
  title0022: '收货地址',
  title0023: '查看操作日志',
  title0024: '操作日志',
  title0025: '操作人姓名',
  title0026: '操作名称',
  title0027: '附加金额',
  title0028: '操作时间',
};
