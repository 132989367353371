import { lazy } from 'react';
import ContentLayout from '@/layouts/ContentLayout';

const UserLogin = lazy(() =>
  import(/* webpackChunkName: "login" */ '@/pages/user/login'),
);
const SecurityLayout = lazy(() =>
  import(/* webpackChunkName: "SecurityLayout" */ '@/layouts/SecurityLayout'),
);
const UserLayout = lazy(() =>
  import(/* webpackChunkName: "UserLayout" */ '@/layouts/UserLayout'),
);
const HomePage = lazy(() =>
  import(/* webpackChunkName: "HomePage" */ '@/pages/HomePage'),
);
const APPLaunchPageConfig = lazy(() =>
  import(
    /* webpackChunkName: "APPLaunchPageConfig" */ '@/pages/FrontLoginM/APPLaunchPageConfig'
  ),
);
const APPBootPageConfig = lazy(() =>
  import(
    /* webpackChunkName: "APPBootPageConfig" */ '@/pages/FrontLoginM/APPBootPageConfig'
  ),
);
const APPLoginPageConfig = lazy(() =>
  import(
    /* webpackChunkName: "APPLoginPageConfig" */ '@/pages/FrontLoginM/APPLoginPageConfig'
  ),
);
const WEBLoginPageBannerConfig = lazy(() =>
  import(
    /* webpackChunkName: "WEBLoginPageBannerConfig" */ '@/pages/FrontLoginM/WEBLoginPageBannerConfig'
  ),
);
const ServiceCoverageAreaConfig = lazy(() =>
  import(
    /* webpackChunkName: "ServiceCoverageAreaConfig" */ '@/pages/FrontLoginM/ServiceCoverageAreaConfig'
  ),
);
const UsingProtocolConfig = lazy(() =>
  import(
    /* webpackChunkName: "UsingProtocolConfig" */ '@/pages/FrontLoginM/UsingProtocolConfig'
  ),
);
const UnservedAreaRegistration = lazy(() =>
  import(
    /* webpackChunkName: "UnservedAreaRegistration" */ '@/pages/FrontLoginM/UnservedAreaRegistration'
  ),
);
const HotSearchWordConfigManagement = lazy(() =>
  import(
    /* webpackChunkName: "HotSearchWordConfigManagement" */ '@/pages/FrontLoginConfigM/HotSearchWordConfigManagement'
  ),
);
const SearchTermSearch = lazy(() =>
  import(
    /* webpackChunkName: "SearchTermSearch" */ '@/pages/FrontLoginConfigM/SearchTermSearch'
  ),
);
const SearchThesaurusMaintenance = lazy(() =>
  import(
    /* webpackChunkName: "SearchThesaurusMaintenance" */ '@/pages/FrontLoginConfigM/SearchThesaurusMaintenance'
  ),
);
const BannerConfigManagement = lazy(() =>
  import(
    /* webpackChunkName: "BannerConfigManagement" */ '@/pages/FrontLoginConfigM/BannerConfigManagement'
  ),
);
const HomepageConfigManagement = lazy(() =>
  import(
    /* webpackChunkName: "HomepageConfigManagement" */ '@/pages/FrontLoginConfigM/HomepageConfigManagement'
  ),
);
const WeeklySpecialsConfig = lazy(() =>
  import(
    /* webpackChunkName: "WeeklySpecialsConfig" */ '@/pages/FrontLoginConfigM/WeeklySpecialsConfig'
  ),
);
const WeeklySpecialsConfigBindProduct = lazy(() =>
  import(
    /* webpackChunkName: "WeeklySpecialsConfigBindProduct" */ '@/pages/FrontLoginConfigM/WeeklySpecialsConfig/bindProduct'
  ),
);
const PopularProductsManagement = lazy(() =>
  import(
    /* webpackChunkName: "PopularProductsManagement" */ '@/pages/FrontLoginConfigM/PopularProductsManagement'
  ),
);
const PopularProductsManagementBindProduct = lazy(() =>
  import(
    /* webpackChunkName: "PopularProductsManagementBindProduct" */ '@/pages/FrontLoginConfigM/PopularProductsManagement/bindProduct'
  ),
);
const NewProductReleaseProductManagement = lazy(() =>
  import(
    /* webpackChunkName: "NewProductReleaseProductManagement" */ '@/pages/FrontLoginConfigM/NewProductReleaseProductManagement'
  ),
);
const NewProductReleaseProductManagementBindProduct = lazy(() =>
  import(
    /* webpackChunkName: "NewProductReleaseProductManagementBindProduct" */ '@/pages/FrontLoginConfigM/NewProductReleaseProductManagement/bindProduct'
  ),
);
const ProductRecommendationManagement = lazy(() =>
  import(
    /* webpackChunkName: "ProductRecommendationManagement" */ '@/pages/FrontLoginConfigM/ProductRecommendationManagement'
  ),
);
const ProductRecommendationDetail = lazy(() =>
  import(
    /* webpackChunkName: "ProductRecommendationDetail" */ '@/pages/FrontLoginConfigM/ProductRecommendationManagement/DetailForm'
  ),
);
const HomepagePopupConfigManagement = lazy(() =>
  import(
    /* webpackChunkName: "HomepagePopupConfigManagement" */ '@/pages/FrontLoginConfigM/HomepagePopupConfigManagement'
  ),
);
const Account = lazy(() =>
  import(/* webpackChunkName: "Account" */ '@/pages/Permission/Account'),
);
const RolePermissions = lazy(() =>
  import(/* webpackChunkName: "RolePermissions" */ '@/pages/Permission/Role'),
);
const Organization = lazy(() =>
  import(
    /* webpackChunkName: "Organization" */ '@/pages/Permission/Organization'
  ),
);
const Menu = lazy(() =>
  import(/* webpackChunkName: "Menu" */ '@/pages/Permission/Menu'),
);

const OperationLog = lazy(() =>
  import(/* webpackChunkName: "Menu" */ '@/pages/Permission/OperationLog'),
);

const CommentModerationManagement = lazy(() =>
  import(
    /* webpackChunkName: "CommentModerationManagement" */ '@/pages/ContentM/CommentModerationManagement'
  ),
);
const PlatformContentCategory = lazy(() =>
  import(
    /* webpackChunkName: "PlatformContentCategory" */ '@/pages/ContentM/PlatformContentCategory'
  ),
);
const PlatformContentRelease = lazy(() =>
  import(
    /* webpackChunkName: "PlatformContentRelease" */ '@/pages/ContentM/PlatformContentRelease'
  ),
);
const RecipeContentCategory = lazy(() =>
  import(
    /* webpackChunkName: "RecipeContentCategory" */ '@/pages/ContentM/RecipeContentCategory'
  ),
);
const RecipeContentRelease = lazy(() =>
  import(
    /* webpackChunkName: "RecipeContentRelease" */ '@/pages/ContentM/RecipeContentRelease'
  ),
);
const HelpCenterManagement = lazy(() =>
  import(
    /* webpackChunkName: "HelpCenterManagement" */ '@/pages/ContentM/HelpCenterManagement'
  ),
);
const IssueCategoryManagement = lazy(() =>
  import(
    /* webpackChunkName: "IssueCategoryManagement" */ '@/pages/ContentM/IssueCategoryManagement'
  ),
);
const FeedbackManagement = lazy(() =>
  import(
    /* webpackChunkName: "FeedbackManagement" */ '@/pages/ContentM/FeedbackManagement'
  ),
);
const BannedWordsManagement = lazy(() =>
  import(
    /* webpackChunkName: "BannedWordsManagement" */ '@/pages/ContentM/BannedWordsManagement'
  ),
);
const RegisterMember = lazy(() =>
  import(
    /* webpackChunkName: "RegisterMember" */ '@/pages/MemberM/RegisterMember'
  ),
);
const PointsDetail = lazy(() =>
  import(/* webpackChunkName: "PointsDetail" */ '@/pages/MemberM/PointsDetail'),
);
const PointsRule = lazy(() =>
  import(/* webpackChunkName: "PointsRule" */ '@/pages/MemberM/PointsRule'),
);
const MemberBenefits = lazy(() =>
  import(
    /* webpackChunkName: "MemberBenefits" */ '@/pages/MemberM/MemberBenefits'
  ),
);
const MemberLevel = lazy(() =>
  import(/* webpackChunkName: "MemberLevel" */ '@/pages/MemberM/MemberLevel'),
);
const CanceledMembers = lazy(() =>
  import(
    /* webpackChunkName: "CanceledMembers" */ '@/pages/MemberM/CanceledMembers'
  ),
);
const OfflinePointsDetail = lazy(() =>
  import(
    /* webpackChunkName: "OfflinePointsDetail" */ '@/pages/MemberM/OfflinePointsDetail'
  ),
);
const ManuallyAdjustPoints = lazy(() =>
  import(
    /* webpackChunkName: "ManuallyAdjustPoints" */ '@/pages/MemberM/ManuallyAdjustPoints'
  ),
);
// 订单管理
const OrderList = lazy(() =>
  import(/* webpackChunkName: "OrderList" */ '@/pages/OrderM/OrderList'),
);
const OrderDetails = lazy(() =>
  import(/* webpackChunkName: "OrderDetails" */ '@/pages/OrderM/OrderDetails'),
);
const AcceptOrder = lazy(() =>
  import(/* webpackChunkName: "AcceptOrder" */ '@/pages/OrderM/AcceptOrder'),
);
const OutOfStockOrder = lazy(() =>
  import(
    /* webpackChunkName: "OutOfStockOrder" */ '@/pages/OrderM/OutOfStockOrder'
  ),
);
const OvertimeOrder = lazy(() =>
  import(
    /* webpackChunkName: "OvertimeOrder" */ '@/pages/OrderM/OvertimeOrder'
  ),
);
const OrderSettings = lazy(() =>
  import(
    /* webpackChunkName: "OrderSettings" */ '@/pages/OrderM/OrderSettings'
  ),
);
const AfterSaleList = lazy(() =>
  import(
    /* webpackChunkName: "AfterSaleList" */ '@/pages/OrderM/AfterSaleList'
  ),
);
const AfterSaleDetails = lazy(() =>
  import(
    /* webpackChunkName: "AfterSaleDetails" */ '@/pages/OrderM/AfterSaleDetails'
  ),
);
// 营销管理
const VoucherActivityList = lazy(() =>
  import(
    /* webpackChunkName: "VoucherActivityList" */ '@/pages/SalesM/VoucherActivityList'
  ),
);

const CouponPromotion = lazy(() =>
  import(
    /* webpackChunkName: 'CouponPromotion' */ '@/pages/SalesM/CouponPromotion'
  ),
);

const CouponPromotionCreate = lazy(() =>
  import(
    /* webpackChunkName: 'CouponPromotion' */ '@/pages/SalesM/CouponPromotion/create'
  ),
);

const CouponPromotionEdit = lazy(() =>
  import(
    /* webpackChunkName: 'CouponPromotion' */ '@/pages/SalesM/CouponPromotion/edit'
  ),
);

const CouponPromotionView = lazy(() =>
  import(
    /* webpackChunkName: 'CouponPromotion' */ '@/pages/SalesM/CouponPromotion/view'
  ),
);

const GlobalDiscount = lazy(() =>
  import(
    /* webpackChunkName: 'GlobalDiscount' */ '@/pages/SalesM/GlobalDiscount'
  ),
);

const CreateGlobalDiscount = lazy(() =>
  import(
    /* webpackChunkName: 'GlobalDiscount' */ '@/pages/SalesM/GlobalDiscount/Create'
  ),
);

const EditGlobalDiscount = lazy(() =>
  import(
    /* webpackChunkName: 'GlobalDiscount' */ '@/pages/SalesM/GlobalDiscount/Edit'
  ),
);

const GlobalDiscountDetail = lazy(() =>
  import(
    /* webpackChunkName: 'GlobalDiscount' */ '@/pages/SalesM/GlobalDiscount/Detail'
  ),
);

const IssuedCoupon = lazy(() =>
  import(/* webpackChunkName: "IssuedCoupon" */ '@/pages/SalesM/IssuedCoupon'),
);
const HandIssue = lazy(() =>
  import(/* webpackChunkName: "HandIssue" */ '@/pages/SalesM/HandIssue'),
);

const AutoIssuanceMgmt = lazy(() =>
  import(
    /* webpackChunkName: "AutoIssuanceMgmt" */ '@/pages/SalesM/AutoIssuanceMgmt'
  ),
);
const LimitedTimeDirectDrop = lazy(() =>
  import(
    /* webpackChunkName: "LimitedTimeDirectDrop" */ '@/pages/SalesM/LimitedTimeDirectDrop'
  ),
);
const BuyGifts = lazy(() =>
  import(/* webpackChunkName: "BuyGifts" */ '@/pages/SalesM/BuyGifts'),
);
const DestroyLogs = lazy(() =>
  import(
    /* webpackChunkName: "DestroyLogs" */ '@/pages/SalesM/IssuedCoupon/DestroyLogs'
  ),
);
const BCategory = lazy(() =>
  import(/* webpackChunkName: "BCategory" */ '@/pages/ItemsM/BCategory'),
);
const FCategory = lazy(() =>
  import(/* webpackChunkName: "FCategory" */ '@/pages/ItemsM/FCategory'),
);
const FCategoryDetail = lazy(() =>
  import(
    /* webpackChunkName: "FCategoryDetail" */ '@/pages/ItemsM/FCategory/CategoryDetails'
  ),
);
const ProductAttributeM = lazy(() =>
  import(
    /* webpackChunkName: "ProductAttributeM" */ '@/pages/ItemsM/ProductAttributeManagement'
  ),
);
const ProductDescriptionM = lazy(() =>
  import(
    /* webpackChunkName: "ProductDescriptionM" */ '@/pages/ItemsM/ProductDescriptionManagement'
  ),
);
const ProductVariantManangement = lazy(() =>
  import(
    /* webpackChunkName: "ProductVariantManangement" */ '@/pages/ItemsM/ProductVariantManangement'
  ),
);
const ProductBrandM = lazy(() =>
  import(
    /* webpackChunkName: "ProductBrandM" */ '@/pages/ItemsM/ProductBrandManagement'
  ),
);
const ProductPolicyM = lazy(() =>
  import(
    /* webpackChunkName: "ProductPolicyM" */ '@/pages/ItemsM/ProductPolicyManagement'
  ),
);
const ProductTagM = lazy(() =>
  import(
    /* webpackChunkName: "ProductTagM" */ '@/pages/ItemsM/ProductTagManagement'
  ),
);
const AttributeCategoryM = lazy(() =>
  import(
    /* webpackChunkName: "AttributeCategoryM" */ '@/pages/ItemsM/AttributeCategoryM'
  ),
);
const ProductInformationM = lazy(() =>
  import(
    /* webpackChunkName: "ProductInformationM" */ '@/pages/ItemsM/ProductInformationM'
  ),
);
const ViewProductDetails = lazy(() =>
  import(
    /* webpackChunkName: "ViewProductDetails" */ '@/pages/ItemsM/ViewProductDetails'
  ),
);
const StoreGroupManagement = lazy(() =>
  import(
    /* webpackChunkName: "StoreGroupManagement" */ '@/pages/StoreM/StoreGroup'
  ),
);
const StoreInfoManagement = lazy(() =>
  import(
    /* webpackChunkName: "StoreInfoManagement" */ '@/pages/StoreM/StoreInfo'
  ),
);
const StoreInfoDetail = lazy(() =>
  import(
    /* webpackChunkName: "StoreInfoDetail" */ '@/pages/StoreM/StoreInfo/ViewStoreDetail/StoreInfoDetail'
  ),
);
const CreateStore = lazy(() =>
  import(
    /* webpackChunkName: "CreateStore" */ '@/pages/StoreM/StoreInfo/CreateAndEditStore/CreateStore'
  ),
);

// 消息管理
const IniticativeSms = lazy(() =>
  import(
    /* webpackChunkName: "IniticativeSms" */ '@/pages/InformationM/InitiativeSms'
  ),
);
const ViewTriggerDetail = lazy(() =>
  import(
    /* webpackChunkName: "ViewTriggerDetail" */ '@/pages/InformationM/InitiativeSms/ViewTriggerDetail'
  ),
);

const InventoryManagement = lazy(() => import('@/pages/WarehouseM/Inventory'));
const WarehouseAdministration = lazy(() =>
  import('@/pages/WarehouseM/WarehouseAdministration'),
);
const ShelfNodeManagement = lazy(() =>
  import('@/pages/WarehouseM/ShelfNodeManagement'),
);
const ShelfPositionManagement = lazy(() =>
  import('@/pages/WarehouseM/ShelfPositionManagement'),
);

const PickingCartManagement = lazy(() =>
  import(
    /* webpackChunkName: "PickingCartBasketManagement" */ '@/pages/WarehouseM/PickingCartBasketManagement'
  ),
);

// 系统配置管理
const BaseSetting = lazy(() => import('@/pages/SettingM/BaseSetting'));
const PlatformFeeSetting = lazy(() => import('@/pages/SettingM/PlatformFee'));
const AppVersionManagement = lazy(() =>
  import('@/pages/SettingM/AppVersionManagement'),
);
const PdaAppVersionManagement = lazy(() =>
  import('@/pages/SettingM/PdaAppVersionManagement'),
);

const SMSManagement = lazy(() => import('@/pages/MessageM/SMS'));
const MailMessageManagement = lazy(() =>
  import('@/pages/MessageM/MailMessageManagement'),
);
const WebsiteMessageManagement = lazy(() =>
  import('@/pages/MessageM/WebsiteMessageManagement'),
);
const PeriodicMessageManagement = lazy(() =>
  import('@/pages/MessageM/PeriodicMessageManagement'),
);

// 财务系统
const SettlementCycle = lazy(() =>
  import('@/pages/FinancialSystem/SettlementCycle'),
);

const SettlementAccount = lazy(() =>
  import('@/pages/FinancialSystem/SettlementAccount'),
);

const BillingStatementList = lazy(() =>
  import('@/pages/FinancialSystem/BillingStatementList'),
);

const AfterSalesListDetail = lazy(() =>
  import('@/pages/FinancialSystem/AfterSalesListDetail'),
);

const AfterSalesProductDetail = lazy(() =>
  import('@/pages/FinancialSystem/AfterSalesProductDetail'),
);

const SettlementTaskManagement = lazy(() =>
  import('@/pages/FinancialSystem/SettlementTaskManagement'),
);

const SettlementOrderManagement = lazy(() =>
  import('@/pages/FinancialSystem/SettlementOrderManagement'),
);

const SettlementOrderItemManagement = lazy(() =>
  import('@/pages/FinancialSystem/SettlementOrderItemManagement'),
);

const OrderDaySummary = lazy(() =>
  import('@/pages/FinancialSystem/OrderDaySummary'),
);

const SettlementTaskList = lazy(() =>
  import('@/pages/FinancialSystem/SettlementTaskList'),
);

const PointsFlowReport = lazy(() =>
  import('@/pages/FinancialSystem/PointsFlowReport'),
);

const PaymentSummary = lazy(() =>
  import('@/pages/FinancialSystem/PaymentSummary'),
);

const PointsBudget = lazy(() => import('@/pages/FinancialSystem/PointsBudget'));

const baseRoutes = [
  {
    name: 'all',
    path: '*',
    hideInMenu: true,
    component: SecurityLayout,
  },
  {
    path: '/user',
    name: 'user',
    component: UserLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: UserLogin,
      },
    ],
  },
];
const indexMenus = [
  {
    path: 'home',
    name: 'home',
    component: HomePage,
    code: 'home',
    parentCode: 'index',
    hideInMenu: true,
  },
];
const loginMenus = [
  {
    path: 'appLaunchPageConfig',
    name: 'menus.top.appLaunchPageConfig',
    component: APPLaunchPageConfig,
    code: 'appLaunchPageConfig',
    parentCode: 'loginConfig',
  },
  {
    path: 'appBootPageConfig',
    name: 'menus.top.appBootPageConfig',
    component: APPBootPageConfig,
    code: 'appBootPageConfig',
    parentCode: 'loginConfig',
  },
  {
    path: 'appLoginPageConfig',
    name: 'menus.top.appLoginPageConfig',
    component: APPLoginPageConfig,
    code: 'appLoginPageConfig',
    parentCode: 'loginConfig',
  },
  {
    path: 'webLoginPageBannerConfig',
    name: 'menus.top.webLoginPageBannerConfig',
    component: WEBLoginPageBannerConfig,
    code: 'webLoginPageBannerConfig',
    parentCode: 'loginConfig',
  },
  {
    path: 'serviceCoverageAreaConfig',
    name: 'menus.top.serviceCoverageAreaConfig',
    component: ServiceCoverageAreaConfig,
    code: 'serviceCoverageAreaConfig',
    parentCode: 'loginConfig',
  },
  {
    path: 'usingProtocolConfig',
    name: 'menus.top.usingProtocolConfig',
    component: UsingProtocolConfig,
    code: 'usingProtocolConfig',
    parentCode: 'loginConfig',
  },
  {
    path: 'unServedAreaRegistration',
    name: 'menus.top.unServedAreaRegistration',
    component: UnservedAreaRegistration,
    code: 'unServedAreaRegistration',
    parentCode: 'loginConfig',
  },
];
const permissionMenus = [
  {
    path: 'role',
    name: 'menus.top.rolePermissions',
    component: RolePermissions,
    code: 'role',
    parentCode: 'permissions',
  },
  {
    path: 'organization',
    name: 'menus.top.organization',
    component: Organization,
    code: 'organization',
    parentCode: 'permissions',
  },
  {
    path: 'account',
    name: 'menus.top.account',
    component: Account,
    code: 'account',
    parentCode: 'permissions',
  },
  {
    path: 'menu',
    name: 'menus.top.menu',
    component: Menu,
    code: 'menu',
    parentCode: 'permissions',
  },
  {
    path: 'operationLog',
    name: 'menus.top.operationLog',
    component: OperationLog,
    code: 'operationLog',
    parentCode: 'permissions',
  },
];
const homeMenus = [
  {
    path: 'hotSearchWordConfigManagement',
    name: 'menus.top.hotSearchWordConfig',
    component: HotSearchWordConfigManagement,
    code: 'hotSearchWordConfigManagement',
    parentCode: 'homeConfig',
  },
  {
    path: 'searchTermSearch',
    name: 'menus.top.searchTermSearch',
    component: SearchTermSearch,
    code: 'searchTermSearch',
    parentCode: 'homeConfig',
  },
  {
    path: 'searchThesaurusMaintenance',
    name: 'menus.top.searchThesaurusMaintenance',
    component: SearchThesaurusMaintenance,
    code: 'searchThesaurusMaintenance',
    parentCode: 'homeConfig',
  },
  {
    path: 'appHomepageBannerConfig',
    name: 'menus.top.appHomepageBannerConfig',
    component: BannerConfigManagement,
    code: 'appHomepageBannerConfig',
    parentCode: 'homeConfig',
  },
  {
    path: 'appHomepageConfig',
    name: 'menus.top.appHomepageConfig',
    component: HomepageConfigManagement,
    code: 'appHomepageConfigManagement',
    parentCode: 'homeConfig',
  },
  {
    path: 'webHomepageBannerConfig',
    name: 'menus.top.webHomepageBannerConfig',
    component: BannerConfigManagement,
    code: 'webHomepageBannerConfig',
    parentCode: 'homeConfig',
  },
  {
    path: 'webTopBannerConfig',
    name: 'menus.top.webTopBannerConfig',
    component: BannerConfigManagement,
    code: 'webTopBannerConfig',
    parentCode: 'homeConfig',
  },
  {
    path: 'webHomepageConfig',
    name: 'menus.top.webHomepageConfig',
    component: HomepageConfigManagement,
    code: 'webHomepageConfig',
    parentCode: 'homeConfig',
  },
  {
    path: 'weeklySpecialsConfig',
    name: 'menus.top.weeklySpecialsConfig',
    component: WeeklySpecialsConfig,
    code: 'weeklySpecialsConfig',
    parentCode: 'homeConfig',
  },
  {
    path: 'weeklySpecialsConfig/bindProduct/:id',
    name: 'menus.top.weeklySpecialsConfig.bindProduct',
    component: WeeklySpecialsConfigBindProduct,
    code: 'weeklySpecialsConfigBindProduct',
    parentCode: 'homeConfig',
    hideInMenu: true,
  },
  {
    path: 'popularProducts',
    name: 'menus.top.popularProducts',
    component: PopularProductsManagement,
    code: 'popularProducts',
    parentCode: 'homeConfig',
  },
  {
    path: 'popularProducts/bindProduct/:id',
    name: 'menus.top.popularProducts.bindProduct',
    component: PopularProductsManagementBindProduct,
    code: 'popularProductsBindProduct',
    parentCode: 'homeConfig',
    hideInMenu: true,
  },
  {
    path: 'newProductReleaseProduct',
    name: 'menus.top.newProductReleaseProduct',
    component: NewProductReleaseProductManagement,
    code: 'newProductReleaseProduct',
    parentCode: 'homeConfig',
  },
  {
    path: 'newProductReleaseProduct/bindProduct/:id',
    name: 'menus.top.newProductReleaseProduct.bindProduct',
    component: NewProductReleaseProductManagementBindProduct,
    code: 'newProductReleaseProductBindProduct',
    parentCode: 'homeConfig',
    hideInMenu: true,
  },
  {
    path: 'productRecommendation',
    name: 'menus.top.productRecommendation',
    component: ProductRecommendationManagement,
    code: 'productRecommendation',
    parentCode: 'homeConfig',
  },
  {
    path: 'productRecommendation/create',
    name: 'menus.top.productRecommendationCreate',
    component: ProductRecommendationDetail,
    code: 'productRecommendation/create',
    parentCode: 'homeConfig',
    hideInMenu: true,
  },
  {
    path: 'productRecommendation/edit',
    name: 'menus.top.productRecommendationEdit',
    component: ProductRecommendationDetail,
    code: 'productRecommendation/edit',
    parentCode: 'homeConfig',
    hideInMenu: true,
  },
  {
    path: 'homepagePopupConfig',
    name: 'menus.top.homepagePopupConfig',
    component: HomepagePopupConfigManagement,
    code: 'homepagePopupConfig',
    parentCode: 'homeConfig',
  },
];
const memberMenus = [
  {
    path: 'registerMember',
    name: 'menus.top.registerMember',
    component: RegisterMember,
    code: 'registerMember',
    parentCode: 'memberInformation',
  },
  {
    path: 'pointsDetail',
    name: 'menus.top.pointsDetail',
    component: PointsDetail,
    code: 'pointsDetail',
    parentCode: 'memberInformation',
  },
  {
    path: 'pointsRule',
    name: 'menus.top.pointsRule',
    component: PointsRule,
    code: 'pointsRule',
    parentCode: 'memberInformation',
  },
  {
    path: 'membershipBenefits',
    name: 'menus.top.membershipBenefits',
    component: MemberBenefits,
    code: 'membershipBenefits',
    parentCode: 'memberInformation',
  },
  {
    path: 'membershipLevel',
    name: 'menus.top.membershipLevel',
    component: MemberLevel,
    code: 'membershipLevel',
    parentCode: 'memberInformation',
  },
  {
    path: 'cancelMemberInformation',
    name: 'menus.top.cancelMemberInformation',
    component: CanceledMembers,
    code: 'cancelMemberInformation',
    parentCode: 'memberInformation',
  },
  {
    path: 'offlinePointsDetail',
    name: 'menus.top.offlinePointsDetail',
    component: OfflinePointsDetail,
    code: 'offlinePointsDetail',
    parentCode: 'memberInformation',
  },
  {
    path: 'manuallyAdjustPoints',
    name: 'menus.top.manuallyAdjustPoints',
    component: ManuallyAdjustPoints,
    code: 'manuallyAdjustPoints',
    parentCode: 'memberInformation',
  },
];
const contentMenus = [
  {
    path: 'commentModeration',
    name: 'menus.top.commentModeration',
    component: CommentModerationManagement,
    code: 'commentModeration',
    parentCode: 'content',
  },
  {
    path: 'platformContentCategory',
    name: 'menus.top.platformContentCategory',
    component: PlatformContentCategory,
    code: 'platformContentClassification',
    parentCode: 'content',
  },
  {
    path: 'platformContentRelease',
    name: 'menus.top.platformContentRelease',
    component: PlatformContentRelease,
    code: 'platformContentPublish',
    parentCode: 'content',
  },
  {
    path: 'recipeContentCategory',
    name: 'menus.top.recipeContentCategory',
    component: RecipeContentCategory,
    code: 'recipeContentCategory',
    parentCode: 'content',
  },
  {
    path: 'recipeContentRelease',
    name: 'menus.top.recipeContentRelease',
    component: RecipeContentRelease,
    code: 'recipeContentPublish',
    parentCode: 'content',
  },
  {
    path: 'helpCenter',
    name: 'menus.top.helpCenter',
    component: HelpCenterManagement,
    code: 'helpCenter',
    parentCode: 'content',
  },
  {
    path: 'helpCenter/issueCategoryManagement',
    name: 'menus.top.issueCategoryManagement',
    component: IssueCategoryManagement,
    code: 'issueCategoryManagement',
    parentCode: 'content',
    hideInMenu: true,
  },
  {
    path: 'feedback',
    name: 'menus.top.feedback',
    component: FeedbackManagement,
    code: 'feedback',
    parentCode: 'content',
  },
  {
    path: 'prohibitedWords',
    name: 'menus.top.prohibitedWords',
    component: BannedWordsManagement,
    code: 'prohibitedWords',
    parentCode: 'content',
  },
];
const orderMenus = [
  {
    path: 'orderList',
    name: 'menus.top.orderList',
    component: OrderList,
    code: 'orderList',
    parentCode: 'order',
  },
  {
    path: 'outOfStockOrder',
    name: 'menus.top.outOfStockOrder',
    component: OutOfStockOrder,
    code: 'outOfStockOrder',
    parentCode: 'order',
  },
  {
    path: 'overtimeOrder',
    name: 'menus.top.overtimeOrder',
    component: OvertimeOrder,
    code: 'overtimeOrder',
    parentCode: 'order',
  },
  {
    path: 'orderDetails',
    name: 'menus.top.orderDetails',
    component: OrderDetails,
    code: 'orderDetails',
    parentCode: 'order',
    hideInMenu: true,
  },
  {
    path: 'acceptOrder',
    name: 'menus.top.acceptOrder',
    component: AcceptOrder,
    code: 'acceptOrder',
    parentCode: 'order',
  },
  {
    path: 'afterSaleList',
    name: 'menus.top.afterSaleList',
    component: AfterSaleList,
    code: 'afterSaleList',
    parentCode: 'order',
  },
  {
    path: 'afterSaleDetails',
    name: 'menus.top.afterSaleDetails',
    component: AfterSaleDetails,
    code: 'afterSaleDetails',
    parentCode: 'order',
    hideInMenu: true,
  },
  {
    path: 'orderSettings',
    name: 'menus.top.orderSettings',
    component: OrderSettings,
    code: 'orderSettings',
    parentCode: 'order',
  },
];
const salesMenus = [
  {
    path: 'voucherActivityList',
    name: 'menus.top.couponActivities',
    component: VoucherActivityList,
    code: 'couponActivities',
    parentCode: 'marketingManagement',
  },
  {
    path: 'issuedCoupon',
    name: 'menus.top.issuedCoupon',
    component: IssuedCoupon,
    code: 'issuedCoupon',
    parentCode: 'marketingManagement',
  },
  {
    path: 'destroyLogs',
    name: 'menus.top.destroyLogs',
    component: DestroyLogs,
    code: 'destroyLogs',
    parentCode: 'marketingManagement',
    hideInMenu: true,
  },
  {
    path: 'handIssue',
    name: 'menus.top.issueCouponsManually',
    component: HandIssue,
    code: 'issueCouponsManually',
    parentCode: 'marketingManagement',
  },
  {
    path: 'autoIssuanceMgmt',
    name: 'menus.top.automatedCouponIssuanceManagement',
    component: AutoIssuanceMgmt,
    code: 'automatedCouponIssuanceManagement',
    parentCode: 'marketingManagement',
  },
  {
    path: 'limitedTimeDirectDrop',
    name: 'menus.top.limitedTimeDirectDrop',
    component: LimitedTimeDirectDrop,
    code: 'limitedTimeDirectDrop',
    parentCode: 'marketingManagement',
  },
  {
    path: 'buyGifts',
    name: 'menus.top.buyGifts',
    component: BuyGifts,
    code: 'buyGifts',
    parentCode: 'marketingManagement',
  },
  {
    path: 'couponPromotion',
    name: 'menus.top.couponPromotion',
    component: CouponPromotion,
    code: 'couponPromotion',
    parentCode: 'marketingManagement',
  },
  {
    path: 'couponPromotion/create',
    name: 'pages.couponPromotion.add_activity',
    component: CouponPromotionCreate,
    code: 'couponPromotion',
    parentCode: 'marketingManagement',
    hideInMenu: true,
  },
  {
    path: 'couponPromotion/edit',
    name: 'pages.couponPromotion.edit_activity',
    component: CouponPromotionEdit,
    code: 'couponPromotion/edit',
    parentCode: 'marketingManagement',
    hideInMenu: true,
  },
  {
    path: 'couponPromotion/view',
    name: 'pages.couponPromotion.view_activity',
    component: CouponPromotionView,
    code: 'couponPromotion/view',
    parentCode: 'marketingManagement',
    hideInMenu: true,
  },
  {
    path: 'globalDiscount',
    name: 'menus.top.globalDiscount',
    component: GlobalDiscount,
    code: 'globalDiscount',
    parentCode: 'marketingManagement',
  },
  {
    path: 'globalDiscount/add',
    name: 'pages.globalDiscount.add',
    component: CreateGlobalDiscount,
    code: 'globalDiscount/add',
    parentCode: 'marketingManagement',
    hideInMenu: true,
  },
  {
    path: 'globalDiscount/edit/:id',
    name: 'pages.globalDiscount.edit',
    component: EditGlobalDiscount,
    code: 'globalDiscount/edit',
    parentCode: 'marketingManagement',
    hideInMenu: true,
  },
  {
    path: 'globalDiscount/detail/:id',
    name: 'pages.globalDiscount.detail',
    component: GlobalDiscountDetail,
    code: 'globalDiscount/detail',
    parentCode: 'marketingManagement',
    hideInMenu: true,
  },
];

const itemsMenus = [
  {
    path: 'bCategory',
    name: 'menus.top.bCategory',
    component: BCategory,
    code: 'bCategory',
    parentCode: 'items',
  },
  {
    path: 'fCategory',
    name: 'menus.top.fCategory',
    component: FCategory,
    code: 'fCategory',
    parentCode: 'items',
  },
  {
    path: 'fCategoryDetail',
    name: 'menus.top.fCategoryDetail',
    component: FCategoryDetail,
    code: 'fCategoryDetail',
    parentCode: 'items',
    hideInMenu: true,
  },
  {
    path: 'productAttributeManagement',
    name: 'menus.top.productAttributeManagement',
    component: ProductAttributeM,
    code: 'productAttributeManagement',
    parentCode: 'items',
  },
  {
    path: 'attributeCategoryManagement',
    name: 'menus.top.attributeCategoryManagement',
    component: AttributeCategoryM,
    code: 'attributeCategoryManagement',
    parentCode: 'items',
    hideInMenu: true,
  },
  {
    path: 'productInformationManagement',
    name: 'menus.top.productInformationManagement',
    component: ProductInformationM,
    code: 'productInformationManagement',
    parentCode: 'items',
  },
  {
    path: 'viewProductDetails',
    name: 'menus.top.productDetails',
    component: ViewProductDetails,
    code: 'productDetails',
    parentCode: 'items',
    hideInMenu: true,
  },
  {
    path: 'productDescriptionManagement',
    name: 'menus.top.productDescriptionManagement',
    component: ProductDescriptionM,
    code: 'productDescriptionManagement',
    parentCode: 'items',
  },
  {
    path: 'productBrandManagement',
    name: 'menus.top.productBrandManagement',
    component: ProductBrandM,
    code: 'productBrandManagement',
    parentCode: 'items',
  },
  {
    path: 'productPolicyManagement',
    name: 'menus.top.productPolicyManagement',
    component: ProductPolicyM,
    code: 'productPolicyManagement',
    parentCode: 'items',
  },
  {
    path: 'productVariantManangement',
    name: 'menus.top.productVariantManangement',
    component: ProductVariantManangement,
    code: 'productVariantManangement',
    parentCode: 'items',
    hideInMenu: true,
  },
  {
    path: 'productTagManagement',
    name: 'menus.top.productTagManagement',
    component: ProductTagM,
    code: 'productTagManagement',
    parentCode: 'items',
  },
];

const storeMenus = [
  {
    path: 'storeGroupManagement',
    name: 'menus.top.storeGroup',
    component: StoreGroupManagement,
    code: 'storeGroupManagement',
    parentCode: 'allStoreConfig',
  },
  {
    path: 'storeInfoManagement',
    name: 'menus.top.storeInfo',
    component: StoreInfoManagement,
    code: 'storeInfoManagement',
    parentCode: 'allStoreConfig',
  },
  {
    path: 'storeInfoManagement/view',
    name: 'menus.top.storeInfoDetail',
    component: StoreInfoDetail,
    code: 'storeInfoManagement/view',
    hideInMenu: true,
    parentCode: 'allStoreConfig',
  },
  {
    path: 'storeInfoManagement/create',
    name: 'menus.top.createStore',
    component: CreateStore,
    code: 'storeInfoManagement/create',
    hideInMenu: true,
    parentCode: 'allStoreConfig',
  },
  {
    path: 'productManagement',
    name: 'menus.top.productManagement',
    component: lazy(() => import('@/pages/StoreM/ProductMgmt')),
    code: 'productManagement',
    parentCode: 'allStoreConfig',
  },
  {
    path: 'promotionManagement',
    name: 'menus.top.promotionManagement',
    component: lazy(() => import('@/pages/StoreM/PromoMgmt')),
    code: 'promotionManagement',
    parentCode: 'allStoreConfig',
  },
  {
    path: 'storeIntroManagement',
    name: 'menus.top.storeIntroManagement',
    component: lazy(() => import('@/pages/StoreM/StoreIntro')),
    code: 'storeIntroManagement',
    parentCode: 'allStoreConfig',
  },
];

const warehouseMenus = [
  {
    path: 'incomingDocument',
    name: 'menus.top.incomingDocument',
    component: lazy(() => import('@/pages/WarehouseJobM/IncomingGoods')),
    code: 'incomingDocument',
    parentCode: 'warehouseJob',
  },
  {
    path: 'outgoingDocument',
    name: 'menus.top.outgoingDocument',
    component: lazy(() => import('@/pages/WarehouseJobM/OutgoingGoods')),
    code: 'outgoingDocument',
    parentCode: 'warehouseJob',
  },
];

const warehouseMgtMenus = [
  {
    path: 'warehouseAdministration',
    name: 'menus.top.warehouseAdministration',
    component: WarehouseAdministration,
    code: 'warehouseAdministration',
    parentCode: 'warehouseMgt',
  },
  {
    path: 'inventory',
    name: 'menus.top.inventoryManagement',
    component: InventoryManagement,
    code: 'inventoryManagement',
    parentCode: 'warehouseMgt',
  },
  {
    path: 'shelf',
    name: 'menus.top.shelfNodeManagement',
    component: ShelfNodeManagement,
    code: 'shelfNodeManagement',
    parentCode: 'warehouseMgt',
  },
  {
    path: 'shelfpos',
    name: 'menus.top.shelfPositionManagement',
    component: ShelfPositionManagement,
    code: 'shelfPositionManagement',
    parentCode: 'warehouseMgt',
  },
  {
    path: 'pickingcart',
    name: 'menus.top.pickingcartManagement',
    component: PickingCartManagement,
    code: 'pickingcartManagement',
    parentCode: 'warehouseMgt',
  },
];

const settingMenus = [
  {
    path: 'baseSettingManagement/member',
    name: 'menus.top.baseSettingManagement',
    component: BaseSetting,
    code: 'baseSetting',
    parentCode: 'systemSetting',
  },
  {
    path: 'baseSettingManagement/customer',
    name: 'menus.top.baseSettingManagement',
    component: BaseSetting,
    code: 'baseSetting',
    parentCode: 'systemSetting',
    hideInMenu: true,
  },
  {
    path: 'baseSettingManagement/shoppingCart',
    name: 'menus.top.baseSettingManagement',
    component: BaseSetting,
    code: 'baseSetting',
    parentCode: 'systemSetting',
    hideInMenu: true,
  },

  {
    path: 'appVersionManagement',
    name: 'menus.top.appVersionManagement',
    component: AppVersionManagement,
    code: 'appVersionManagement',
    parentCode: 'systemSetting',
  },
  {
    path: 'pdaAppVersionManagement',
    name: 'menus.top.pdaAppVersionManagement',
    component: PdaAppVersionManagement,
    code: 'pdaAppVersionManagement',
    parentCode: 'systemSetting',
  },
];

const messageMenus = [
  {
    path: 'websiteMessageManagement',
    name: 'menus.top.websiteMessageManagement',
    component: WebsiteMessageManagement,
    code: 'websiteMessageManagement',
    parentCode: 'message',
  },
  {
    path: 'smsManagement',
    name: 'menus.top.smsM',
    component: SMSManagement,
    code: 'smsManagement',
    parentCode: 'message',
  },
  {
    path: 'mailMessageManagement',
    name: 'menus.top.mailMessageManagement',
    component: MailMessageManagement,
    code: 'mailMessageManagement',
    parentCode: 'message',
  },
  {
    path: 'periodicMessageManagement',
    name: 'menus.top.periodicMessageManagement',
    component: PeriodicMessageManagement,
    code: 'periodicMessageManagement',
    parentCode: 'message',
  },
  {
    path: 'messageCenter',
    name: 'menus.top.messageCenter',
    component: lazy(() => import('@/pages/MessageM/MessageCenter')),
    code: 'messageCenter',
    parentCode: 'message',
    hideInMenu: true,
  },
  {
    path: 'initiative-sms',
    name: 'menus.top.initiativeSms',
    component: IniticativeSms,
    code: 'initiativeSms',
    parentCode: 'message',
  },
  {
    path: 'initiative-sms/view',
    name: 'menus.top.viewTriggerDetail',
    component: ViewTriggerDetail,
    code: 'initiative-sms/view',
    parentCode: 'message',
    hideInMenu: true,
  },
];

// 财务系统
const financialSystemMenus = [
  {
    path: 'settlement',
    name: 'menus.top.settlementManagement',
    component: ContentLayout,
    code: 'settlementManagement',
    parentCode: 'financialSystem',
    children: [
      {
        path: 'settlementTaskList',
        name: 'menus.top.settlementTaskList',
        component: SettlementTaskList,
        code: 'settlementTaskList',
        parentCode: 'settlementManagement',
      },
      {
        path: 'billingStatementList',
        name: 'menus.top.billingStatementList',
        component: BillingStatementList,
        code: 'billingStatementList',
        parentCode: 'settlementManagement',
      },
      {
        path: 'settlementTaskManagement',
        name: 'menus.top.settlementTaskManagement',
        component: SettlementTaskManagement,
        code: 'settlementTaskManagement',
        parentCode: 'settlementManagement',
      },
    ],
  },
  {
    path: 'settlementReport',
    name: 'menus.top.settlementReport',
    component: ContentLayout,
    code: 'settlementReport',
    parentCode: 'financialSystem',
    children: [
      {
        path: 'settlementOrderManagement',
        name: 'menus.top.settlementOrderManagement',
        component: SettlementOrderManagement,
        code: 'settlementOrderManagement',
        parentCode: 'settlementReport',
      },
      {
        path: 'settlementOrderItemManagement',
        name: 'menus.top.settlementOrderItemManagement',
        component: SettlementOrderItemManagement,
        code: 'settlementOrderItemManagement',
        parentCode: 'settlementReport',
      },
      {
        path: 'afterSalesListDetails',
        name: 'menus.top.afterSalesListDetails',
        component: AfterSalesListDetail,
        code: 'afterSalesListDetails',
        parentCode: 'settlementReport',
      },
      {
        path: 'afterSalesProductDetail',
        name: 'menus.top.afterSalesProductDetail',
        component: AfterSalesProductDetail,
        code: 'afterSalesProductDetail',
        parentCode: 'settlementReport',
      },
      {
        path: 'orderDaySummary',
        name: 'menus.top.orderDaySummary',
        component: OrderDaySummary,
        code: 'orderDaySummary',
        parentCode: 'settlementReport',
      },
    ],
  },
  {
    path: 'settlementSetting',
    name: 'menus.top.settlementSetting',
    component: ContentLayout,
    code: 'settlementSetting',
    parentCode: 'financialSystem',
    children: [
      {
        path: 'settlementCycle',
        name: 'menus.top.settlementCycle',
        component: SettlementCycle,
        code: 'settlementCycle',
        parentCode: 'settlementSetting',
      },
      {
        path: 'settlementAccount',
        name: 'menus.top.settlementAccount',
        component: SettlementAccount,
        code: 'settlementAccount',
        parentCode: 'settlementSetting',
      },
      {
        path: 'PlatformFeeManagement',
        name: 'menus.top.PlatformFeeManagement',
        component: PlatformFeeSetting,
        code: 'PlatformFeeSetting',
        parentCode: 'settlementSetting',
      },
    ],
  },
  {
    path: 'paymentSummary',
    name: 'menus.top.paymentSummary',
    component: PaymentSummary,
    code: 'paymentSummary',
    parentCode: 'financialSystem',
  },
  {
    path: 'pointsBudgetManagement',
    name: 'menus.top.pointsBudgetManagement',
    component: ContentLayout,
    code: 'pointsBudgetManagement',
    parentCode: 'financialSystem',
    children: [
      {
        path: 'pointsBudget',
        name: 'menus.top.pointsBudget',
        component: PointsBudget,
        code: 'pointsBudget',
        parentCode: 'pointsBudgetManagement',
      },
      {
        path: 'pointsFlowReport',
        name: 'menus.top.pointsFlowReport',
        component: PointsFlowReport,
        code: 'pointsFlowReport',
        parentCode: 'pointsBudgetManagement',
      },
    ],
  },
];

export {
  baseRoutes,
  contentMenus,
  financialSystemMenus,
  homeMenus,
  indexMenus,
  itemsMenus,
  loginMenus,
  memberMenus,
  messageMenus,
  orderMenus,
  permissionMenus,
  salesMenus,
  settingMenus,
  storeMenus,
  warehouseMenus,
  warehouseMgtMenus,
};
