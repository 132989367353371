export const AuthCode = Object.freeze({
  EDIT: 'edit',
  DELETE: 'delete',
  IMPORT: 'import',
  EXPORT: 'export',
  REJECT: 'reject',
  REMOVE: 'remove',
  CONFIRM: 'confirm',
  CHECK: 'check',
  SAVE: 'save',
  COPY: 'copy',
  BATCH: 'batch',
  RESET: 'reset',
  CREATE: 'create',
  TOGGLE: 'toggle',
  BATCH_ON_SHELVES: 'batchOnShelves',
  BATCH_OFF_SHELVES: 'batchOffShelves',
  SORT: 'sort',
  DETAIL: 'detail',
  REVIEW: 'review',
  CATEGORY: 'category',
  NEW_CATEGORY: 'newCategory',
  EDIT_CATEGORY: 'editCategory',
  TOGGLE_CATEGORY: 'toggleCategory',
  DEAL: 'deal',
  ORG_CONFIG: 'orgConfig',
  OPERATION_CONFIG: 'operationConfig',
  DATA_CONFIG: 'dataConfig',
  DISABLE: 'disable',
  ENABLE: 'enable',
  VIEW: 'view',
  VIEW_LOGS: 'viewLogs',
  SET_OPERATION_ACCESS: 'setOperationAccess',
  SET_DATA_ACCESS: 'setDataAccess',
  ASSIGN: 'assign',
  SET_EXPIRED: 'setExpired',
});
