export default {
  title0001: 'Bulk manual couponing',
  title0002: 'Manual couponing log',
  title0003: 'Batch information',
  title0004: 'Batch Name',
  title0005: 'Reason for issuing',
  title0006: 'Selection of coupons & number of coupons issued',
  title0007: 'Coupon activity',
  title0008: 'Number of individual users issued',
  title0009: 'Select users to push coupons to',
  title0010: 'Filter users by selection criteria',
  title0011: 'Bulk import via Excel',
  title0012: 'Mobile number',
  title0013: 'Member nickname',
  title0014: 'Registration source',
  title0015: 'Membership Level',
  title0016: 'View selected users',
  title0017: 'User ID',
  title0018: 'Member nickname',
  title0019: 'Mobile phone number',
  title0020: 'Registration source',
  title0021: 'Member Level',
  title0022: 'User status',
  title0023: 'Select file',
  title0024: 'Template Download',
  title0025:
    '[Tip] Support cell phone number or user ID to upload matching users, just fill in any of the fields',
  title0026: 'Batch number',
  title0027: 'Operator',
  title0028: 'Operation time',
  title0029: 'Number of users involved',
  title0030: 'Reason for issuance',
  title0031: 'Operation',
  title0032: 'View batch details',
  title0033: 'Batch details',
  title0034: 'Voucher Activity Code',
  title0035: 'Voucher Activity Name',
  title0036: 'Support positive numbers up to {{num}} digits',
  title0037: 'View',
  title0038: 'Coupon parameters',
  title0039: 'Coupon activity information',
  title0040: 'Voucher type',
  title0041: 'Allowed amount',
  title0042: 'Discount value',
  title0043: 'Tips',
  title0044:
    'Coupon issued successfully! A total of {{num}} coupons were issued, involving {{user}} users.',
  title0045:
    'Operation failed! Please export the Excel sheet to see the details.',
  title0046: 'Exporting Excel sheet with error message',
  title0047:
    'After switching the user selection method, the users selected/imported in the previous method will be cleared.',
  title0048:
    'User data matching in progress, may take some time, please do not leave ......',
  title0049: 'Support no more than {{num}} bits characters',
  title0050: 'Batch name',
  title0051: 'Number of coupons issued',
  title0052: 'Selected users',
  title0053: 'Confirm coupon issuance',
  title0054: 'Please select user data',
  title0055:
    '[Tip] Support cell phone number or user ID to upload matching users, just fill in any of the fields',
  title0056: 'Template download',
  title0057: 'Select file',
  title0058: 'Please select excel file',
  title0059: 'Voucher issue time',
};
