export default {
  title0001: 'New Category',
  title0002: 'Edit Category',
  title0003: 'Category name',
  title0004: 'Category Status',
  title0005: 'Operation',
  title0006: 'Edit',
  title0007: 'Category ID',
  title0008: 'Category Name (CN)',
  title0009: 'Category Name (TW)',
  title0010: 'Category Name (EN)',
  title0011: 'Number of content',
  title0012: 'Updated by',
  title0013: 'Update time',
  title0014: 'Status',
  title0015: 'Category Code',
  title0016: 'Please enter, limited to 10 words',
  title0017: 'Please enter, limit to 50 characters',
  title0018: 'Enter numbers and letters only',
  title0019: 'Enabled successfully',
  title0020: 'Will be disabled, please confirm',
  title0021: 'Enable',
  title0022: 'Disable',
};
