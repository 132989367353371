export default {
  title0001: '安卓版本管理',
  title0002: 'iOS版本管理',
  title0003: '版本号',
  title0004: '新建版本',
  title0005: '安卓版本号',
  title0006: '安卓下载地址',
  title0007: '强制更新',
  title0008: '版本描述',
  title0009: '更新人',
  title0010: '更新时间',
  title0011: '操作',
  title0012: '编辑',
  title0013: '安卓版本号',
  title0014: '限输入200字符',
  title0015: 'iOS版本号',
  title0016: 'iOS下载地址',
};
