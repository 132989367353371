export default {
  balance: 'Available points balance',
  code: 'Account Code',
  createdTime: 'Created Time',
  holder: 'holder',
  id: 'Platform Account ID',
  name: 'account name',
  purpose: 'purpose',
  status: 'status',
  statusName: 'status name',
  totalAmount: 'Total points',
  type: 'Type',
  typeName: 'type name',
  change: 'Amount change',
  changeType: 'change type',
  changeValue: 'change amount',
  notice: 'prompt account',
  noticeText:
    'Reminder: The account set here will receive a message prompt when the balance of the points account is lower than 20%, 10%, or 5% of the total points! ',
  noticeSub: 'New account',
  noticeName: 'Account Name',
  noticeCode: 'account code',
  noticeSelect: 'Account selected',
  flowCreatedTime: 'Time',
  flowOperator: 'Operator',
  flowAmount: 'Change Amount',
  flowBalance: 'Account Balance',
  accountFlow: 'Account Flow',
};
