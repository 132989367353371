export default {
  account: '用户账号',
  username: '用户名称',
  department: '归属部门',
  phoneNumber: '手机号码',
  email: '邮箱',
  assignRoles: '分配角色',
  passwordValidityPeriod: '密码有效期',
  status: '状态',
  createdTime: '创建日期',
  newAccount: '新建账号',
  updateAccount: '更新账号',
  disableAccountTip: '禁用后该用户将无法登录系统，是否确认？',
  inputPlaceholder: '请输入用户账号/姓名',
  selectPlaceholder: '请选择用户状态',
  password: '登录密码',
  accountPlaceholder: '请输入用户账号',
  usernamePlaceholder: '请输入用户名称',
  departmentPlaceholder: '请选择归属部门',
  passwordPlaceholder: '8-20位包含数字、字母、英文符号',
  assignRolesPlaceholder: '请选择角色',
  phoneNumberPlaceholder: '请输入手机号',
  emailPlaceholder: '请输入邮箱',
  passwordValidityPeriodPlaceholder: '请输入密码有效期',
  assignedTime: '分配时间',
  roleList: '角色列表',
  setExpirationDate: '设置有效期',
  resetPassword: '重置密码',
  importData: '导入用户数据',
  downloadFileTemplate: '下载导入文件模板',
  templateName: '模版',
  viewList: '查看列表',
};
