export default {
  title0001: 'New login page',
  title0002: 'Edit login page',
  title0003:
    'The front desk will not be visible after removal. Are you sure to remove?',
  title0004: 'On a successful',
  title0005: 'Operation',
  title0006: 'Edit',
  title0007: 'Coverage Service',
  title0008: 'O2O area',
  title0009: 'Launch page name',
  title0010: 'Content',
  title0011: 'Content type',
  title0012: 'Expiry Date',
  title0013: 'Creator',
  title0014: 'Creation time',
  title0015: 'Renewed ',
  title0016: 'Update time',
  title0017: 'Status',
  title0018: 'Upload content',
  title0019: 'Select the file',
  title0020: 'Content of the name',
  title0021: 'Limit the input to 12 words',
  title0022: 'Valid time',
  title0023: 'Confirm to submit',
  title0024:
    'Image upload jpg/png/gif file; Video uploaded to MP4 takes {{num}} seconds',
};
