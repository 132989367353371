/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type templateListParam = {
  /** current */
  current?: number;

  /** functionType */
  functionType?: number;

  /** size */
  size?: number;
};
export type templateListOptions = Record<string, any>;

export type templateListResponse = Promise<
  defs.product.Response<defs.product.Page<defs.product.FunctionTemplateDO>>
>;

/**
 * @desc 功能区模板列表
 */
export function request(
  params: templateListParam,
  options?: templateListOptions,
): templateListResponse {
  const host = getEnvHost();
  const url = host + '/product/bo/function/template/list';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
