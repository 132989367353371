/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type cancelOrderPdaBody = defs.order.OrderIdsDTO;
export type cancelOrderPdaOptions = Record<string, any>;

export type cancelOrderPdaResponse = Promise<defs.order.Response<Set<string>>>;

/**
 * @desc cancelOrderPda
 */
export function request(
  body: cancelOrderPdaBody,
  options?: cancelOrderPdaOptions,
): cancelOrderPdaResponse {
  const host = getEnvHost();
  const url = host + '/order/bo/support/order/cancel/pda/fix';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
