/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Upload, message, ButtonProps } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { isString } from 'lodash-es';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { promotionAPI } from '@/services';

const UPLOAD_CONTENT_TYPE = 'image/jpeg';
const MP4 = 'video/mp4';

const DEFAULT_FILE_COUNT = 1;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

// TODO To Be Optimized
type ImageUProps = {
  onChange?: (value: string[]) => void;
  maxCount?: number;
  accept?: string;
  imageText?: string;
  value?: string[];
  limitSize?: number;
  showButton?: boolean;
  tip?: JSX.Element;
  buttonConfig?: ButtonProps;
  buttonTitle?: React.ReactNode;
  fileType?: string[];
  imgLimitSize: number;
  videoLimitTime: number;
} & UploadProps;

const ImageUpload = (props: ImageUProps) => {
  const {
    onChange,
    maxCount = DEFAULT_FILE_COUNT,
    accept = '.png,.jpg,.jpeg',
    fileType = ['image/jpeg', 'image/png'],
    imageText = 'Upload',
    value,
    imgLimitSize = 2,
    videoLimitTime = 5,
    showButton = false,
    tip = null,
    buttonConfig,
    buttonTitle,
    ...uploadProps
  } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<UploadFile>();
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
  };

  const beforeUpload = (file: RcFile) => {
    const allowTypes = fileType
      .map((type) => {
        return type.split('/')[1];
      })
      .join(',');
    const isAllowType = fileType.includes(file.type);
    const isMp4 = file.type === 'video/mp4';
    if (!isAllowType) {
      message.error(`You can only upload ${allowTypes} file!`);
      return false;
    }
    const isLtImgSize = file.size / 1024 / 1024 < imgLimitSize;
    if (!isMp4) {
      if (!isLtImgSize) {
        message.error(`Image must smaller than ${imgLimitSize}MB!`);
        return false;
      }
      return true;
    }
    return new Promise<RcFile>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const video = document.createElement('video');
        video.src = e.target.result;
        video.addEventListener('loadedmetadata', () => {
          const { duration } = video;
          if (Math.floor(duration) > videoLimitTime) {
            message.error(
              `Video duration should not exceed ${videoLimitTime}s ！`,
            );
            reject();
          } else {
            resolve(file);
          }
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onChange &&
      onChange(
        (maxCount === DEFAULT_FILE_COUNT
          ? newFileList?.[0]?.response
          : newFileList.map((file) => file.response)) || '',
      );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{imageText}</div>
    </div>
  );

  const showUploadButton = () => {
    return showButton ? (
      <Button {...buttonConfig}>{buttonTitle}</Button>
    ) : (
      uploadButton
    );
  };

  const handleUpload = async ({ file, onSuccess }: any) => {
    const contentType = file.type === MP4 ? MP4 : UPLOAD_CONTENT_TYPE;
    const { data, success } = await promotionAPI.boCoupon.getFileUrl.request({
      contentType,
    });
    if (success) {
      const url = data?.url || '';
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: file,
      })
        .then(() => {
          onSuccess(data?.key);
          message.success('upload successfully.');
        })
        .catch(() => {
          message.error('upload failed.');
        });
    } else {
      message.error('upload failed.');
    }
  };

  useEffect(() => {
    if (value && isString(value)) {
      setFileList([{ url: value, uid: uuidv4(), name: uuidv4() }]);
    }
  }, [value]);

  return (
    <>
      {tip}
      <Upload
        accept={accept}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        maxCount={maxCount}
        beforeUpload={beforeUpload}
        customRequest={handleUpload}
        onChange={handleChange}
        {...uploadProps}
      >
        {fileList.length >= maxCount ? null : showUploadButton()}
      </Upload>

      <Modal
        open={previewOpen}
        title={previewTitle}
        onCancel={handleCancel}
        footer={null}
      >
        {previewImage && previewImage.url?.includes('.mp4') && (
          <video
            controls
            width="100%"
            key={JSON.stringify(previewImage)}
            autoPlay={false}
          >
            <source src={previewImage.url || previewImage.preview} type={MP4} />
          </video>
        )}
        {previewImage && !previewImage.url?.includes('.mp4') && (
          <img
            alt="img"
            style={{ width: '100%' }}
            src={previewImage.url || previewImage.preview}
          />
        )}
      </Modal>
    </>
  );
};

export default ImageUpload;
