/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type startPickingParam = {
  /** orderId */
  orderId: string;
};
export type startPickingOptions = Record<string, any>;

export type startPickingResponse = Promise<defs.order.Response<void>>;

/**
 * @desc mock-开始捡货
 */
export function request(
  params: startPickingParam,
  options?: startPickingOptions,
): startPickingResponse {
  const host = getEnvHost();
  const url = host + '/order/bo/support/mock/startpicking';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
