export default {
  contentType: {
    describe: 'Content type',
    title001: 'Picture',
    title002: 'Video',
  },
  shelvesStatus: {
    describe: 'Shelves status',
    title001: 'Has been on',
    title002: 'Has been off the shelves',
    title003: 'The shelves',
    title004: 'Shelves',
  },
  pointType: {
    describe: 'Point type',
    title001: 'Front Desk Categories',
    title002: 'Goods',
    title003: 'Customize the page',
    title004: 'No Point',
  },
  coverageType: {
    describe: 'Coverage Service',
    title001: 'default',
    title002: '8899',
    title003: '9988',
    title004: 'O2O',
  },
  o2oType: {
    describe: 'O2O area',
    title001: 'NCA',
    title002: 'SCA',
    title003: 'EC',
    title004: 'TC',
  },
  countryType: {
    describe: 'Country type',
    title001: 'American',
  },
  american: {
    describe: 'American all state',
    title001: 'Alabama',
    title002: 'Alaska',
    title003: 'Arizona',
    title004: 'Arkansas',
    title005: 'California',
    title006: 'Colorado',
    title007: 'Connecticut',
    title008: 'Delaware',
    title009: 'Florida',
    title010: 'Georgia',
    title011: 'Hawaii',
    title012: 'Idaho',
    title013: 'Illinois',
    title014: 'Indiana',
    title015: 'Iowa',
    title016: 'Kansas',
    title017: 'Kentucky',
    title018: 'Louisiana',
    title019: 'Maine',
    title020: 'Maryland',
    title021: 'Massachusetts',
    title022: 'Michigan',
    title023: 'Minnesota',
    title024: 'Mississippi',
    title025: 'Missouri',
    title026: 'Montana',
    title027: 'Nebraska',
    title028: 'Nevada',
    title029: 'New Hampshire',
    title030: 'NewJersey',
    title031: 'New Mexico',
    title032: 'NewYork',
    title033: 'North Carolina',
    title034: 'North Dakota',
    title035: 'Ohio',
    title036: 'Oklahoma',
    title037: 'Oregon',
    title038: 'Pennsylvania',
    title039: 'Rhode Island',
    title040: 'South Carolina',
    title041: 'South Dakota',
    title042: 'Tennessee',
    title043: 'Texas',
    title044: 'Utah',
    title045: 'Vermont',
    title046: 'Virginia',
    title047: 'Washington',
    title048: 'West Virginia',
    title049: 'Wisconsin',
    title050: 'Wyoming',
    title051: 'Washington D.C.',
  },
  matchType: {
    describe: 'match type',
    title001: 'Exact match',
    title002: 'Fuzzy match',
  },
  checkType: {
    describe: 'Audit Status',
    title001: 'Pending review',
    title002: 'Reviewed',
    title003: 'Rejected',
  },
  checkStatus: {
    describe: 'Audit Results',
    title001: 'Agree',
    title002: 'Reject',
  },
  categoryStatus: {
    describe: 'Category status',
    title001: 'Enabled',
    title002: 'Disabled',
  },
  contentStatus: {
    describe: 'Content Status',
    title001: 'On Shelf Status',
    title002: 'Draft',
    title003: 'Off Shelf Status',
  },
  processStatus: {
    describe: 'Processing status',
    title001: 'Pending',
    title002: 'Processed',
  },
  status: {
    enabled: 'Enabled',
    disabled: 'Disabled',
  },
  afterSalesType: {
    describe: 'After Sales Status',
    title001: 'All',
    title002: 'Yes',
    title003: 'No',
  },
  deliveryMethodType: {
    describe: 'Delivery method',
    title001: 'All',
    title002: 'All US Direct Shipping',
    title003: 'Same Day Delivery',
    title004: 'Self-pickup',
  },
  orderStatus: {
    describe: 'Order Status',
    title001: 'All',
    title002: 'To be pushed into picking pool',
    title003: 'Pending picking',
    title004: 'Picking in progress',
    title005: 'To be delivered',
    title006: 'To be picked',
    title007: 'To be posted',
    title008: 'Delivered',
    title009: 'Cancelled',
  },
  orderChannelsType: {
    describe: 'Order channels',
    title001: 'All',
    title002: 'WEB',
    title003: 'APP',
  },
  debitStatus: {
    describe: 'Debit Status',
    title001: 'All',
    title002: 'Failed',
    title003: 'Success',
  },
  shippingTypes: {
    allUsDirectShipping: 'All US Direct Shipping',
    sameDayDelivery: 'Same Day Delivery',
    nextDayDelivery: 'Next Day Delivery',
  },
  releaseTypes: {
    describe: 'Release Types',
    title001: 'Immediate',
    title002: 'Scheduled',
  },
  storeTypes: {
    physicalStore: 'Physical Store',
    virtualStore: 'Virtual Store',
  },
  storeNature: {
    sameDay: 'Same day delivery',
    nextDay: 'Next day delivery',
    allArrive: 'Us direct mail',
    selfRunning: 'Self support',
  },
  deliveryType: {
    self: 'Self Support',
  },
  productPolicyTypes: {
    precautions: 'Precautions',
    disclaimer: 'Disclaimer',
  },
  storeTag: {
    offline: 'Offline',
    online: 'Online',
  },
};
