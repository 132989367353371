import * as apiStoreInterface from './apiStore';
import * as boAppLaunchConfigInterface from './boAppLaunchConfig';
import * as boBannerInterface from './boBanner';
import * as boFileInterface from './boFile';
import * as boGoogleMapInterface from './boGoogleMap';
import * as boGroupInterface from './boGroup';
import * as boStoreInterface from './boStore';
import * as boStoreActivityInterface from './boStoreActivity';
import * as boStoreDeliveryInterface from './boStoreDelivery';
import * as boStoreIntroductionInterface from './boStoreIntroduction';

export const store = {
  apiStore: apiStoreInterface,
  boAppLaunchConfig: boAppLaunchConfigInterface,
  boBanner: boBannerInterface,
  boFile: boFileInterface,
  boGoogleMap: boGoogleMapInterface,
  boGroup: boGroupInterface,
  boStore: boStoreInterface,
  boStoreActivity: boStoreActivityInterface,
  boStoreDelivery: boStoreDeliveryInterface,
  boStoreIntroduction: boStoreIntroductionInterface,
};
