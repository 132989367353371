/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type saveOrUpdateParam = {
  /** 活动结束时间 */
  activityEndTime?: number;

  /** 活动名称 */
  activityName?: string;

  /** 活动开始时间 */
  activityStartTime?: number;

  /** 活动 ID */
  id?: number;

  /** 图片链接 */
  image?: string;

  /** 定时发布时间 */
  releaseTime?: number;

  /** 发布类型：1 定时发布 2 即时发布 */
  releaseType?: number;

  /** 门店分组 ID */
  smsGroupId?: number;

  /** 渠道：1 WEB 2 APP 3 WEB & APP */
  source?: number;

  /** 门店 ID */
  storeIds?: string;

  /** 门店类型：1 按门店设置 2 按分组设置 */
  type?: number;
};
export type saveOrUpdateFormData = FormData;
export type saveOrUpdateOptions = Record<string, any>;

export type saveOrUpdateResponse = Promise<defs.store.Response<void>>;

/**
 * @desc 保存或更新活动
 */
export function request(
  params: saveOrUpdateParam,
  formData: saveOrUpdateFormData,
  options?: saveOrUpdateOptions,
): saveOrUpdateResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/store/activity/saveOrUpdate';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params,
    data: formData,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
