export default {
  title0001: '批量手动发券',
  title0002: '手动发券日志',
  title0003: '批次信息',
  title0004: '发券批次名称',
  title0005: '发放原因',
  title0006: '选择券&发券数量',
  title0007: '券活动',
  title0008: '单个用户发放数量',
  title0009: '选择推送券的用户',
  title0010: '通过选择条件筛选用户',
  title0011: '通过Excel批量导入',
  title0012: '手机号码',
  title0013: '会员昵称',
  title0014: '注册来源',
  title0015: '会员等级',
  title0016: '查看已选用户',
  title0017: '用户ID',
  title0018: '会员昵称',
  title0019: '手机号',
  title0020: '注册来源',
  title0021: '会员等级',
  title0022: '用户状态',
  title0023: '选择文件',
  title0024: '模板下载',
  title0025: '【提示】支持手机号或用户ID上传匹配用户，只需填写任意一个字段即可',
  title0026: '批次号',
  title0027: '操作人',
  title0028: '操作时间',
  title0029: '涉及用户数',
  title0030: '发放原因',
  title0031: '操作',
  title0032: '查看批次详情',
  title0033: '批次详情',
  title0034: '券活动编码',
  title0035: '券活动名称',
  title0036: '支持不超过{{num}}位的正数',
  title0037: '查看',
  title0038: '券参数',
  title0039: '券活动信息',
  title0040: '券类型',
  title0041: '准用金额',
  title0042: '折扣值',
  title0043: '提示',
  title0044: '发券成功！共计发出{{num}}张券，涉及{{user}}位用户。',
  title0045: '操作失败！请导出Excel表查看详情。',
  title0046: '导出含有错误信息的Excel表格',
  title0047: '切换用户选择方式后，前一种方式选择/导入的用户将被清空。',
  title0048: '用户数据匹配中，可能需要一些时间，请勿离开......',
  title0049: '支持不超过{{num}}位字符',
  title0050: '批次名称',
  title0051: '发券数量',
  title0052: '已选用户',
  title0053: '确认发券',
  title0054: '请选择用户数据',
  title0055: '【提示】支持手机号或用户ID上传匹配用户，只需填写任意一个字段即可',
  title0056: '模板下载',
  title0057: '选择文件',
  title0058: '请选择excel文件',
  title0059: '发券时间',
};
