export default {
  title0001: 'New Coverage',
  title0002:
    'The front desk will not be visible after removal. Are you sure to remove?',
  title0003: 'On a successful',
  title0004: 'Operation',
  title0005: 'Countries',
  title0006: 'Province/state/City',
  title0007: 'Zip code',
  title0008: 'Creator',
  title0009: 'Creation time',
  title0010: 'Renewed ',
  title0011: 'Update time',
  title0012: 'Status',
  title0013: 'Confirm to submit',
  title0014: 'Area search',
  title0015: 'Zip code search',
  title0016: 'There is no point to',
};
