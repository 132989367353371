export default {
  title0001: '新建',
  title0002: '编辑',
  title0003: '违禁词',
  title0004: '操作',
  title0005: '简体中文',
  title0006: '繁体中文',
  title0007: '英文',
  title0008: '更新人',
  title0009: '更新时间',
  title0010: '删除',
  title0011: '简体中文词',
  title0012: '繁体中文词',
  title0013: '英文词',
  title0014: '请输入，限10个字',
  title0015: '请输入，限50个字符',
  title0016: '导入违禁词数据',
  title0017: '选择数据',
  title0018: '选取文件',
  title0019: '上传xls/xlsx文件',
  title0020: '将删除，请确认',
  title0021: '点此下载导入模板',
};
