export default {
  title0001: '会员卡号',
  title0002: '发生时间',
  title0003: '事项类型',
  title0004: '手机号',
  title0005: '线下门店编号',
  title0006: '导出',
  title0007: '发生时间',
  title0008: '门店编号',
  title0009: 'Lane N.O.',
  title0010: 'Transaction N.O.',
  title0011: '当前积分余额',
  title0012: '积分增加',
  title0013: '积分减少',
  title0014: '订单累计',
  title0015: '订单抵扣',
};
