export default {
  backToHome: 'Back to Home',
  pageTitle: "Tawa's Back Office",
  currencySymbols: '$',
  homePage: 'Home',
  status: 'Status',
  id: 'ID',
  operation: 'Operation',
  edit: 'Edit',
  delete: 'Delete',
  confirm: 'OK',
  cancel: 'Cancel',
  copy: 'Copy',
  day: 'Days',
  inputPlaceholder: 'Please Input',
  selectPlaceholder: 'Please Select',
  yes: 'Yes',
  no: 'No',
  operationSuccess: 'Operation Success',
  operationFailure: 'Operation Failed',
  enabled: 'Enabled',
  disabled: 'Disabled',
  toBeSent: 'To Be Sent',
  hasBeenSent: 'Has Been Sent',
  min: 'Min',
  max: 'Max',
  view: 'View',
  config: 'Config',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  hour: 'hour',
  minute: 'minute',
  begin: 'Start',
  end: 'End',
  time: 'Time',
  weekDay: 'Week Days',
  uploadFailed: 'Upload Failed',
  state: 'State',
  selectAll: 'Select All / Reverse Select',
  search: 'Search',
  batchDelete: 'Batch Delete',
  submit: 'Submit',
  week: 'week(s)',
  month: 'month(s)',
  quater: 'quarter(s)',
  every: 'every',
  operate: 'Operation in progress, please wait...',
};
