export default {
  balance: '可用积分余额',
  code: '账户编码',
  createdTime: '创建时间',
  holder: '持有人',
  id: '平台账户ID',
  name: '账户名称',
  purpose: '用途',
  status: '状态',
  statusName: '状态名称',
  totalAmount: '积分总额',
  type: '类型',
  typeName: '类型名称',
  change: '金额变动',
  changeType: '变更类型',
  changeValue: '变更金额',
  notice: '提示账户',
  noticeText:
    '提示：此处设置的账户，会在积分账户余额已低于积分总额的20%、10%、5%收到消息提示！',
  noticeSub: '新增账户',
  noticeName: '账户名称',
  noticeCode: '账户编码',
  noticeSelect: '已选账户',
  flowCreatedTime: '时间',
  flowOperator: '操作人',
  flowAmount: '变更额度',
  flowBalance: '变更后账户余额',
  accountFlow: '账户流水',
};
