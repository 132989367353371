/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type fixOrderSummaryParam = {
  /** date */
  date: string;
};
export type fixOrderSummaryOptions = Record<string, any>;

export type fixOrderSummaryResponse = Promise<defs.order.Response<void>>;

/**
 * @desc 订单日汇总
 */
export function request(
  params: fixOrderSummaryParam,
  options?: fixOrderSummaryOptions,
): fixOrderSummaryResponse {
  const host = getEnvHost();
  const url = host + '/order/bo/support/order/summary/fix';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
