export function enumToArray<T extends Record<string, any>>(
  params: T,
): {
  label: keyof T;
  value: T[keyof T];
}[] {
  return Object.keys(params).map((key) => ({
    label: key,
    value: params[key],
  }));
}
export function getLabel<T extends Array<Record<'label' | 'value', any>>>(
  params: T,
  value: any,
) {
  const item = params.find((i) => i.value === value);
  return item ? item.label : '';
}

/** 处理状态 */
export enum PROCESS_STATUS {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  FAIL = 'FAIL',
}

/** 覆盖业务 */
export const BUSINESS_TYPE = {
  '9988': 2,
  '8899': 3,
  O2O: 4,
};
export const BUSINESS_TYPE_ARRAY = enumToArray(BUSINESS_TYPE);

/** 配送方式 */
export const getDeliveryTypes = (t: (s: string) => string) => [
  { label: t('pages.enums.deliveryMethodType.title002'), value: 3 },
  { label: t('pages.enums.deliveryMethodType.title003'), value: 2 },
  { label: t('pages.enums.deliveryMethodType.title004'), value: 1 },
];

/** 折扣方式 */
export const DISCOUNT_TYPE = {
  /** 折扣比例 */
  BY_RATE: 2,
  /** 固定价格 */
  BY_PRICE: 3,
};
export const getDiscountTypeArray = (t: (s: string) => string) => [
  {
    label: t('pages.globalDiscount.by_rate'),
    value: DISCOUNT_TYPE.BY_RATE,
  },
  {
    label: t('pages.globalDiscount.by_price'),
    value: DISCOUNT_TYPE.BY_PRICE,
  },
];

/** 活动层级 */
export const ACTIVITY_LEVEL = {
  GOODS: 1,
  ORDER: 2,
};
export const getActivityLevelArray = (t: (s: string) => string) => [
  {
    label: t('pages.globalDiscount.goods_level'),
    value: ACTIVITY_LEVEL.GOODS,
  },
  {
    label: t('pages.globalDiscount.order_level'),
    value: ACTIVITY_LEVEL.ORDER,
  },
];

/** 上架状态 */
export enum ACTIVITY_STATUS {
  OFF = 2 /** 下架 */,
  ON = 1 /** 上架 */,
}
