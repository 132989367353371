export default {
  title0001: '会员相关配置',
  title0002: '客服相关配置',
  title0003: '购物车相关配置',
  title0004: '积分有效期',
  title0005: '积分兑换比例',
  title0006: '会员升级累计支付金额计算周期',
  title0007: '积分使用起始值',
  title0008: '确认提交',
  title0009: '天',
  title0010: '积分 = 1美元',
  title0011: '分',
  title0012: '服务时间',
  title0013: '开始',
  title0014: '结束',
  title0015: '添加',
  title0016: '周一',
  title0017: '周二',
  title0018: '周三',
  title0019: '周四',
  title0020: '周五',
  title0021: '周六',
  title0022: '周日',
  title0023: '客服电话',
  title0024: 'Email地址',
  title0025: '星期',
  title0026: '时间区间',
  title0027: '操作',
  title0028: '删除',
  title0029: '',
  title0030: '库存提醒阈值',
  title0031: '购物车一次提交商品数量限制',
  title0032: 'O2O订单免运设置',
  title0033: '门店分组',
  title0034: '免运阈值',
  title0035: '运费',
  title0036: '8899订单免运设置',
  title0037: '2天时效运费',
  title0038: '所属州',
  title0039: '9988订单免运阈值',
};
