export default {
  afterSalesOrderNO: 'AfterSales Order NO',
  associateOrderNO: 'Associate Order NO',
  afterSalesStatus: 'AfterSales Status',
  userID: 'User ID',
  userPhoneNO: 'User Phone NO',
  refundAmount: 'Refund Amount',
  itemsAmount: 'Items Amount',
  bottleReturnFee: 'Bottle Return Fee',
  tax: 'Tax',
  serviceCharge: 'Service Charge',
  deliveryFee: 'Delivery Fee',
  driverTip: 'Driver Tip',
  applyRefundAmount: 'Apply Refund Amount',
  actualRefundAmount: 'Actual Refund Amount',
  initiateAfterSalesTime: 'Initiate AfterSales Time',
  subSalesOrderNO: 'SubSales Order NO',
  memberCardNO: 'Member Card NO',
  reason: 'Reason',
  all: 'All',
  refundTypeReview: 'Refund Type Review',
  refundReviewOnly: 'Refund Review Only',
  pendingReturnConfirmation: 'Pending Return Confirmation',
  afterSalesDetails: 'AfterSales Details',
  refundChannel: 'Refund Channel',
  returnItems: 'Return Items',
  refundType: 'Refund Type',
  productName: 'Product Front-end Display Name',
  mainUPCCode: 'UPC Code',
  productId: 'Product ID',
  frontCategory: 'Front Category',
  productType: 'Product Type',
  quantity: 'Quantity',
  sellingPrice: 'Selling Price',
  amountReceived: 'Amount Received',
  credentialPicture: 'Credential Picture',
  refundReason: 'Refund Reason',
  refundInstructions: 'Refund Instructions',
  refundInfo: 'Refund Info',
  refundNO: 'Refund NO',
  refundCardNO: 'Refund Card NO',
  refundTime: 'Refund Time',
  refundStatus: 'Refund Status',
  refundableAmount: 'Refundable Amount',
  operationLogs: 'Operation Logs',
  totalRefundAmount: 'Total Refund Amount',
  agreeRefund: 'Agree Refund',
  refuseRefund: 'Refuse Refund',
  itemsToBeReturned: 'Items To Be Returned',
  noNeedToRefundItems: 'No Need To Refund Items',
  refundReview: 'Refund Review',
  refundUsedPoints: 'Refund Used Points',
  returnIssuedPoints: 'Return Issued Points',
  pointsTip: "The user's current point balance is:",
  pointsRefund: 'Points Refund',
  view: 'View',
  operationReason: 'Operation Reason',
  refuseRefundItems: 'Refunds are not accepted for the following items!',
  refundableItems: 'The following items are refundable!',
  returnConfirmPictures: 'Picture of returned goods',
  returnConfirmComment: 'Comment of returned goods',
  refundWay: 'Method of return',
  backendCategoryName: 'Back-end Category Name',
  needReturnItem: 'Goods Need to Be Returned',
  platformDiscountAmount: 'Platform Discount Amount',
  storeDiscountAmount: 'Store Discount Amount',
  pointsDeduction: 'Points Deduction',
};
