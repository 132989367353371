export default {
  title0001: '商品选择(手选和导入二选一)',
  title0002: '商品名称',
  title0003: '商 品 分 类',
  title0004: '请输入商品名称、商品ID或商品UPC编码',
  title0005: '商品名称',
  title0006: '商品ID',
  title0007: 'UPC',
  title0008: '商品类目',
  title0009: '图片',
  title0010: '已选商品',
  title0011: '操作',
  title0012: '移除',
  title0013: '商品批量导入',
  title0014: '选择文件',
  title0015: '上传商品列表',
  title0016: '模板下载',
  title0017: '导入成功！共计{{num}}个商品。',
  title0018: '共计{{num}}个商品导入成功，失败的商品请导出Excel表 查看详情！',
  title0019: '导出含有错误信息的Excel表格',
  title0020: '请选择Excel文件',
};
