export default {
  takeOffTheShelves: 'Take off the shelves',
  remove: 'Remove',
  edit: 'Edit',
  productName: 'Product Name',
  shelfStatus: 'Shelf Status',
  productId: 'Product ID',
  add: 'Add',
  maxQuota: 'Max Quota',
  activeInventory: 'Active Inventory',
  activeInventoryDesc: 'Please enter a positive integer from 1-100',
  costAllocation: 'Cost Allocation',
  platformCommitment: 'Platform responsibility',
  storeResponsibility: 'Store responsibility',
  costAllocationDesc: 'Please enter a positive integer from 0-100',
  discountStrength: 'Discount Strength',
  activityId: 'Activity ID',
  thumbnail: 'Thumbnail',
  category: 'Category',
  UPC: 'UPC',
  discount: 'Discount',
  validityPeriod: 'Validity Period',
  priceDetail: 'Price Detail',
  updateUser: 'Update User',
  updateTime: 'Update Time',
  commodityPricing: 'Product pricing',
  productSellingPrice: 'Product Price',
  currentPrice: 'Current selling price',
  discountedPrice: 'Price after discount',
  availableStock: 'Available inventory',
  activeStockType: 'Active inventory type',
  purchaseLimitPerPerson: 'Limit purchase quantity per person',
  confirmOperation: 'Confirm Operation',
  viewDetail: 'View Detail',
  operateDesc1:
    'After delisting, the front desk will not be visible. Do you want to confirm the delisting?',
  operateDesc2:
    'After removal, the product is no longer included in the list. Do you want to confirm the removal?',
  perDiscount: 'According to discount ratio',
  fixedPrice: 'Fixed price',
  fixedQuantity: 'Fixed quantity',
  setProportionally: 'Set proportionally',
  airticleNo: 'Product ID',
  storeActivityPrice: 'Store Activity Price',
  uniformDiscount: 'Flat discount',
  fixedDiscountAmount: 'Fixed Discount Amount',
  limitedScope: 'Limited Scope',
  byBusinessScope: 'By Business Scope',
  byStores: 'By Stores',
  batchProcessingSuccessful: 'Batch processing successful',
  submitLoading:
    'Submission successful, event is being created, please do not close the current page!',
  submitSuccess: 'Event created successfully!',
  submitFail: 'Event creation failed!',
  batchesRemove: '批量移除已下架活动',
};
