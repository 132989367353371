/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type deliveredBody = defs.order.OrderIdsDTO;
export type deliveredOptions = Record<string, any>;

export type deliveredResponse = Promise<defs.order.Response<void>>;

/**
 * @desc 商品送达（完成订单）
 */
export function request(
  body: deliveredBody,
  options?: deliveredOptions,
): deliveredResponse {
  const host = getEnvHost();
  const url = host + '/order/bo/support/order/delivered';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
