// 积分规则配置
export default {
  id: '规则 ID',
  ruleName: '规则名称',
  ruleDescription: '规则说明',
  ruleLogic: '规则逻辑',
  updater: '更新人',
  latestUpdate: '最后更新',
  operations: '操作',
  edit: '编辑',
  enName: '英文',
  cnName: '简体中文',
  twName: '繁体中文',
  coupon: '优惠券',
  // Quantity issued per month
  quantityIssuedPerMonth: '每月发放数量',
  freeShippingThreshold: '免运门槛',
  switch: '开关',
  ruleConfig: '规则配置',
  namePlaceholder: '请输入规则名称',
  descriptionPlaceholder: '请输入规则说明',
  couponPlaceholder: '请选择优惠券',
  quantityPlaceholder: '请输入数量',
  amountPlaceholder: '请输入金额',
  categoryPlaceholder: '请选择类目',
  xPlaceholder: '请输入X',
  exclusionCategory: '排除类目',
  monthlyUpperLimit: '月上限值',
  onlyObtainOnce: '仅限获取1次',
  purchases: '购买次数',
  purchaseThreshold: '购买门槛',
};
