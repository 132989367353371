/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type sendRefundMqParam = {
  /** refundId */
  refundId: string;
};
export type sendRefundMqOptions = Record<string, any>;

export type sendRefundMqResponse = Promise<defs.order.Response<string>>;

/**
 * @desc 退款-发送 MQ
 */
export function request(
  params: sendRefundMqParam,
  options?: sendRefundMqOptions,
): sendRefundMqResponse {
  const host = getEnvHost();
  const url = host + '/order/bo/support/refund/mq';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
