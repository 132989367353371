export default {
  setting: '设置',
  baseInfo: '基础信息',
  productSpecifications: '商品规格',
  productMainImage: '商品主图',
  productDetail: '商品明细',
  seoInfo: 'SEO信息',
  baseInfoModalTitle: '基本信息设置',
  manufacturer: '制造商',
  seoModalTitle: 'SEO信息设置',
  keywordsEN: '英文关键字',
  titleEN: '英文标题',
  descriptionEN: '英文描述',
  keywordsSCH: '简体中文关键字',
  titleSCH: '简体中文标题',
  descriptionSCH: '简体中文描述',
  keywordsTCH: '繁体中文关键字',
  titleTCH: '繁体中文标题',
  descriptionTCH: '繁体中文描述',
  mainImageModalTitle: '主图设置',
  mainImage1: '主图1',
  mainImage2: '主图2',
  mainImage3: '主图3',
  mainImage4: '主图4',
  mainImage5: '主图5',
  mainImage6: '主图6',
  mainImage7: '主图7',
  mainImage8: '主图8',
  mainImage9: '主图9',
  variantNameEN: '规格英文名称',
  variantNameSCH: '规格简体名称',
  variantNameTCH: '规格繁体名称',
  image: '规格图片',
  status: '状态',
  mainUpc: 'UPC',
  updateVariant: '编辑规格',
  cooCode: '产地',
  storeVariantDetails: '关联门店',
  netWeight: '规格净重',
  netWeightUom: '净重单位',
  saleUom: '销售单位',
  price: '价格',
  alcoholic: '是否含有酒精',
  validityPeriod: '有无有效期',
  brand: '品牌',
  length: '长',
  width: '宽',
  high: '高',
  weight: '重量',
  weightUom: '重量单位',
  packLength: '打包长',
  packWidth: '打包宽',
  packHigh: '打包高',
  packWeight: '打包重量',
  packWeightUom: '打包重量单位',
  packQuantity: '装箱数量',
  packFlag: '装箱标志',
};
