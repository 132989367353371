export default {
  categoryDetails: 'Category Details',
  basicInfo: 'Basic Info',
  newCategory: 'New Category',
  updateCategory: 'Update Category',
  categoryId: 'Category ID',
  categoryStatus: 'Category Status',
  categoryLevel: 'Category Level',
  parentCategory: 'Parent Category',
  categoryName: 'Category Name',
  finalCategory: 'Final Category',
  categoryIcon: 'Category ICON',
  MCHNumber: 'MCH Number',
  rateInfo: 'Rate Info',
  plusRate: 'Plus Rate',
  categoryAttributeInfo: 'Category Attribute Info',
  associateAttribute: 'Associate Attribute',
  attributeName: 'Attribute Name',
  attributeValue: 'Number Of Attribute Values',
  attributeCategory: 'Attribute Category',
  frontDisplay: 'Front Display',
  searchDisplay: 'Search Display',
  multipleSelection: 'Multiple Selection',
  required: 'Required',
  operations: 'Operations',
  remove: 'Remove',
  inheritAttributeInfo: 'Inherit Attribute Info',
  categoryBrandInfo: 'Category Brand Info',
  associateBrand: 'Associate brand',
  brandName: 'Brand Name',
  brandLogo: 'Brand Logo',
  inheritBrandInfo: 'Inherit Brand Info',
  enable: 'Enable',
  disable: 'Disable',
  delete: 'Delete',
  category: 'Category',
  deleteTip:
    'After deletion, the current category will not be visible, and the operation cannot be restored. Are you sure to delete?',
  bCategory: 'Background Categories',
  fCategory: 'Front Desk Categories',
};
