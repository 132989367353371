export default {
  sponsor: '发起人',
  contentTitle: '内容标题',
  messageContent: '消息内容',
  submitTime: '提交时间',
  sendCount: '发送对象数量',
  sendStatus: '发送状态',
  auditOpinion: '审核意见',
  reviewer: '审核人',
  reviewTime: '审核时间',
  approvalStatus: '审核状态',
  operate: '操作',
  add: '新建',
  sendTime: '发布时间',
};
