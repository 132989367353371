export default {
  title0001: '专题名称',
  title0002: '专题状态',
  title0003: '下架后前台将不可见，是否确认下架？',
  title0004: '上架成功',
  title0005: '操作',
  title0006: '新建专题',
  title0007: '简体中文名称',
  title0008: '繁体中文名称',
  title0009: '英文名称',
  title0010: '商品数',
  title0011: '覆盖业务',
  title0012: 'O2O区域',
  title0013: '更新人',
  title0014: '更新时间',
  title0015: '状态',
  title0016: '排序值',
  title0017: '编辑',
  title0018: '删除',
  title0019: '查看专题商品明细',
  title0020: '手动选择商品',
  title0021: '商品ID',
  title0022: '商品标题',
  title0023: '历史销量',
  title0024: '折扣',
  title0025: '缩略图',
  title0026: '所属分类',
  title0027: '点击量',
  title0028: '移除',
  title0029: '排序',
  title0030: '删除后不可恢复，是否确认删除？',
  title0031: '移除后前台将不可见，是否确认移除？',
  title0032: '限输入30个字符',
  title0033: '限输入12个字',
  title0034: '显示顺序',
  title0035: '请输入1-99正整数',
  title0036: '添加商品',
  title0037: '选择商品',
  title0038: '保存提交',
  title0039: '已选择商品',
  title0040: '搜索标题',
  title0041: '选择',
  title0042: '保存提交',
  title0043: 'UPC',
  title0044: '封面图',
  title0045: '输入商品标题、UPC、article number搜索',
  title0046: '固定专题不能选择商品',
};
