export default {
  userId: 'User Id',
  memberNickname: 'Member Nickname',
  phoneNO: 'Phone NO',
  couponCode: 'Coupon Code',
  issueCouponsWays: 'Issue Coupon Ways',
  couponActivityName: 'Coupon Activity Name',
  couponActivityCode: 'Coupon Activity Code',
  couponParams: 'Coupon Params',
  getCouponTime: 'Coupon Collection Time',
  couponActivityStatus: 'Coupon Activity Status',
  operations: 'Operations',
  destroy: 'Destroy',
  batchDestroy: 'Batch Destroy',
  destroyLogs: 'Destroy Logs',
  operatingReason: 'Operating Reasons',
  view: 'View',
  couponActivityInfo: 'Coupon Activity Info',
  couponType: 'Coupon Type',
  discountValue: 'Discount Value',
  maxDeductAmount: 'Max Deduct Amount',
  destroyTip:
    "After the coupon is destroyed, the user will not be able to see the destroyed coupon. If you need to destroy, please fill in the reason below and submit, if you don't need to destroy, cancel.",
};
