export default {
  userNickName: 'Nickname',
  auditStatus: 'Audit Status',
  submitTime: 'Submit Time',
  automaticApproval: 'Automatic Approval',
  comments: 'Comments',
  commentPictures: 'Comment Pictures',
  location: 'Location',
  reviewOpinion: 'Review Opinion',
  reviewer: 'Reviewer',
  reviewTime: 'Review time',
  operations: 'Operations',
  audit: 'Audit',
  auditResults: 'Audit Results',
  agree: 'Agree',
  reject: 'Reject',
  status: 'Audit Status',
  batchReview: 'Batch Review',
};
