export default {
  title0001: '分类名称',
  title0002: '上架状态',
  title0003: '下架后前台将不可见，是否确认下架？',
  title0004: '上架成功',
  title0005: '操作',
  title0006: '选择分类',
  title0007: '分类ID',
  title0008: '分类名称',
  title0009: '展示图',
  title0010: '点击量',
  title0011: '更新人',
  title0012: '更新时间',
  title0013: '状态',
  title0014: '排序',
  title0015: '移除',
  title0016: '手动选择分类',
  title0017: '搜索名称',
  title0018: '分类层级',
  title0019: 'ICON图',
  title0020: '选择',
  title0021: '移除后列表不再包含该分类，是否确认移除',
};
