export default {
  title0001: '问题标题',
  title0002: '所属分类',
  title0003: '创建时间',
  title0004: '问题状态',
  title0005: '更新时间',
  title0006: '新建问题',
  title0007: '批量下架',
  title0008: '批量上架',
  title0009: '所属分类',
  title0010: '查看次数',
  title0011: '更新人',
  title0012: '更新时间',
  title0013: '状态',
  title0014: '问题分类管理',
  title0015: '操作',
  title0016: '编辑',
  title0017: '编辑问题',
  title0018: '请输入，限20个字',
  title0019: '请输入，限输入100个字符',
  title0020: '简体内容',
  title0021: '繁体内容',
  title0022: '英文内容',
  title0023: '上架',
  title0024: '下架',
  title0025: '下架后前台将不可见，是否确认下架',
  title0026: '上架成功',
  title0027: '保存草稿',
  title0028: '问题分类',
  title0029: '请至少选择一条符合操作条件的记录',
  title0030: '简体问题标题',
  title0031: '繁体问题标题',
  title0032: '英文问题标题',
};
