/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type listParam = {
  /** id */
  batchId?: string;
  /** page */
  page?: number;
  /** size */
  size?: number;
};

export type listOptions = Record<string, any>;

export type pageProductResponse = Promise<
  defs.promotion.Response<defs.promotion.IPage<defs.promotion.Product>>
>;

/**
       * @desc 上传排除的商品信息
上传排除商品信息，用于全场折扣
       */
export function request(
  params: listParam,
  options?: listOptions,
): pageProductResponse {
  const host = getEnvHost();
  const url = host + '/promotion/bo/import/file/import/product/page';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
