export default {
  acceptOrder: 'Accept Order',
  top: 'Top',
  orderNo: 'Order No',
  paymentOrderNo: 'Payment Order NO',
  userID: 'User ID',
  userPhoneNO: 'User Phone',
  memberCardNo: 'Member Card NO',
  deliveryMethod: 'Delivery Method',
  orderStatus: 'Order Status',
  orderChannel: 'Order Channel',
  store: 'Store',
  orderTime: 'Order Time',
  operations: 'Operations',
  amountReceivable: 'Amount Receivable',
  afterSalesOrderNO: 'After Sales Order NO',
  afterSale: 'After Sales',
  title0001: 'Product Name',
  title0002: 'UPC',
  title0003: 'Product ID',
  title0004: 'Category',
  title0005: 'Product Type',
  title0006: 'Order Quantity',
  title0007: 'Picking Quantity',
  title0008: 'Order Item Amount',
  title0009: 'Picking Item Amount',
  title0010: 'Picking Result',
  title0011: 'Products',
  title0012: 'Overtime',
  title0013: 'estimated Delivered Time',
  title0014: 'Is the driver accpted the order',
  title0015: 'Driver Tip',
  title0016: 'Add Driver Tip',
  title0017: 'Delivery Total',
  title0018: 'Overtime Minutes',
  title0019: 'Add Driver Tip Orders',
  title0020: 'Yes',
  title0021: 'No',
  title0022: 'Address',
  title0023: 'View Operation Logs',
  title0024: 'Operation Logs',
  title0025: 'Operation Username',
  title0026: 'Operation Name',
  title0027: 'Add Amount',
  title0028: 'Operation Time',
};
