export default {
  title0001: '国家',
  title0002: '省/州/市',
  title0003: '邮编',
  title0004: '邮箱账号',
  title0005: '用户昵称',
  title0006: '提交时间',
  title0007: '状态',
  title0008: '来源',
  title0009: '地区搜索',
  title0010: '邮编搜索',
};
