export default {
  takeOffTheShelves: '下架',
  remove: '移除',
  edit: '编辑',
  productName: '商品名称',
  shelfStatus: '上架状态',
  productId: '商品ID',
  add: '新增',
  maxQuota: '最大额度',
  activeInventory: '活动库存',
  activeInventoryDesc: '请输入1-100的正整数',
  costAllocation: '成本分摊',
  platformCommitment: '平台承担',
  storeResponsibility: '门店承担',
  costAllocationDesc: '请输入0-100的正整数',
  discountStrength: '折扣力度',
  activityId: '活动ID',
  thumbnail: '缩略图',
  category: '所属后台分类',
  UPC: 'UPC',
  discount: '折扣',
  validityPeriod: '有效期',
  priceDetail: '价格详情',
  updateUser: '更新人',
  updateTime: '更新时间',
  commodityPricing: '商品定价',
  productSellingPrice: '商品定价',
  currentPrice: '当前售价',
  discountedPrice: '折扣后价格',
  availableStock: '可售库存',
  activeStockType: '活动库存类型',
  purchaseLimitPerPerson: '每人限购数量',
  confirmOperation: '确认操作',
  viewDetail: '查看详情',
  operateDesc1: '下架后前台将不可见，是否确认下架？',
  operateDesc2: '移除后列表不再包含该商品，是否确认移除？',
  perDiscount: '按折扣比例',
  fixedPrice: '按固定价格',
  fixedQuantity: '固定数量',
  setProportionally: '按比例设置',
  airticleNo: '商品ID',
  storeActivityPrice: '门店活动价',
  uniformDiscount: '统一固定折扣金额',
  fixedDiscountAmount: '固定折扣金额',
  limitedScope: '限定范围',
  byBusinessScope: '按业务范围',
  byStores: '按指定门店',
  batchProcessingSuccessful: '批量处理成功',
  submitLoading: '提交成功，活动创建中，请勿关闭当前页面！',
  submitSuccess: '活动创建成功！',
  submitFail: '活动创建失败！',
  batchesRemove: '批量移除已下架活动',
};
