export default {
  warehouse_operation_documents: 'Warehouse Operation Documents',
  inbound_order_query: 'Inbound Order Query',
  outbound_order_query: 'Outbound Order Query',
  outbound_warehouse: 'Outbound Warehouse',
  outbound_order_number: 'Outbound Order Number',
  inbound_warehouse: 'Inbound Warehouse',
  inbound_order_number: 'Inbound Order Number',
  operator: 'Operator',
  location_code: 'Location Code',
  product_name: 'Product Name',
  upc_code: 'UPC Code',
  creation_time: 'Creation Time',
  status: 'Status',
  product_details: 'Product Details',
  total_quantity: 'Total Quantity',
  view_details: 'View Details',
  product_image: 'Product Image',
  outbound_quantity: 'Outbound Quantity',
  outbound_location_code: 'Outbound Location Code',
  inbound_quantity: 'Inbound Quantity',
  inbound_location_code: 'Inbound Location Code',
  belonging_warehouse: 'Belonging Warehouse',
  create_outbound_order: 'Create Outbound Order',
  create_inbound_order: 'Create Inbound Order',
  select_product: 'Select Product',
  add: 'Add',
  operation: 'Operation',
  remove: 'Remove',
  added_list: 'Added List',
  input_tip:
    'Please enter the product title, UPC code, Article Number to search and select',
  completed: 'Completed',
  not_completed: 'Not Completed',
};
