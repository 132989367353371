export default {
  title: 'Product Title',
  articleNumber: 'Article Number',
  upc: 'UPC',
  batchImportTags: 'Batch Import Tags',
  tagsTemplate: 'Tags Template',
  id: 'Product ID',
  titleEN: 'Product English Title',
  sales: 'Sales',
  brandTags: 'Brand Tags',
  attributeTags: 'Attribute Tags',
  categoryTags: 'Category Tags',
  action: 'Actions',
  editTags: 'Edit Tags',
  clearTags: 'Clear Tags',
  editProductTags: 'Edit Product Tags',
  clear: 'Clear',
  clearTip: 'Clear all tags for this product?',
  en: 'English',
  ch: 'Simplified Chinese',
  tw: 'traditional Chinese',
  placeholder: 'Please input tag words',
  importSuccessful: 'Import successful! Total {{num}} tags.',
  importFail:
    'Total {{num}} tags are imported successfully, please export the failed tags to Excel sheet for details! ',
  downloadErrorFile: 'Exporting Excel sheet with error messages',
  notice: 'Notice',
};
