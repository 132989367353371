export default {
  afterSalesOrderNumber: 'After-sales order number',
  orderNumber: 'Order number',
  applicationTime: 'application time',
  refundTime: 'Refund time',
  actualRefundAmount: 'Actual refund amount',
  refundableAmount: 'Amount due to be refunded',
  aftermarketType: 'After-sales type',
  afterSalesStatus: 'After-sales status',
  shopName: 'Shop name',
  businessType: 'Business type',
  storeName: 'Store Name',
  storeCode: 'Store code',
  storeGroup: 'Store group name',
  userID: 'User ID',
  userPhone: 'User phone number',
  sku: 'Number of SKUs',
  shipFromCity: 'ship from(city)',
  shipFromState: 'ship from(state)',
  shipToCity: 'ship to(city)',
  shipToState: 'ship to(state)',
  export: 'Export',
  orderDeductionTime: 'Order deduction time',
};
