export default {
  acceptOrder: '接单',
  top: '置顶',
  orderNo: '订单编号',
  paymentOrderNo: '付款单号',
  userID: '用户 ID',
  userPhoneNO: '用户电话',
  memberCardNo: '会员卡号',
  deliveryMethod: '配送方式',
  orderStatus: '订单状态',
  orderChannel: '下单渠道',
  store: '门店',
  orderTime: '下单时间',
  operations: '操作',
  amountReceivable: '应收金额',
  afterSalesOrderNO: '售后单号',
  afterSale: '售后',
  estDeliveredTime: '自提/送达时间',
  batchToast: '笔订单已接单成功',
};
