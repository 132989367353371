import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NProgress from 'nprogress';

import 'nprogress/nprogress.css';

import { useRecoilValue } from 'recoil';

import { PageLoading } from '@/components';

import styles from './BasicLayout.less';
import { loginStateAtom } from '@/atoms/login';
import NotFoundError from '@/pages/NotFound';

NProgress.configure({ showSpinner: false });

const ContentLayout: React.FC = () => {
  const { routesFlat } = useRecoilValue(loginStateAtom);
  const location = useLocation();
  const accessed = routesFlat.some(({ fullPath = '' }) =>
    location.pathname.includes(fullPath),
  );

  return (
    <Suspense fallback={<PageLoading />}>
      <div
        className={styles.proLayoutDiv}
        style={{ minHeight: 'calc(100vh - 80px)' }}
      >
        {accessed ? <Outlet /> : <NotFoundError />}
      </div>
    </Suspense>
  );
};

export default ContentLayout;
