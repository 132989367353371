export default {
  store_name: 'Store Name',
  activity_title: 'Activity Title',
  belong_group: 'Belong Group',
  channel: 'Channel',
  activity_period: 'Activity Period',
  activity_id: 'Activity ID',
  store_id: 'Store ID',
  updater: 'Updater',
  update_time: 'Update Time',
  status: 'Status',
  operation: 'Operation',
  publish: 'Publish',
  unpublish: 'Unpublish',
  unpublished: 'Unpublished',
  published: 'Published',
  create: 'Create',
  edit: 'Edit',
  store_type: 'Store Type',
  set_by_store: 'Set by Store',
  set_by_store_group: 'Set by Store Group',
  related_stores: 'Related Stores',
  add: 'Add',
  activity_image: 'Activity Image',
  image_supports_jpg_png_format: 'Image supports JPG/PNG format',
  select_import_file: 'Select Import File',
  supports_uploading_xls_xlsx_files: 'Supports uploading XLS/XLSX files',
  click_here_to_download_import_template:
    'Click here to download import template',
  publish_type: 'Publish Type',
  scheduled_publish: 'Scheduled Publish',
  immediate_publish: 'Immediate Publish',
  select_file: 'Select File',
  store_group: 'Store Group',
  release_time: 'Release Time',
  confirm_unpublish_message:
    'After unpublishing, the corresponding activity will no longer be showed up. Are you sure you want to unpublish?',
};
