export default {
  roleName: '角色名称',
  roleStatus: '角色状态',
  newRole: '新建角色',
  editRole: '编辑角色',
  associatedUsers: '关联人数',
  validityPeriod: '有效期',
  updater: '更新人',
  updatedTime: '更新时间',
  organizationPermissions: '组织权限',
  operationPermissions: '操作权限',
  dataPermissions: '数据权限',
  configure: '配置',
  assign: '按照组织分配',
  relatedPersonnelDetails: '关联人员明细',
  longTerm: '长期',
  shortTerm: '短期',
  range: '区间',
  organization: '组织',
  extendPermissions: '继承权限',
  operationPermissionSettings: '操作权限设置',
  dataPermissionSettings: '数据权限配置',
  menu: '菜单',
  store: '门店',
  warehouse: '仓库',
  bizModule: '业务模块',
  lastLoginTime: '最后登录时间',
  remove: '移除',
  enable: '启用',
  disable: '禁用',
  disableRoleTip: '角色禁用后对应用户将不再拥有相应权限,是否确认？',
  batchExport: '批量导出',
  import: '导入',
  roleList: '角色列表',
  importData: '导入数据',
  platform: '平台',
  longTermEffective: '长期有效',
  save: '保存',
};
