export default {
  title0001: '结算任务号',
  title0002: '订单号',
  title0003: '售后单号',
  title0004: '店铺编码',
  title0005: '结算方式',
  title0006: '渠道类型',
  title0007: '付款方',
  title0008: '收款方',
  title0009: '相关主体',
  title0010: '采集时间',
  title0011: '创建时间',
  title0012: '更新时间',
  title0013: '结算单号',
  title0014: '核价状态',
  title0015: '支付状态',
  title0016: '结算状态',
  title0017: '批量结算',
  title0018: '店铺名称',
  title0019: '收费金额',
  title0020: '分账金额',
  title0021: '核算分账金额',
  title0022: '结算',
  title0023: '详情',
  title0024: '待核价',
  title0025: '成功',
  title0026: '失败',
  title0027: '手动',
  title0028: '自动',
  title0029: '待结算',
  title0030: '已结算',
  title0031: '支付成功',
  title0032: '支付失败',
  title0033: '待支付',
  title0034: '结算单预览',
  title0035: '结算单序号',
  title0036: '付款账户',
  title0037: '收款账户',
  title0038: '收费金额总计',
  title0039: '分账金额总计',
  title0040: '核算分账金额总计',
  title0041: '结算金额',
  title0042: '门店小计',
  title0043: '结算任务创建时间',
  title0044: '核价结果',
  title0045: '创建结算单',
  title0046: '创建结算单并支付',
  title0047: '结算时间',
  title0048: '结算方式',
  title0049: '核价详情',
  title0050: '分账金额计算',
  title0051: '核算分账金额计算',
  title0052: '结算详情',
  title0053: '结算人',
  title0054: '{{num}}笔结算单创建成功，详情请在结算单列表查看',
  title0055: '退款成功',
  title0056: '退款失败',
};
