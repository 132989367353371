export default {
  title0001: 'Maintenance of user protocols',
  title0002: 'Click here to maintain',
  title0003: 'The English version',
  title0004: 'Simplified Chinese version',
  title0005: 'Traditional Chinese version',
  title0006: 'Privacy policy content maintenance',
  title0007: 'Maintain the deregistration instructions',
  title0008: 'Edit',
  title0009: 'Content',
};
