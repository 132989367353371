export default {
  addDriverTip: '附加配送费',
  addressCity: 'ship to(city)',
  addressState: 'ship to(state)',
  captureAmount: '实际扣款金额',
  captureStatus: '扣款状态',
  captureTime: '扣款时间',
  channelType: '渠道类型',
  companyCode: '公司编码',
  createdTime: '订单创建时间',
  coupon: '优惠券金额',
  crvFee: '退瓶费',
  deliverType: '交付类型',
  deliveredTime: '订单完成时间',
  deliveryFee: '配送费',
  driverTip: '司机小费',
  id: '订单号',
  isCutOrder: '是否割接订单',
  itemAdjustment: '临时加收金额',
  itemAmount: '商品总价',
  merchantName: '商家名称',
  merchantType: '商家类型',
  orderTotalAmount: '订单总计',
  payAmount: '预授权金额',
  performanceTime: '履约时间',
  pickUpCode: '提货码',
  pickingAmount: '拣货调整金额',
  platformCoupon: '平台订单级优惠金额',
  pointsExchangeRate: '积分抵扣',
  serviceFee: '服务费',
  status: '订单状态',
  storeAddrCity: 'ship from(city)',
  storeAddrState: 'ship from(State)',
  storeCoupon: '门店订单级优惠金额',
  storeGroupName: '门店分组名称',
  storeId: '店铺编码',
  storeName: '店铺名称',
  storeTimeZone: '所在时区',
  taxFee: '消费税',
  tradeType: '贸易类型',
  userId: '用户ID',
  userPhone: '用户手机号',
  deliveryType: '配送类型',
};
