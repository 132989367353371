import React, { lazy } from 'react';
import {
  LoginOutlined,
  HomeOutlined,
  SafetyCertificateOutlined,
  ProjectOutlined,
  TrademarkOutlined,
  TeamOutlined,
  ProfileOutlined,
  ShoppingOutlined,
  MessageOutlined,
  ShopOutlined,
  DatabaseOutlined,
  SettingOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import type { MenuDataItem } from '@ant-design/pro-layout';
import {
  loginMenus,
  permissionMenus,
  homeMenus,
  memberMenus,
  contentMenus,
  orderMenus,
  salesMenus,
  itemsMenus,
  baseRoutes,
  storeMenus,
  warehouseMenus,
  warehouseMgtMenus,
  settingMenus,
  messageMenus,
  financialSystemMenus,
  indexMenus,
} from './routes';

const SecurityLayout = lazy(() => import('@/layouts/SecurityLayout'));

/** 👇🏻路由配置的接口类型 */
export interface DynamicRouteType extends MenuDataItem {
  path?: string;
  /** 翻译失败后 则采用name配置值,如无需全球化直接使用中文即可 */
  name: string;
  /** @/config/icons里配置的图标,小写也可以 */
  icon?: string | React.ReactElement;
  /** @/config/access里可配置静态策略。权限入口在@/config/pages里 */
  access?: string;
  /** 非动态的有page属性的路由，会默认显示在sideMmenu里 */
  component?: React.FC<any>;
  children?: DynamicRouteType[];
  parentCode?: string;
  fullPath?: string;
}

const homeRoutes: DynamicRouteType[] = [
  {
    path: '/',
    name: 'index',
    code: 'index',
    children: indexMenus,
    hideInMenu: true,
    component: SecurityLayout,
  },
];

// use a request result to instead it.
const menuTabs: DynamicRouteType[] = [
  {
    name: 'menus.top.loginConfig',
    path: '/loginConfig',
    icon: <LoginOutlined />,
    children: loginMenus,
    code: 'loginConfig',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.homeConfig',
    path: '/homeConfig',
    icon: <HomeOutlined />,
    children: homeMenus,
    code: 'homeConfig',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.memberInformation',
    path: '/memberInformation',
    icon: <TeamOutlined />,
    children: memberMenus,
    code: 'memberInformation',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.content',
    path: '/contentConfig',
    icon: <ProjectOutlined />,
    children: contentMenus,
    code: 'content',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.marketingManagement',
    path: '/sales',
    icon: <TrademarkOutlined />,
    children: salesMenus,
    code: 'marketingManagement',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.order',
    path: '/order',
    icon: <ProfileOutlined />,
    children: orderMenus,
    code: 'order',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.message',
    path: '/message',
    icon: <MessageOutlined />,
    children: messageMenus,
    code: 'message',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.items',
    path: '/items',
    icon: <ShoppingOutlined />,
    children: itemsMenus,
    code: 'items',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.permission',
    path: '/permissions',
    icon: <SafetyCertificateOutlined />,
    children: permissionMenus,
    code: 'permissions',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.store',
    path: '/allStoreConfig',
    icon: <ShopOutlined />,
    children: storeMenus,
    code: 'allStoreConfig',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.warehouseManagement',
    path: '/warehouseManagement',
    icon: <DatabaseOutlined />,
    children: warehouseMgtMenus,
    code: 'warehouseMgt',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.warehouseJob',
    path: '/warehouseJob',
    icon: <ShopOutlined />,
    children: warehouseMenus,
    code: 'warehouseJob',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.setting',
    path: '/systemSetting',
    icon: <SettingOutlined />,
    children: settingMenus,
    code: 'systemSetting',
    component: SecurityLayout,
  },
  {
    name: 'menus.top.financialSystem',
    path: '/financialSystem',
    icon: <BarChartOutlined />,
    children: financialSystemMenus,
    code: 'financialSystem',
    component: SecurityLayout,
  },
];

// authority menus white list
const whiteList = ['/', '/home'];
export { menuTabs, baseRoutes, homeRoutes, whiteList };
