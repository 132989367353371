/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type createConfigBody = defs.store.BoAPPLaunchConfigDetailVo;
export type createConfigOptions = Record<string, any>;

export type createConfigResponse = Promise<defs.store.Response<void>>;

/**
 * @desc 添加启动页
 */
export function request(
  body: createConfigBody,
  options?: createConfigOptions,
): createConfigResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/app/launch/config/create';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
