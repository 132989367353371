/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type listParam = {
  /** current */
  current?: number;

  /** name */
  name?: string;

  /** productId */
  productId?: number;

  /** size */
  size?: number;

  /** sortOrder */
  sortOrder?: string;

  /** upc */
  upc?: string;
};
export type listOptions = Record<string, any>;

export type listResponse = Promise<
  defs.search.Response<defs.search.IPage<defs.search.ProductSearchTag>>
>;

/**
 * @desc 商品搜索词列表 按照销量排序可选DESC ASC
 */
export function request(
  params: listParam,
  options?: listOptions,
): listResponse {
  const host = getEnvHost();
  const url = host + '/search/bo/search_tag/product/list';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
