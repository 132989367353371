export default {
  title0001: 'Product name',
  title0002: 'Shelf status',
  title0003:
    'The product will not be visible in the foreground after shelving, do you want to confirm the shelving?',
  title0004: 'Shelf success',
  title0005: 'Operation',
  title0006: 'Select product',
  title0007: 'Batch shelf',
  title0008: 'Bulk off shelves',
  title0009: 'Product ID',
  title0010: 'Product title',
  title0011: 'Discount',
  title0012: 'Cover image',
  title0013: 'Category',
  title0014: 'Hits',
  title0015: 'Updated by',
  title0016: 'Update time',
  title0017: 'Status',
  title0018: 'Sort by',
  title0019: 'Remove',
  title0020: 'Manually select products',
  title0021: 'Search Title',
  title0022: 'Display order',
  title0023: 'Please enter a positive integer from 1-99',
  title0024: 'Select',
  title0025:
    'The list no longer contains this product after removal, confirm removal',
  title0026:
    'Please select at least one record that meets the operating conditions',
  title0027: 'Sure to {{status}} this template?',
  title0028: 'Template name',
  title0029: 'Is it default',
  title0030: 'Contains stores',
  title0031: 'New template',
  title0032: 'Edit template',
  title0033: 'Edit product',
};
