export default {
  contentType: {
    describe: '内容类型',
    title001: '图片',
    title002: '视频',
  },
  shelvesStatus: {
    describe: '上架状态',
    title001: '已上架',
    title002: '已下架',
    title003: '下架',
    title004: '上架',
  },
  pointType: {
    describe: '指向类型',
    title001: '前台分类',
    title002: '商品',
    title003: '自定义页面',
    title004: '无指向',
  },
  coverageType: {
    describe: '覆盖业务',
    title001: '默认',
    title002: '8899',
    title003: '9988',
    title004: 'O2O',
  },
  o2oType: {
    describe: 'O2O区域',
    title001: 'NCA',
    title002: 'SCA',
    title003: 'EC',
    title004: 'TC',
  },
  countryType: {
    describe: '国家类型',
    title001: '美国',
  },
  american: {
    describe: '美国所有州',
    title001: '亚拉巴马州',
    title002: '阿拉斯加州',
    title003: '亚利桑那州',
    title004: '阿肯色州',
    title005: '加利福尼亚州',
    title006: '科罗拉多州',
    title007: '康涅狄格州',
    title008: '特拉华州',
    title009: '佛罗里达州',
    title010: '佐治亚州',
    title011: '夏威夷州',
    title012: '爱达华州',
    title013: '伊利诺州',
    title014: '印地安那州',
    title015: '艾奥瓦州',
    title016: '堪萨斯州',
    title017: '肯塔基州',
    title018: '路易斯安那州',
    title019: '缅因州',
    title020: '马里兰州',
    title021: '麻萨诸塞州',
    title022: '密歇根州',
    title023: '明尼苏达州',
    title024: '密西西比州',
    title025: '密苏里州',
    title026: '蒙大拿州',
    title027: '内布拉斯加州',
    title028: '内华达州',
    title029: '新罕布什尔州',
    title030: '新泽西州',
    title031: '新墨西哥州',
    title032: '纽约州',
    title033: '北卡罗来纳州',
    title034: '北达科他州',
    title035: '俄亥俄州',
    title036: '俄克拉何马州',
    title037: '俄勒冈州',
    title038: '宾夕法尼亚州',
    title039: '罗得岛州',
    title040: '南卡罗来纳州',
    title041: '南达科他州',
    title042: '田纳西州',
    title043: '得克萨斯州',
    title044: '犹他州',
    title045: '佛蒙特州',
    title046: '弗吉尼亚州',
    title047: '华盛顿州',
    title048: '西弗吉尼亚州',
    title049: '威斯康星州',
    title050: '怀俄明州',
    title051: '华盛顿哥伦比亚特区',
  },
  matchType: {
    describe: '匹配类型',
    title001: '精确匹配',
    title002: '模糊匹配',
  },
  checkType: {
    describe: '审核状态',
    title001: '待审核',
    title002: '已审核',
    title003: '已拒绝',
  },
  checkStatus: {
    describe: '审核结果',
    title001: '同意',
    title002: '拒绝',
  },
  categoryStatus: {
    describe: '分类状态',
    title001: '启用',
    title002: '未启用',
  },
  contentStatus: {
    describe: '分类状态',
    title001: '已上架',
    title002: '草稿',
    title003: '已下架',
  },
  processStatus: {
    describe: '处理状态',
    title001: '待处理',
    title002: '已处理',
  },
  status: {
    enabled: '启用',
    disabled: '禁用',
  },
  afterSalesType: {
    describe: '售后状态',
    title001: '全部',
    title002: '是',
    title003: '否',
  },
  deliveryMethodType: {
    describe: '配送方式',
    title001: '全部',
    title002: '全美直邮',
    title003: '当日达',
    title004: '自提',
  },
  orderStatus: {
    describe: '订单状态',
    title001: '全部',
    title002: '待推入拣货池',
    title003: '待拣货',
    title004: '拣货中',
    title005: '待配送',
    title006: '待自提',
    title007: '待邮寄',
    title008: '已送达',
    title009: '已取消',
  },
  orderChannelsType: {
    describe: '下单渠道',
    title001: '全部',
    title002: 'WEB',
    title003: 'APP',
  },
  debitStatus: {
    describe: '扣款状态',
    title001: '全部',
    title002: '失败',
    title003: '成功',
  },
  shippingTypes: {
    allUsDirectShipping: '全美直邮',
    sameDayDelivery: '当日达',
    nextDayDelivery: '次日达',
  },
  releaseTypes: {
    describe: '发布类型',
    title001: '即时发布',
    title002: '定时发布',
  },
  storeTypes: {
    physicalStore: '实体门店',
    virtualStore: '虚拟门店',
  },
  storeNature: {
    sameDay: '当日达',
    nextDay: '次日达',
    allArrive: '全美直邮',
    selfRunning: '自营',
  },
  deliveryType: {
    self: '自营配送',
  },
  productPolicyTypes: {
    precautions: '注意事项',
    disclaimer: '免责声明',
  },
  storeTag: {
    offline: '线下',
    online: '线上',
  },
};
