export default {
  date: 'Date', //
  oms: 'OMS type', //
  accountingAmount: 'Calculate sub-account amounts', //
  platformDiscountAmount: 'Platform order level discount amount', //
  shopDiscountAmount: 'Store order level discount amount', //
  orderTotal: 'Order total', //
  temporaryAdditionalAmount: 'Temporary additional amount', //
  preAuthorizedAmount: 'Pre-authorization amount', //
  pickAdjustmentAmount: 'Picking adjustment amount', //
  actualDeductionAmount: 'Actual deduction amount', //
  payee: 'Beneficiary', //
  totalPrice: 'Total product price', //
  timeZone: 'Timezone', //
  pointsDeduction: 'Points deduction', //
  saleTax: 'sale tax', //
  bottleReturnFee: 'Bottle return fee', //
  serviceCharge: 'service charge', //
  driverTip: 'Driver tip', //
  deliveryFee: 'delivery fee', //
  additionalDeliveryFee: 'Additional delivery fee', //
  platformDeductionPoints: 'Platform deduction points(%)', //
  platformDeductionAmount: 'Platform deduction points (fixed amount)', //
  storeName: 'Store Name', //
  storeCode: 'Store code', //
};
