/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type listParam = {
  /** 活动结束时间 */
  activityEndTime?: number;

  /** 活动名称 */
  activityName?: string;

  /** 活动开始时间 */
  activityStartTime?: number;

  /** 页码 */
  pageNum?: number;

  /** 每页大小 */
  pageSize?: number;

  /** 门店分组 ID */
  smsGroupId?: number;

  /** 门店 ID */
  storeId?: number;

  /** 门店名称 */
  storeName?: string;
};
export type listOptions = Record<string, any>;

export type listResponse = Promise<
  defs.store.Response<defs.store.IPage<defs.store.BoStoreActivityVo>>
>;

/**
 * @desc 查询活动列表
 */
export function request(
  params: listParam,
  options?: listOptions,
): listResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/store/activity/list';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
