// 会员等级配置
export default {
  id: 'ID',
  enName: 'name (EN)',
  cnName: 'name (CN)',
  twName: 'name (TW)',
  levelSymbol: 'Level Symbol',
  levelType: 'Level Type',
  memberBenefits: 'Member Benefits',
  pointBenefits: 'Points Benefits',
  upgradeRules: 'Upgrade Rules',
  updater: 'Updater',
  updateTime: 'Update Time',
  status: 'Status',
  operations: 'Operations',
  config: 'Config',
  edit: 'Edit',
  freeMembership: 'Free Membership',
  paidMembership: 'Paid Membership',
  ruleName: 'Rule Name',
  ruleLogic: 'Rule Logic',
  monthlyCoupon: 'Monthly Coupon',
  monthlyIssuedCoupon: 'Monthly Issued Coupon',
  birthdayCoupon: 'Birthday Coupon',
  coupon: 'Coupon',
  sameDayFreeShipping: 'Same Day Free Shipping',
  freeShippingThreshold: 'Free Shipping Threshold',
  expressFreeShipping: 'Express Free Shipping',
  storePickup: 'Store Pickup',
  switch: 'Switch',
  enjoyPromotions: 'Enjoy Promotions',
  enjoyVIPPreSaleRights: 'Enjoy VIP PreSale Rights',
  cumulativeOrderPaymentAmount: 'Cumulative Order Payment Amount',
  purchaseThreshold: 'Purchase Threshold',
  nextLevel: 'Next Level',
  orderRewards: 'Order Rewards',
  orderReview: 'Order Review',
  inviteFriends: 'Invite Friends',
  emailSubscription: 'Email Subscription',
  smsSubscription: 'SMS Subscription',
  perfectPersonalInformation: 'Perfect Personal Information',
  followSocialPlatformAccounts: 'Follow Social Platform Accounts',
  purchasingRewardFullTimesPerMonth: 'purchasing Reward Full Times Per Month',
  firstPurchaseReward: 'First Purchase Reward',
  rewardEvery$1Spent: 'Reward Every $1 Spent',
  rewardEveryPost: 'Reward Every Post',
  monthlyUpperLimit: 'Monthly Upper Limit',
  perInviteReward: 'Per Invite Reward',
  reward: 'Reward',
  perFellowReward: 'Per Fellow Reward',
  purchases: 'Purchases',
  newLevel: 'New Level',
  updateLevel: 'Edit Level',
  on: 'ON',
  off: 'OFF',
  couponPlaceholder: 'Please Select Coupon',
  amountPlaceholder: 'Please Input The Amount',
  quantityPlaceholder: 'Please Input The Quantity',
  nextLevelPlaceholder: 'Please Select The Next Level',
  enNameRule: 'Please enter a combination of English characters',
};
