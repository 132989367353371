export default {
  backToHome: '返回首页',
  pageTitle: "Tawa's Back Office",
  currencySymbols: '$',
  homePage: '首页',
  status: '状态',
  id: 'ID',
  operation: '操作',
  edit: '编辑',
  delete: '删除',
  confirm: '确认',
  cancel: '取消',
  copy: '复制',
  day: '天',
  inputPlaceholder: '请输入',
  selectPlaceholder: '请选择',
  yes: '是',
  no: '否',
  operationSuccess: '操作成功',
  operationFailure: '操作失败',
  enabled: '启用',
  disabled: '禁用',
  toBeSent: '待发送',
  hasBeenSent: '已发送',
  min: '最小值',
  max: '最大值',
  view: '查看',
  config: '设置',
  monday: '周一',
  tuesday: '周二',
  wednesday: '周三',
  thursday: '周四',
  friday: '周五',
  saturday: '周六',
  sunday: '周日',
  hour: '小时',
  minute: '分钟',
  begin: '开始',
  end: '结束',
  time: '时间',
  weekDay: '星期',
  uploadFailed: '上传失败',
  state: '州',
  selectAll: '全选/反选',
  search: '搜索',
  batchDelete: '批量删除',
  submit: '确认提交',
  week: '周',
  month: '月',
  quater: '季度',
  every: '每',
  operate: '操作进行中，请稍后...',
};
