export default {
  title0001: '新建',
  title0002: '编辑',
  title0003: '下架后前台将不可见，是否确认下架？',
  title0004: '上架成功',
  title0005: '操作',
  title0006: '名称',
  title0007: '简体中文图',
  title0008: '繁体中文图',
  title0009: '英文图',
  title0010: '点击量',
  title0011: '指向链接',
  title0012: '更新人',
  title0013: '更新时间',
  title0014: '状态',
  title0015: '排序',
  title0016: '简体中文图',
  title0017: '请输入，限12个字',
  title0018: '请输入，限100个字符',
  title0019: '选取文件',
  title0020: '上传jpg/png文件,且不超过6M',
  title0021: '请输入链接',
  title0022: '显示顺序',
  title0023: '请输入1-99正整数',
  title0024: '发布类型',
  title0025: '发布时间',
  title0026: '即时发布',
  title0027: '定时发布',
  title0028: '请上传',
  title0029: '需要保证至少5个功能区，不能继续下架',
};
