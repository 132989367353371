export default {
  afterSalesOneLineCoding: '售后单行编码',
  companyCode: '公司编码',
  payer: '付款方',
  payee: '收款方（用户ID）',
  splitAmount: '分账金额',
  channelType: '渠道类型',
  timeZone: '所在时区',
  refundSuccessfully: '退款成功',
  orderTime: '下单时间',
  completeTime: '完成时间',
  deductionTime: '扣款时间',
  performingParty: '履约方',
  productName: '商品名称',
  upc: 'UPC',
  articleNumber: 'Article Number',
  weighingGoods: '称重商品',
  sellingPrice: '售价',
  unit: '单位',
  specification: '规格',
  afterSalesQuantity: '售后数量',
  originalPrice: '商品原价',
  productPriceAfterDirectDrop: '直降后商品价格',
  platformAmount: '平台分摊促销金额',
  merchantsAmount: '商家分摊促销金额',
  allPostPromotionAmounts: '所有促销后金额',
  saleTax: '消费税',
  bottleReturnFee: '退瓶费',
  serviceCharge: '服务费',
  additionalDeliveryFee: '附加配送费',
  platformDistributionFee: '平台配送费',
  merchantDeliveryFee: '商家配送费',
  merchantShippingDiscount: '商家运费折扣',
  platformShippingDiscount: '平台运费折扣',
  platformDeductionPoints: '平台扣点%',
  platformDeductionAmount: '平台扣点（定额）',

  afterSalesOrderNumber: '售后单号',
  orderNumber: '订单号',
  applicationTime: '申请时间',
  refundTime: '退款时间',
  actualRefundAmount: '实退金额',
  refundableAmount: '应退金额',
  aftermarketType: '售后类型',
  afterSalesStatus: '售后状态',
  shopName: '商家名称',
  businessType: '商家类型',
  storeName: '店铺名称',
  storeCode: '店铺编码',
  userID: '用户ID',
  userPhone: '用户手机号',
  sku: 'SKU个数',
  shipFromCity: 'ship from(city)',
  shipFromState: 'ship from(state)',
  shipToCity: 'ship to(city)',
  shipToState: 'ship to(state)',
  export: '导出',
  orderDeductionTime: '订单扣款时间',
  refundStatusDesc: '售后状态',
  saleUom: '售卖单位',
  netWeight: '净重',
  netWeightUom: '净重单位',
  marketPrice: '商品售价',
  storeActivityPrice: '门店活动价',
  onlineActivityPrice: '线上活动价',
  currentSalePrice: '当前售价',
  itemAmount: '售后商品金额小计',
  taxFee: '消费税',
  crvFee: '退瓶费',
  serviceFee: '服务费',
  platformDeliveryFee: '平台配送费',
  platformDeliveryFeeDiscount: '平台配送费优惠',
  storeDeliveryFee: '商家配送费',
  storeDeliveryFeeDiscount: '商家配送费优惠',
  addDriverTip: '附加配送费',
  driverTip: '司机小费',
  platformPromotionDiscount: '平台优惠金额',
  storePromotionDiscount: '商家优惠金额',
  pointsDeduction: '积分抵扣',
  refundAmount: '实际退款金额',
  commissionAmount: '佣金(定额)',
  commissionPercent: '佣金%',
};
