/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type listO2OGroupNamesOptions = Record<string, any>;

export type listO2OGroupNamesResponse = Promise<
  defs.store.Response<Set<string>>
>;

/**
 * @desc O2O 门店分组名称列表
 */
export function request(
  options?: listO2OGroupNamesOptions,
): listO2OGroupNamesResponse {
  const host = getEnvHost();
  const url = host + '/store/bo/smsGroup/o2o';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  return sendRequest(url, fetchOption);
}
