// Data Entry FieldTypes

import { Checkbox, DatePicker, Input, InputNumber, Radio, Select } from 'antd';
import type { RangePickerProps } from 'antd/lib/date-picker';

import ClassifySelect from '@/components/ClassifySelect';
import GoodsSelect from '@/components/GoodsSelect';
import ImageUpload from '@/components/ImageUpload';
import MediaUpload from '@/components/MediaUpload';
import PointLink from '@/components/PointLink';
import RangeInput from '@/components/RangeInput';
import RichText from '@/components/RichText';
import SelectAllOrNot from '@/components/SelectAllOrNot';
import SpaceContainer from '@/components/SpaceContainer';
import StoreSelect from '@/components/StoreSelect';

const { RangePicker } = DatePicker;

const renderRangePicker = (props: RangePickerProps) => {
  return <RangePicker style={{ width: '100%' }} allowClear {...props} />;
};

function renderText({ value = '' }: { value: string }): React.ReactNode {
  return <span>{value}</span>;
}

export default {
  text: renderText,
  'radio-group': Radio.Group,
  'date-range-picker': renderRangePicker,
  'date-picker': DatePicker,
  'space-container': SpaceContainer,
  'checkbox-group': Checkbox.Group,
  'select-all-not': SelectAllOrNot,
  'antd-select': Select,
  'rich-text': RichText,
  'point-link': PointLink,
  'text-area': Input.TextArea,
  'range-input': RangeInput,
  'number-input': InputNumber,
  'store-select': StoreSelect,
  'image-upload': ImageUpload,
  'media-upload': MediaUpload,
  'classify-select': ClassifySelect,
  'goods-select': GoodsSelect,
};
