export default {
  afterSalesOrderNO: 'AfterSales Order NO',
  associateOrderNO: 'Associate Order NO',
  afterSalesStatus: 'AfterSalesStatus',
  userID: 'User ID',
  userPhoneNO: 'User Phone NO',
  applyRefundAmount: 'Apply Refund Amount',
  actualRefundAmount: 'Actual Refund Amount',
  minApplyRefundAmount: 'Min Apply Refund Amount',
  maxApplyRefundAmount: 'Max Apply Refund Amount',
  minActualRefundAmount: 'Min Actual Refund Amount',
  maxActualRefundAmount: 'Max Actual Refund Amount',
  initiateAfterSalesTime: 'Initiate AfterSales Time',
  minInitiateAfterSalesTime: 'Min Initiate AfterSales Time',
  maxInitiateAfterSalesTime: 'Max Initiate AfterSales Time',
  subSalesOrderNO: 'subSales Order NO',
  memberCardNO: 'Member Card NO',
  reason: 'Reason',
  all: 'All',
  refundTypeReview: 'Refund Type Review',
  refundReviewOnly: 'RefundReview Only',
  pendingReturnConfirmation: 'Pending Return Confirmation',
  operations: 'Operations',
  returnToWarehouse: 'Return To Warehouse',
  productMainImage: 'Product Main Image',
  productName: 'Product Name',
  specifications: 'Specifications',
  mainUPCCode: 'Main UPC Code',
  productId: 'Product ID',
  sellingPrice: 'Selling Price',
  pricePerUnitQuantity: 'Price Per Unit Quantity',
  refundableQuantity: 'Refundable Quantity',
  refundableWeight: 'Refundable Weight',
  freeGifts: 'Free Gifts',
  returnableItems: 'Returnable Items',
  refundTime: 'Refund Time',
};
