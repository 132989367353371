export default {
  title0001: 'Content title',
  title0002: 'Category',
  title0003: 'Creation time',
  title0004: 'Content status',
  title0005: 'Update time',
  title0006: 'New content',
  title0007: 'Content Category',
  title0008: 'Number of views',
  title0009: 'Created by',
  title0010: 'Creation time',
  title0011: 'Updated by',
  title0012: 'Update time',
  title0013: 'Status',
  title0014: 'Sort value',
  title0015: 'Operation',
  title0016: 'Edit',
  title0017: 'Edit content',
  title0018: 'Please enter, limited to 30 characters',
  title0019: 'Enter numbers only',
  title0020: 'Simplified content',
  title0021: 'Traditional content',
  title0022: 'English content',
  title0023: 'Shelf',
  title0024: 'off-shelf',
  title0025:
    'After the shelf will not be visible in the foreground, whether to confirm the shelf',
  title0026: 'Shelf success',
  title0027: 'Save draft',
  title0028: 'Release Status',
  title0029: 'Simplified Title',
  title0030: 'Traditional Title',
  title0031: 'English Title',
  title0032: 'English',
  title0033: 'Simplified',
  title0034: 'Traditional',
  title0035: 'Content',
  title0036: 'keywords',
  title0037: 'description',
  title0038: 'Release Type',
  title0039: 'Release Time',
  title0040: 'Please enter, limited to 20 words',
  title0041: 'Please enter, limited to 100 characters',
  title0042: 'Please select',
  title0043:
    'Tips：English、Simplified、Traditional content are all required, please finish them before submit.',
};
