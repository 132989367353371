import { Button, Table, Image } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import styles from './index.less';
import { promotionAPI } from '@/services';

type IProps = {
  data?: defs.product.BackendProduct[];
  handleRemove: (item: defs.product.BackendProduct) => void;
  disabled?: boolean;
  batchId?: string;
};
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}
const SelectedTable = (props: IProps) => {
  const { data = [], handleRemove, batchId, disabled } = props;

  const columns: ColumnsType<defs.product.BackendProduct> = [
    {
      title: t<string>(`components.goodsSelect.title0005`),
      dataIndex: 'productNameEN',
      key: 'productNameEN',
      align: 'center',
      fixed: 'left',
    },
    {
      title: t<string>(`components.goodsSelect.title0006`),
      dataIndex: 'productId',
      key: 'productId',
      align: 'center',
    },
    {
      title: t<string>(`components.goodsSelect.title0007`),
      dataIndex: 'upc',
      key: 'upc',
      align: 'center',
    },
    {
      title: t<string>(`components.goodsSelect.title0009`),
      dataIndex: 'image',
      key: 'image',
      render: (url) => <Image width="50px" src={url} />,
      align: 'center',
    },
    {
      title: t<string>(`components.goodsSelect.title0008`),
      dataIndex: 'category',
      key: 'category',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) =>
        disabled ? (
          ''
        ) : (
          <Button type="link" onClick={() => handleRemove(record)}>
            {t<string>(`components.goodsSelect.title0012`)}
          </Button>
        ),
      align: 'center',
    },
  ];
  const [tableData, setTableData] = useState<
    defs.product.BackendProduct[] | undefined
  >([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 30, 50],
      showTotal: (t) => `Total ${t} items`,
    },
  });
  const pageTableData = () => {
    if (!batchId) {
      return;
    }
    setLoading(true);
    promotionAPI.boImportFile.pageProduct
      .request({
        batchId,
        page: tableParams.pagination?.current,
        size: tableParams.pagination?.pageSize,
      })
      .then((res) => {
        if (res.success && res.data) {
          const results = res.data?.records?.map((el) => {
            return {
              ...el,
              variantId: el.upc,
              articleNumber: el.id,
              productNameEN: el.name,
              productId: el.id,
            };
          });
          setTableData(results);
          setLoading(false);
          setTableParams({
            pagination: {
              ...tableParams.pagination,
              total: res.data.total,
            },
          });
        }
      });
  };
  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (
      pagination.current !== tableParams.pagination?.current ||
      pagination.pageSize !== tableParams.pagination?.pageSize
    ) {
      setTableParams({
        pagination: {
          ...tableParams.pagination,
          ...pagination,
        },
      });
    }
  };
  useEffect(() => {
    pageTableData();
  }, [batchId, JSON.stringify(tableParams)]);
  if (!data.length) {
    return (
      <div className={styles.selectedTableCard}>
        <h3>{t<string>(`components.goodsSelect.title0010`)}</h3>
        <Table
          rowKey="upc"
          columns={columns}
          dataSource={tableData}
          loading={loading}
          scroll={{ y: '335px', x: '600px' }}
          size="small"
          onChange={handleTableChange}
          pagination={tableParams.pagination}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.selectedTableCard}>
        <h3>{t<string>(`components.goodsSelect.title0010`)}</h3>
        <Table
          rowKey="upc"
          columns={columns}
          dataSource={data}
          scroll={{ y: '335px', x: '600px' }}
          size="small"
          pagination={{
            showSizeChanger: true,
            total: data.length,
            pageSizeOptions: [10, 20, 30, 50],
            showTotal: (t) => `Total ${t} items`,
          }}
        />
      </div>
    );
  }
};

export default SelectedTable;
