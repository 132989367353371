const ListSection = {
  activity_id: '活动ID',
  activity_name: '活动名称',
  coupon_code: '优惠码',
  listing_status: '上架状态',
  discount_type: '优惠类型',
  add_activity: '新增活动',
  edit_activity: '编辑活动',
  update_activity: '更新活动',
  view_activity: '查看活动',
  distributable_quantity: '可发数量',
  redeemed_quantity: '已核销数量',
  activity_effective_expiration_time: '活动生失效时间',
  updater: '更新人',
  update_time: '更新时间',
  status: '状态',
  operation: '操作',
  edit: '编辑',
  off_shelf: '下架',
  on_shelf: '上架',
  off_shelf_status: '已下架',
  on_shelf_status: '已上架',
  view: '查看',
  full_reduction: '满减',
  full_discount: '满折',
};

const CreateSection = {
  activity_name: '活动名称',
  please_enter: '请输入',
  coupon_code: '优惠码',
  discount_type: '折扣类型',
  full_discount: '满折',
  full_reduction: '满减',
  discount_rules: '折扣规则',
  product_amount: '商品金额',
  please_select: '请选择',
  support_up_to_9_digits_positive_integer: '支持不超过9位的正整数',
  please_enter_a_positive_integer_between_1_and_999: '请输入1-999的正整数',
  integer_between_1_and_99: '1-99的整数',
  discount_ratio: '折扣比例',
  maximum_limit: '最大额度',
  please_enter_a_positive_integer_less_than_1000000:
    '请输入1000000以内的正整数',
  deduction_amount: '抵扣金额',
  issue_quantity: '发放数量',
  use_limit: '使用限制',
  validity_period: '有效期',
  activity_level: '活动层级',
  select_product: '选择商品',
  exclude_product: '排除商品',
  cover_business: '覆盖业务',
  available_stores: '可用门店',
  click_to_select: '点击选择',
  cost_sharing: '成本分摊',
  platform_bears: '平台承担',
  please_enter_a_positive_integer_between_0_and_100: '请输入0-100的正整数',
  store_bears: '门店承担',
  please_enter_4_8_digits_letters: '请输入4-8位数字/字母 （忽略大小写）',
  coupon_code_invalid: '优惠码格式错误',
  limit_per_person_prefix: '限制每个用户使用',
  limit_per_person_suffix: '次',
  save_submit: '保存提交',
  add_coupon_success: '新增优惠码活动成功',
  edit_coupon_success: '编辑优惠码活动成功',
};

export default {
  ...ListSection,
  ...CreateSection,
};
