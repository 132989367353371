export default {
  acceptOrder: '接单',
  top: '置顶',
  orderNo: '订单编号',
  paymentOrderNo: '付款单号',
  userID: '用户 ID',
  userPhoneNO: '用户电话',
  memberCardNo: '会员卡号',
  deliveryMethod: '配送方式',
  orderStatus: '订单状态',
  orderChannel: '下单渠道',
  store: '门店',
  orderTime: '下单时间',
  operations: '缺货订单详情',
  operations1: '查看缺货订单详情',
  amountReceivable: '应收金额',
  afterSalesOrderNO: '售后单号',
  afterSale: '售后',
  title0001: '商品前端展示名',
  title0002: 'UPC 编码',
  title0003: '商品ID',
  title0004: '前台类目',
  title0005: '商品类型',
  title0006: '下单数量',
  title0007: '拣货数量',
  title0008: '下单金额',
  title0009: '拣货金额',
  title0010: '拣货结果',
  title0011: '商品',
};
