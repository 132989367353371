export default {
  sendingRules: '发送规则',
  cycleRule: '周期规则',
  cycleRuleName: '周期规则名称',
  updateTime: '更新时间',
  updater: '更新人',
  status: '状态',
  operate: '操作',
  enabledState: '启用状态',
  cycleType: '周期类型',
  day: '按天',
  week: '按周',
  month: '按月',
  cycleRuleInput1: '每',
  cycleRuleInput2: '天的',
  cycleRuleInput4: '周的',
  cycleRuleInput5: '月的',
  cycleRuleInput6: '日的',
  cycleRuleInput3: '点发送',
  sendContentType: '发送内容类型',
  sms: '短信',
  email: '邮件',
  smsContent: '短信内容',
  emailTitle: '邮件标题',
  emailContent: '邮件内容',
};
