import { EMPTY_FIELD_PLACEHOLDER } from '@/constants';

export interface TagContainerProps {
  type?: 'tag' | 'container';
  separator?: string;
  options: Array<string | number>;
  maxLength?: number;
}

const TagContainer = (props: TagContainerProps) => {
  const { type = 'tag', options, separator = ',', maxLength } = props;
  const lenLimit = Number.isInteger(maxLength);
  const values = lenLimit ? options?.slice(0, maxLength) : options;
  const showMore = lenLimit && options?.length > maxLength!;

  return (
    <>
      {type === 'tag' && (
        <>
          {values ? values.join(separator) : EMPTY_FIELD_PLACEHOLDER}
          {showMore ? '...' : ''}
        </>
      )}
      {type === 'container' && <>{options}</>}
    </>
  );
};
export default TagContainer;
