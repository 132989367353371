/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type matchParam = {
  /** 是否模糊查询 */
  exact?: boolean;

  /** 关键字：UPC，商品 ID，商品名称 */
  keyword?: string;

  /** 页码 */
  page?: number;

  /** 每页大小 */
  size?: number;
};
export type matchOptions = Record<string, any>;

export type matchResponse = Promise<
  defs.promotion.Response<
    defs.promotion.IPage<defs.promotion.BoSelectActivityProductResponse>
  >
>;

/**
 * @desc 查询活动商品列表
 */
export function request(
  params: matchParam,
  options?: matchOptions,
): matchResponse {
  const host = getEnvHost();
  const url = host + '/promotion/bo/activity/product/match';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
