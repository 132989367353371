export default {
  title0001: '商品名称',
  title0002: '上架状态',
  title0003: '下架后前台将不可见，是否确认下架？',
  title0004: '上架成功',
  title0005: '操作',
  title0006: '选择商品',
  title0007: '批量上架',
  title0008: '批量下架',
  title0009: '商品ID',
  title0010: '商品标题',
  title0011: '折扣',
  title0012: '封面图',
  title0013: '所属分类',
  title0014: '点击量',
  title0015: '更新人',
  title0016: '更新时间',
  title0017: '状态',
  title0018: '排序',
  title0019: '移除',
  title0020: '手动选择商品',
  title0021: '搜索标题',
  title0022: '显示顺序',
  title0023: '请输入1-99正整数',
  title0024: '选择',
  title0025: '移除后列表不再包含该商品，是否确认移除',
  title0026: '请至少选择一条符合操作条件的记录',
  title0027: '确定{{status}}该模板？',
  title0028: '模板名称',
  title0029: '是否默认',
  title0030: '包含门店',
  title0031: '新建模板',
  title0032: '编辑模板',
  title0033: '编辑商品',
};
