export default {
  title0001: 'Product Name',
  title0002: 'Article Number',
  title0003: 'UPC',
  title0004: 'Location Number',
  title0005: 'Warehouse',
  title0006: 'Print UPC Code',
  title0007: 'Import Inventory',
  title0008: 'Product Image',
  title0009: 'Available Stock',
  title0010: 'Available Sale Rate',
  title0011: 'Saleable Stock',
  title0012: 'Lock Stock',
  title0013: '',
  title0014: 'Total Stock',
  title0015: 'Inventory Change Details',
  title0016: 'Check Details',
  title0017: '',
  title0018: 'Import Saleable Data',
  title0019: 'Choose Data',
  title0020: 'Choose File',
  title0021: 'Import xls/xlsx File',
  title0022: 'Click Here To Download Template',
  title0023: 'Import Completed',
  title0024: 'Import Success',
  title0025: 'Fail',
  title0026: '',
  title0027: '',
  title0028: 'Inventory Change Details',
  title0029: 'Updated Time',
  title0030: 'Updated Reason',
  title0031: 'Available Stock Change',
  title0032: 'Lock Stock Change',
  title0033: 'Current Available Stock',
  title0034: 'Current Lock Stock',
  title0035: 'Total Stock',
  title0036: 'Receipt No',
  title0037: 'Updated Name',
  title0038: 'Outbound Order',
  title0039: 'Inbound Order',
  title0040: 'At Least Select One Item',
};
