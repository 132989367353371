export default {
  addDriverTip: '附加配送费',
  addressCity: 'ship to(city)',
  addressState: 'ship to(state)',
  adjustPrice: '拣货调整金额',
  adjustQuantity: '调整数量',
  adjustWeight: '调整重量',
  afterAllPromotionAmount: '所有促销后金额',

  captureTime: '扣款时间',
  channelType: '渠道类型',
  createdTime: '下单时间',
  crvFee: '退瓶费',
  deliveredTime: '完成时间',
  deliveryFee: '配送费',
  deliveryTypeDesc: '配送方式',
  diffAfterAllPromotionAmount: '补差所有促销后金额',
  diffCrvFee: '补差退瓶费',
  diffDiscountedPrice: '补差直降后商品价格',
  diffMarketPrice: '补差商品原价',
  diffPlatformDiscount: '补差平台分摊促销金额',
  diffStoreDiscount: '补差商家分摊促销金额',
  diffTaxFee: '补差消费税',
  discountedPrice: '直降后商品价格',
  driverTip: '司机小费',
  id: '订单行编码',

  isWeighedProduct: '称重商品',
  itemWeight: '下单重量',

  marketPrice: '商品售价',
  merchantName: '商家名称',
  orderId: '订单号',
  payAmount: '收费金额',
  payingParty: '付款方',
  paymentSuccessful: '扣款成功',

  performAfterAllPromotionAmount: '履约所有促销后金额',

  performDeliveryFee: '履约配送费',
  performDiscountedPrice: '履约直降后商品价格',

  performMarketPrice: '履约商品原价',
  performPlatformDiscount: '履约平台分摊促销金额',

  performStoreDiscount: '履约商家分摊促销金额',

  performanceQuantity: '履约数量',
  performanceWeigh: '履约重量',
  platformCommissionAmount: '平台扣点（定额）',
  platformCommissionPercent: '平台扣点%',
  platformDiscount: '平台分摊促销金额',
  pointsDeduction: '积分抵扣',
  productId: 'Article Number',
  productName: '商品名称',
  quantity: '下单数量',
  receivingParty: '收款方',
  saleUom: '售卖单位',
  netWeight: '净重',
  netWeightUom: '净重单位',
  serviceFee: '服务费',
  splitAmount: '分账金额',
  storeAddrCity: 'ship from(city)',
  storeAddrState: 'ship from(State)',
  storeDiscount: '商家分摊促销金额',
  storeId: '店铺编码',
  storeName: '店铺名称',
  storeTimeZone: '所在时区',
  taxFee: '消费税',
  upc: 'UPC',
  userId: '用户ID',
  userPhone: '用户手机号',
  variantNameEn: '规格',
  storeActivityPrice: '门店活动价',
  onlineActivityPrice: '线上活动价',
  currentSalePrice: '当前售价',
  itemAmount: '商品金额小计',
  platformDeliveryFee: '平台配送费',
  platformDeliveryFeeDiscount: '平台配送费优惠',
  storeDeliveryFee: '商家配送费',
  storeDeliveryFeeDiscount: '商家配送费优惠',
  platformPromotionDiscount: '平台优惠金额',
  storePromotionDiscount: '门店优惠金额',
  orderTotalAmount: '下单总金额',
  diffItemWeight: '补差重量',
  diffQuantity: '补差数量',
  diffItemAmount: '补差商品金额小计',
  diffServiceFee: '补差服务费',
  diffPlatformDeliveryFee: '补差平台配送费',
  diffPlatformDeliveryFeeDiscount: '补差平台配送费优惠',
  diffStoreDeliveryFee: '补差商家配送费',
  diffStoreDeliveryFeeDiscount: '补差商家配送费优惠',
  diffAddDriverTip: '补差附加配送费',
  diffDriverTip: '补差司机小费',
  diffPlatformPromotionDiscount: '补差平台优惠金额',
  diffStorePromotionDiscount: '补差商家优惠金额',
  diffPointsDeduction: '补差积分抵扣',
  diffOrderTotalAmount: '补差总金额',
  performQuantity: '履约数量',
  performItemWeight: '履约重量',
  performItemAmount: '履约商品金额小计',
  performTaxFee: '履约消费税',
  performCrvFee: '履约退瓶费',
  performServiceFee: '履约服务费',
  performPlatformDeliveryFee: '履约平台配送费',
  performPlatformDeliveryFeeDiscount: '履约平台配送费优惠',
  performStoreDeliveryFee: '履约商家配送费',
  performStoreDeliveryFeeDiscount: '履约商家配送费优惠',
  performAddDriverTip: '履约附加配送费',
  performDriverTip: '履约司机小费',
  performPlatformPromotionDiscount: '履约平台优惠金额',
  performStorePromotionDiscount: '履约商家优惠金额',
  performPointsDeduction: '履约积分抵扣',
  performOrderTotalAmount: '履约总金额',
  itemAdjustment: '临时加收金额',
  authedAmount: '预授权金额',
  captureAmount: '实际扣款金额',
  commissionPercent: '佣金%',
  commissionAmount: '佣金（定额)',
  isGift: '是否为赠品',
  mainProductName: '主品名称',
};
// performStoreDeliveryFee	number
// 履约商家配送费

// performStoreDeliveryFeeDiscount	number
// 履约商家配送费优惠
