export default {
  warehouse_operation_documents: '仓储作业单据',
  inbound_order_query: '入库单查询',
  outbound_order_query: '出库单查询',
  outbound_warehouse: '出库仓库',
  outbound_order_number: '出库单号',
  inbound_warehouse: '入库仓库',
  inbound_order_number: '入库单号',
  operator: '操作人',
  location_code: '位置编码',
  product_name: '商品名称',
  upc_code: 'UPC编码',
  creation_time: '创建时间',
  status: '状态',
  product_details: '商品明细',
  total_quantity: '总数量',
  view_details: '查看明细',
  product_image: '商品图片',
  outbound_quantity: '出库数量',
  outbound_location_code: '出库位置编码',
  inbound_quantity: '入库数量',
  inbound_location_code: '入库位置编码',
  belonging_warehouse: '归属仓库',
  create_outbound_order: '新建出库单',
  create_inbound_order: '新建入库单',
  select_product: '选择商品',
  add: '添加',
  operation: '操作',
  remove: '移除',
  added_list: '已添加列表',
  input_tip: '请输入商品标题、UPC编码、Article Number 搜索选择',
  completed: '已完成',
  not_completed: '未完成',
};
