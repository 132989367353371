export default {
  setting: 'Setting',
  baseInfo: 'basic information',
  productSpecifications: 'Product specifications',
  productMainImage: 'Product main image',
  productDetail: 'Product details',
  seoInfo: 'SEO information',
  baseInfoModalTitle: 'Basic information settings',
  manufacturer: 'manufacturer',
  seoModalTitle: 'SEO information settings',
  keywordsEN: 'English keywords',
  titleEN: 'English title',
  descriptionEN: 'English description',
  keywordsSCH: 'Simplified Chinese keywords',
  titleSCH: 'Simplified Chinese title',
  descriptionSCH: 'Simplified Chinese description',
  keywordsTCH: 'Traditional Chinese keywords',
  titleTCH: 'Traditional Chinese title',
  descriptionTCH: 'Traditional Chinese description',
  mainImageModalTitle: 'Main image settings',
  mainImage1: 'Main picture 1',
  mainImage2: 'Main picture 2',
  mainImage3: 'Main picture 3',
  mainImage4: 'Main picture 4',
  mainImage5: 'Main picture 5',
  mainImage6: 'Main picture 6',
  mainImage7: 'Main picture 7',
  mainImage8: 'Main picture 8',
  mainImage9: 'Main picture 9',
  variantNameEN: 'Specification English name',
  variantNameSCH: 'Specification simplified name',
  variantNameTCH: 'Specification traditional Chinese name',
  image: 'Specification pictures',
  status: 'Status',
  mainUpc: 'UPC',
  updateVariant: 'Edit specs',
  cooCode: 'Origin',
  storeVariantDetails: 'Associated stores',
  netWeight: 'Specifications net weight',
  netWeightUom: 'Net weight unit',
  saleUom: 'sales unit',
  price: 'Price',
  alcoholic: 'contain alcohol',
  validityPeriod: 'expiry date',
  brand: 'Brand',
  length: 'length',
  width: 'width',
  high: 'high',
  weight: 'weight',
  weightUom: 'Weight Uom',
  packLength: 'Pack Length',
  packWidth: 'Pack Width',
  packHigh: 'Pack High',
  packWeight: 'Pack Weight',
  packWeightUom: 'pack Weight Uom',
  packQuantity: 'Pack Quantity',
  packFlag: 'Pack Flag',
};
