export default {
  id: 'Group ID',
  groupName: 'Group Name',
  storeCount: 'Store Count',
  storeType: 'Store Type',
  updater: 'Updater',
  updateTime: 'Update Time',
  operations: 'Operations',
  edit: 'Edit',
  status: 'Status',
  create: 'Create',
  storeID: 'Store ID',
  storeName: 'Store Name',
  storeDetail: 'Store Detail',
  confirm: 'Are you sure to continue?',
  storeTag: 'Store Tag',
};
