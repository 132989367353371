/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type matchBody = defs.promotion.MatchGlobalDiscountRequest;
export type matchOptions = Record<string, any>;

export type matchResponse = Promise<
  defs.promotion.Response<defs.promotion.MatchGlobalDiscountResponse>
>;

/**
 * @desc 匹配
 */
export function request(
  body: matchBody,
  options?: matchOptions,
): matchResponse {
  const host = getEnvHost();
  const url = host + '/promotion/bo/activity/global/discount/match';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
