export default {
  title0001: 'Settlement Number',
  title0002: 'Payee',
  title0003: 'Payer',
  title0004: 'Update time',
  title0005: 'Settlement time',
  title0006: 'Nuclear Price Status',
  title0007: 'Payment Status',
  title0008: 'Mass Payment',
  title0009: 'Settlement method',
  title0010: 'Payment time',
  title0011: 'Settlement Amount',
  title0012: 'Payment Amount',
  title0013: 'Unpaid Balance',
  title0014: 'Settlement method',
  title0015: 'Price review time',
  title0016: 'Summary time period',
  title0017: 'Settlement Status',
  title0018: 'Payment method',
  title0019: 'Payment failure reason',
  title0020: 'Payment',
  title0021: 'Cancel payment',
  title0022: 'Details',
  title0023: 'Automatic',
  title0024: 'Manual',
  title0025: 'Price Verified',
  title0026: 'Normal',
  title0027: 'Obsolete',
  title0028: 'to be paid',
  title0029: 'Payment in progress',
  title0030: 'Payment failed',
  title0031: 'Paid',
  title0032: 'None',
  title0033: 'Make settlement invalidation',
  title0034: 'Settlement Task',
  title0035: 'Settlement task number',
  title0036: 'Order Number',
  title0037: 'After-sale order number',
  title0038: 'Amount to share',
  title0039: 'Settlement Details',
  title0040: 'Price reviewer',
  title0041: 'Payment Details',
  title0042: 'Payment number',
  title0043: 'Payment serial number',
  title0044: 'Refund Details',
  title0045: 'Refund order number',
  title0046: 'Refund serial number',
  title0047: 'Refund method',
  title0048: 'Refund Time',
  title0049: 'Refund Status',
  title0050: 'Operation useless',
  title0051: 'Obsolete time',
  title0052: 'to be counted',
  title0053: 'Statistics succeeded',
  title0054: 'Statistics failed',
  title0055:
    'Please select at least one record that meets the operation conditions',
  title0056: 'Refund Amount',
  title0057: 'Success',
  title0058: 'Failure',
  title0059: 'Refund successfully',
  title0060: 'Refund failed',
  title0061: 'Paid',
  title0062: 'Payment failed',
  title0063: 'None',
};
