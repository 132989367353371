export default {
  title0001: '内容标题',
  title0002: '所属分类',
  title0003: '创建时间',
  title0004: '内容状态',
  title0005: '更新时间',
  title0006: '新建内容',
  title0007: '内容分类',
  title0008: '素材',
  title0009: '上传素材',
  title0010: '查看/评论次数',
  title0011: '更新人',
  title0012: '更新时间',
  title0013: '状态',
  title0014: '排序值',
  title0015: '操作',
  title0016: '编辑',
  title0017: '编辑内容',
  title0018: '请输入，限20个字',
  title0019: '限输入数字',
  title0020: '简体内容',
  title0021: '繁体内容',
  title0022: '英文内容',
  title0023: '上架',
  title0024: '下架',
  title0025: '下架后前台将不可见，是否确认下架',
  title0026: '上架成功',
  title0027: '保存草稿',
  title0028: '图片最多9张，支持jpg/png格式；视频最多1个，支持MP4格式',
  title0029: '请输入商品ID或商标标题关键词',
  title0030: '简体中文标题',
  title0031: '繁体中文标题',
  title0032: '英文标题',
  title0033: '请输入，限100个字符',
  title0034: '限输入数字，单位分',
  title0035: '烹饪时间',
  title0036: '关联商品',
  title0037: '关键词',
  title0038: '标题',
  title0039: '描述',
  title0040: '选择商品',
  title0041: '精确匹配',
  title0042: '模糊匹配',
};
