import * as boActivityDiscountCodeConfigInterface from './boActivityDiscountCodeConfig';
import * as boActivityGlobalDiscountInterface from './boActivityGlobalDiscount';
import * as boActivityProductInterface from './boActivityProduct';
import * as boBuyAGetBDiscountConfigInterface from './boBuyAGetBDiscountConfig';
import * as boBuyNGetXDiscountConfigInterface from './boBuyNGetXDiscountConfig';
import * as boCouponInterface from './boCoupon';
import * as boImportFileInterface from './boImportFile';
import * as boTimeLimitDiscountConfigInterface from './boTimeLimitDiscountConfig';
import * as inviteFriendConfigInterface from './inviteFriendConfig';

export const promotion = {
  boActivityDiscountCodeConfig: boActivityDiscountCodeConfigInterface,
  boActivityGlobalDiscount: boActivityGlobalDiscountInterface,
  boActivityProduct: boActivityProductInterface,
  boBuyAGetBDiscountConfig: boBuyAGetBDiscountConfigInterface,
  boBuyNGetXDiscountConfig: boBuyNGetXDiscountConfigInterface,
  boCoupon: boCouponInterface,
  boImportFile: boImportFileInterface,
  boTimeLimitDiscountConfig: boTimeLimitDiscountConfigInterface,
  inviteFriendConfig: inviteFriendConfigInterface,
};
