export default {
  storeName: '门店名称',
  storeID: '门店ID',
  introID: '内容ID',
  updater: '更新人',
  updateTime: '更新时间',
  operation: '操作',
  intro: '门店介绍信息',
  edit: '编辑',
  suffix: '门店介绍信息',
};
