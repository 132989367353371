/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type listUserParam = {
  /** lastUpdateTimestamp */
  lastUpdateTimestamp: number;

  /** limit */
  limit?: number;
};
export type listUserOptions = Record<string, any>;

export type listUserResponse = Promise<
  defs.account.Response<Array<defs.account.POSUserVo>>
>;

/**
 * @desc 查询用户信息
 */
export function request(
  params: listUserParam,
  options?: listUserOptions,
): listUserResponse {
  const host = getEnvHost();
  const url = host + '/account/pos/user/list';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
