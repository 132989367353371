export default {
  newKeywords: '新建关键词',
  updateKeywords: '编辑关键词',
  offShelfTip: '下架后前台将不可见，是否确认下架？',
  operations: '操作',
  edit: '编辑',
  coverageService: '覆盖业务',
  O2OArea: 'O2O 区域',
  clickNumber: '点击量',
  updater: '更新人',
  updatedTime: '更新时间',
  status: '状态',
  keywords: '关键词',
  createdTime: '创建时间',
  bulkOffShelves: '批量下架',
  batchShelves: '批量上架',
  sort: '排序',
  shelf: '上架',
  offShelf: '下架',
  chineseKeywords: '中文关键词',
  traditionalKeywords: '繁体关键词',
  englishKeywords: '英文关键词',
  batchAction: '请至少选择一条符合操作条件的记录',
  displayOrder: '显示顺序',
  default: '默认',
};
