// 注册会员管理
export default {
  id: 'Member ID',
  nickname: 'Person Nickname',
  avatar: 'Person Avatar',
  phone: 'Contact',
  gender: 'Gender',
  birthday: 'Birthday',
  email: 'Email',
  language: 'Language',
  inviter: 'Inviter',
  memberLevel: 'Level Name',
  paidMember: 'Paid Member',
  registerSource: 'Register Channel',
  points: 'Points',
  registerTime: 'Register Time',
  status: 'Status',
  operations: 'Operations',
  accountStatus: 'Account Status',
  inviterPhoneNo: 'Inviter Phone No',
  disableAccountTip:
    'After disabling, the user will no longer be able to log in and use it. Are you sure to disable it?',
  enable: 'Disable Recall',
  disable: 'Disable',
  disableCycle: 'Disable Recall',
  permanentlyDisabled: 'Permanently Disabled',
  shortTermDisabled: 'Short Term Disabled',
  inputPlaceholder: 'Please enter the number of days',
  detail: 'View',
  pointDetails: 'Points Details',
  pointsBalance: 'Points Balance',
  occurredTime: 'Occurred Time',
  projectType: 'Project Type',
  pointsIncrease: 'Points Increase',
  validUntil: 'Valid Until',
  pointsDecrease: 'Points Decrease',
  balance: 'Balance',
  remark: 'Remark',
  freeMember: 'Free Member',
  memberCardNO: 'Member Card NO',
  preMemberCardNo: 'Please enter the initial membership card number',
  sufMemberCardNo: 'Please enter the terminated membership card number',
  cardNoSourceName: 'Membership Card NO Source',
  phoneAndEmail: 'Historical Phone NO & Email',
  negativePointsCount: 'Negative Points Count',
  disabledTime: 'DisabledT ime',
  pointsChangeChannel: 'Points Change Channel',
  storeNO: 'Store NO',
  currentPointsBalance: 'Current Points Balance',
  freeze: 'Freeze',
  unfreeze: 'Unfreeze',
  logout: 'Logout',
  view: 'View',
  disableRecall: 'Disable Recall',
  operationTime: 'Operation Time',
  operator: 'Operator',
  operationReason: 'Operation Reason',
  logoutReason: 'Logout Reason',
  operationRecords: 'Operation Records',
  logoutTip: `After logout:
1. The user cannot use this account to log in online.
2. Users will not be able to use this account to earn points in offline stores.
3. All points in the user account will be cleared.
4. Account transaction records, identity information, and account information will be cleared.`,
  checkLogoutTip:
    'There is a business in progress in this account, and the cancellation operation cannot be performed temporarily!',
  orders: 'The following orders are in progress in this user account',
  afterSale:
    "The following after-sales orders are being processed in the user's account",
  orderNO: 'Order NO',
  afterSalesOrderNO: 'AfterSales Order NO',
  orderStatus: 'Order Status',
  afterSalesOrderStatus: 'AfterSales Order Status',
  freezeCycle: 'Freeze Cycle',
  timeRange: 'Time Range',
  reminderTime: 'Reminder Time',
  checkFreezeTip:
    'There is a business in progress in this account, and the freezing operation cannot be performed for the time being!',
  freezeTip:
    'After the account is frozen, the user cannot use the account to log in online, and the user cannot use the point card in the account to earn points offline. Are you sure to freeze it?',
  freezeForever: 'Freeze Forever',
  shortTermFreeze: 'Short Term Freeze',
  days: 'days',
  disableTip:
    'After deactivation, the user will no longer be able to log in and use it. Are you sure to disable it?',
  disableOrderTip: '',
  bind: 'Bind',
  unbind: 'Unbind',
  membershipCardNOAndOperation: 'Membership Card NO And Operation',
  verify: 'Verify',
  sendVerificationCode: 'Send Verification Code',
  historicalPhoneNO: 'Historical Phone NO',
  historicalEmail: 'Historical Email',
};
