export default {
  title0001: '门店选择',
  title0002: '搜索门店',
  title0003: '请输入门店名称或门店编码',
  title0004: '已选门店',
  title0005: '门店名称',
  title0006: '门店编码',
  title0007: '操作',
  title0008: '移除',
  title0009: '点击选择',
  selected_stores: '已选{{num}}个门店',
};
