/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type batchCloseBody = Array<number>;
export type batchCloseOptions = Record<string, any>;

export type batchCloseResponse = Promise<defs.promotion.Response<void>>;

/**
 * @desc 批量下架活动商品
 */
export function request(
  body: batchCloseBody,
  options?: batchCloseOptions,
): batchCloseResponse {
  const host = getEnvHost();
  const url = host + '/promotion/bo/activity/product/batchClose';
  const fetchOption = {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
