/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type initDBOptions = Record<string, any>;

export type initDBResponse = Promise<defs.search.Response>;

/**
 * @desc 初始化商品
 */
export function request(options?: initDBOptions): initDBResponse {
  const host = getEnvHost();
  const url = host + '/search/bo/search_tag/product/initDb';
  const fetchOption = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  return sendRequest(url, fetchOption);
}
