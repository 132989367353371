export default {
  account: 'Account',
  username: 'Username',
  department: 'Organization',
  phoneNumber: 'Phone NO',
  email: 'Email',
  assignRoles: 'Assign Roles',
  passwordValidityPeriod: 'Password Validity Period',
  status: 'Status',
  createdTime: 'Created Time',
  newAccount: 'New Account',
  updateAccount: 'Update Account',
  disableAccountTip:
    'After disabling, the user will not be able to log in to the system. Are you sure?',
  inputPlaceholder: 'Please enter user account/name',
  selectPlaceholder: 'Please select user status',
  password: 'Password',
  accountPlaceholder: 'Please enter user account',
  usernamePlaceholder: 'Please enter username',
  departmentPlaceholder: 'Please select department',
  passwordPlaceholder:
    '8-20 digits include numbers, letters, and English symbols',
  assignRolesPlaceholder: 'Please select roles',
  phoneNumberPlaceholder: 'Please enter phone number',
  emailPlaceholder: 'Please input your email',
  passwordValidityPeriodPlaceholder:
    'Please enter the password expiration date',
  assignedTime: 'Assigned Time',
  roleList: 'Role List',
  setExpirationDate: 'Set Expiration Date',
  resetPassword: 'Reset Password',
  downloadFileTemplate: 'Download the import file template',
  importData: 'Import User Data',
  templateName: 'template',
  viewList: 'View List',
};
