export default {
  fullScreen: '全屏',
  cancelFullScreen: '取消全屏',
  ok: '确认',
  cancel: '取消',
  export: '导出',
  import: '导入',
  search: '查询',
  enabled: '启用',
  disabled: '禁用',
  reset: '重置',
  submit: '提交',
  close: '关闭',
  pass: '通过',
  fail: '不通过',
  remove: '移除',
  save: '保存',
  add: '新增',
  edit: '编辑',
  delete: '删除',
  yes: '是',
  no: '否',
  selectFiles: '选择文件',
  operation: '操作',
  confirm: '确定',
  check: '查看',
  uploadFiles: '上传文件',
};
