/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type submitPickingBody = defs.order.OrderPick;
export type submitPickingOptions = Record<string, any>;

export type submitPickingResponse = Promise<defs.order.Response<void>>;

/**
 * @desc submitPicking
 */
export function request(
  body: submitPickingBody,
  options?: submitPickingOptions,
): submitPickingResponse {
  const host = getEnvHost();
  const url = host + '/order/bo/support/order/submit/picking';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
