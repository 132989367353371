export default {
  title0001: '商品名称',
  title0002: '商品编码',
  title0003: 'UPC编码',
  title0004: '位置编码',
  title0005: '所在仓库',
  title0006: '打印UPC编码',
  title0007: '导入安全库存',
  title0008: '商品图片',
  title0009: '可用库存',
  title0010: '可售比例',
  title0011: '可售库存',
  title0012: '锁定库存',
  title0013: '在途库存',
  title0014: '总库存',
  title0015: '库存变动明细',
  title0016: '查看明细',
  title0017: '在途库存',
  title0018: '导入可售比例数据',
  title0019: '选择数据',
  title0020: '选取文件',
  title0021: '上传xls/xlsx文件',
  title0022: '点此下载导入模板',
  title0023: '导入完成',
  title0024: '本次导入成功',
  title0025: '失败',
  title0026: '条',
  title0027: '点此下载无效记录数据',
  title0028: '查看库存变动明细',
  title0029: '变动时间',
  title0030: '变动原因',
  title0031: '可用库存变化',
  title0032: '锁定库存变化',
  title0033: '可用库存余额',
  title0034: '锁定库存余额',
  title0035: '总库存',
  title0036: '单据',
  title0037: '操作人',
  title0038: '出库',
  title0039: '入库',
  title0040: '请至少选中1条商品记录',
};
