export default {
  title0001: '手动调整积分列表',
  title0002: '手动积分调整',
  title0003: '会员ID',
  title0004: '积分调整时间',
  title0005: '手机号',
  title0006: '积分调整单号',
  title0007: '积分调整发起时间',
  title0008: '会员昵称',
  title0009: '调整数量',
  title0010: '发起人',
  title0011: '积分调整原因',
  title0012: '审核人',
  title0013: '审核状态',
  title0014: '审核时间',
  title0015: '审核操作原因',
  title0016: '操作',
  title0017: '审核',
  title0018: '用户ID',
  title0019: '手机号',
  title0020: '调整原因',
  title0021: '调整数量',
  title0022: '操作原因',
  title0023: '请选择需要调整积分的用户',
  title0024: '会员等级',
  title0025: '用户状态',
  title0026: '操作类型',
  title0027: '变更数量',
  title0028: '增加积分',
  title0029: '减少积分',
};
