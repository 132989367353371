/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type updateProductSortBody =
  defs.product.FunctionTemplateProductStatusVo;
export type updateProductSortOptions = Record<string, any>;

export type updateProductSortResponse = Promise<defs.product.Response<void>>;

/**
 * @desc 调整商品排序
 */
export function request(
  body: updateProductSortBody,
  options?: updateProductSortOptions,
): updateProductSortResponse {
  const host = getEnvHost();
  const url = host + '/product/bo/function/template/product/updateSort';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
