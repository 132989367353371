import { Button, Col, Modal, Row, Tag } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import SelectCard from './SelectCard';
import styles from './index.less';

interface BackendProduct extends defs.product.BackendProduct {
  discount?: number | null;
}
export type GoodsValue = {
  fileIds?: number[];
  products?: BackendProduct[];
  batchId?: string;
};

export type StoreSelectProps = {
  placeholder?: string;
  /**
   * @default input
   */
  onChange?: (values?: GoodsValue) => void;
  type?: 'input' | 'button';
  buttonType?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed';
  buttonTitle?: React.ReactNode;
  disabled?: boolean;
  value?: GoodsValue;
  tagsMaxLen?: number;
  defaultSelect?: defs.promotion.Product[];
  params?: Record<string, any>;
  clearOnClose?: boolean;
  maskClosable?: boolean;
  asyncOpen?: () => Promise<any>;
  level?: number;
};
const GoodsSelect = (props: StoreSelectProps) => {
  const {
    onChange,
    disabled,
    value,
    params,
    type = 'input',
    placeholder,
    buttonType,
    buttonTitle,
    tagsMaxLen = 10,
    clearOnClose = false,
    maskClosable = true,
    defaultSelect,
    asyncOpen,
    level,
  } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<defs.product.BackendProduct[] | undefined>(
    value?.products,
  );
  const [batchId, setBatchId] = useState<string | undefined>(value?.batchId);
  const [data1, setData1] = useState<defs.product.BackendProduct[] | undefined>(
    value?.products,
  );
  const [fileIds, setIds] = useState<number[] | undefined>(value?.fileIds);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setOpen(false);
    if (clearOnClose) {
      setData(undefined);
      setData1(undefined);
      setIds(undefined);
      setBatchId(undefined);
    }
  };
  const handleOk = () => {
    if (onChange) {
      if (data?.length || fileIds?.length) {
        onChange({ products: data, fileIds });
        const newData1: defs.product.BackendProduct[] =
          fileIds?.map((i) => ({
            productId: i,
            productNameEN: `file-${i}`,
          })) || [];
        if (data?.length) {
          newData1.push(...data);
        }
        setData1(newData1);
      } else if (batchId) {
        onChange({ batchId });
      } else {
        onChange(undefined);
        setData1(undefined);
      }
    }
    handleCancel();
  };
  const handleChange = (
    products: defs.product.BackendProduct[],
    fileIds?: number[],
    batchId?: string,
  ) => {
    setData(products);
    setBatchId(batchId);
  };
  const handleOpen = () => {
    if (typeof asyncOpen === 'function') {
      setLoading(true);
      asyncOpen()
        .then(() => {
          setTimeout(() => {
            setOpen(true);
          }, 0);
        })
        .finally(() => setLoading(false));
    } else {
      setOpen(true);
    }
  };
  const handleFiles = (ids: number[]) => {
    setIds(ids);
  };
  const showTags = data1?.slice(0, tagsMaxLen);
  const isTagsMax = data?.length ? data?.length > tagsMaxLen : false;
  useEffect(() => {
    if (
      (data && data.length > 0) ||
      !defaultSelect ||
      defaultSelect.length === 0
    )
      return;
    setData(
      defaultSelect.map((item) => {
        const { id = 0, name = '', upc = 0 } = item;

        return {
          ...item,
          productNameEN: name,
          productId: +id,
          articleNumber: +upc,
        };
      }),
    );
  }, [defaultSelect]);
  return (
    <div className={`ant-select ${styles.storeSelectCard}`}>
      {type === 'input' && (
        <div
          onClick={handleOpen}
          className={`ant-select-selector ${styles.storeSelectDiv}`}
        >
          {showTags?.length ? (
            <div className={styles.storeTags}>
              {showTags?.map((item) => (
                <Tag key={item.productId}>{item.productNameEN}</Tag>
              ))}
              {isTagsMax && <Tag>...</Tag>}
            </div>
          ) : (
            <div
              className={`ant-select-selection-placeholder ${styles.storePlaceholder}`}
            >
              {placeholder || t<string>(`pages.rules.select`)}
            </div>
          )}
        </div>
      )}
      {type === 'button' && (
        <Button onClick={handleOpen} type={buttonType} loading={loading}>
          {buttonTitle || t<string>(`pages.rules.select`)}
        </Button>
      )}
      {open && (
        <Modal
          open={open}
          title={t<string>(`components.goodsSelect.title0001`)}
          onCancel={handleCancel}
          closable={false}
          footer={null}
          width="1000px"
          maskClosable={maskClosable}
        >
          <SelectCard
            onChang={handleChange}
            data={data}
            disabled={disabled}
            level={level}
            onFileIdsChange={handleFiles}
            params={params}
          />
          <Row justify="end" style={{ padding: '10px' }}>
            <Col>
              <Button type="primary" onClick={handleOk}>
                {t<string>(`button.common.ok`)}
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default GoodsSelect;
