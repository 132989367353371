export default {
  title0001: 'Feedback content',
  title0002: 'Member nickname',
  title0003: 'Submission time',
  title0004: 'Processed by',
  title0005: 'Processing time',
  title0006: 'Image',
  title0007: 'Source',
  title0008: 'Status',
  title0009: 'Operation',
  title0010: 'Processing',
  title0011: 'View',
  title0012: 'View processing comments',
  title0013: 'Fill in the processing comments',
  title0014: 'Processing comments',
  title0015: 'Limit 200 characters',
  title0016: 'All ({{num}})',
  title0017: 'Pending processing ({{num}})',
  title0018: 'Processed ({{num}})',
};
