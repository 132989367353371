export default {
  promotionDetails: 'Promotion Details',
  promotionType: 'Promotion type',
  promotionType1: 'Buy gifts',
  promotionType2: 'xth item discount',
  needToBuyGoods: 'Need to purchase goods',
  purchaseQuantity: 'Purchase quantity',
  freeMerchandise: 'Give away goods',
  giftQuantity: 'Gift quantity',
  itemX: 'Item X',
  itemXPlaceholder: 'Please enter a positive integer from 2-9',
  discountType: 'Discount type',
  discountStrength: 'Discount strength',
  discountStrengthPlaceholder: 'Please enter, supports two decimal places',
  percentDiscount: 'Percent discount',
  fixedSellingPrice: 'Fixed selling price',
  fixedPrice: 'Fixed discounted price',
  inputQuantityPlaceholder: 'Please enter a positive integer from 1-999',
};
