export default {
  promotionDetails: '促销详情',
  promotionType: '促销类型',
  promotionType1: '买赠',
  promotionType2: '第x件折扣',
  needToBuyGoods: '需购买商品',
  purchaseQuantity: '购买数量',
  freeMerchandise: '赠送商品',
  giftQuantity: '赠送数量',
  itemX: '第X件',
  itemXPlaceholder: '请输入2-9的正整数',
  discountType: '折扣类型',
  discountStrength: '折扣力度',
  discountStrengthPlaceholder: '请输入，支持两位小数',
  percentDiscount: '百分比折扣',
  fixedSellingPrice: '固定售卖价格',
  fixedPrice: '固定优惠价格',
  inputQuantityPlaceholder: '请输入1-999的正整数',
};
