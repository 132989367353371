/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type updateProductStatusBody =
  defs.product.FunctionTemplateProductStatusVo;
export type updateProductStatusOptions = Record<string, any>;

export type updateProductStatusResponse = Promise<defs.product.Response<void>>;

/**
 * @desc 上下架商品
 */
export function request(
  body: updateProductStatusBody,
  options?: updateProductStatusOptions,
): updateProductStatusResponse {
  const host = getEnvHost();
  const url = host + '/product/bo/function/template/product/updateStatus';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
