export default {
  name: 'Position code',
  path: 'belonging path',
  remark: 'remark',
  updatedByName: 'updated by',
  updatedTime: 'updated time',
  type: 'Type',
  node: 'node',
  position: 'position',
  createNode: 'Create node',
  createPos: 'Create position',
  parentNode: 'superior node',
  updateNode: 'Edit Node',
  import: 'Import',
  importModalTitle: 'Import Position Data',
  print: 'Print',
  autoCreateName: 'Auto Create',
  deleteWithChildNode:
    'There are child nodes or locations under the current node, which cannot be deleted!',
  deleteWithInventory:
    'The product inventory at the current location is not 0 and cannot be deleted!',
  confirmNodeDelete:
    'Are you sure to delete the node? It cannot be recovered after deletion.',
  confirmPosDelete:
    'Are you sure to delete this location? It cannot be recovered after deletion.',
};
