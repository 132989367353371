export default {
  overview: 'Overview',
  queryTerm: 'Search Keywords',
  trafficStatistics: 'Traffic Statistics',
  conversionStatistics: 'Conversion Statistics',
  orderStatistics: 'Order Statistics',
  system: 'BI Reports',
  permission: 'Access Management',
  operationLog: 'Operation Log',
  organization: 'Organization Management',
  account: 'User Account Management',
  menu: 'Menu Options Management',
  rolePermissions: 'Role/Permission',
  loginConfig: 'Login Config',
  appLaunchPageConfig: 'APP Launching Screen',
  appBootPageConfig: 'APP Landing Pages',
  appLoginPageConfig: 'APP Login Page Config',
  webLoginPageBannerConfig: 'Web Login Banner Config',
  serviceCoverageAreaConfig: 'Service Area Config',
  usingProtocolConfig: 'User Agreement',
  unServedAreaRegistration: 'No Service Area Registry',
  homeConfig: 'Home Page Config',
  hotSearchWordConfig: 'Hot Search Keyword Config',
  searchTermSearch: 'Search Keywords Query',
  searchThesaurusMaintenance: 'Search Keywords Index',
  appHomepageBannerConfig: 'APP Home Banner',
  appHomepageConfig: 'APP Home Screen Config',
  webHomepageBannerConfig: 'WEB Home Banner',
  webTopBannerConfig: 'WEB Top Banner',
  webHomepageConfig: 'WEB Home Screen Config',
  weeklySpecialsConfig: 'Weekly Sale Config',
  popularProducts: 'Best Sellers Config',
  newProductReleaseProduct: 'New Items Config',
  productRecommendation: 'Recommend Items Config',
  productRecommendationCreate: 'Create Config',
  productRecommendationEdit: 'Edit Config',
  homepagePopupConfig: 'Home Popup Window',
  content: 'Content Management',
  commentModeration: 'Comment Moderation',
  platformContentCategory: 'Platform Content Category',
  platformContentRelease: 'Platform Content Release',
  recipeContentCategory: 'Recipe Content Category',
  recipeContentRelease: 'Recipe Content Publish',
  helpCenter: 'Help Center',
  issueCategoryManagement: 'Issue Category Management',
  feedback: 'Feedback',
  prohibitedWords: 'Prohibited Words',
  memberInformation: 'Member Information Management',
  registerMember: 'Register Member Management',
  pointsDetail: 'Points Detail Query',
  pointsRule: 'Points Rule Config',
  membershipBenefits: 'Member Benefits Config',
  membershipLevel: 'Member Level Config',
  cancelMemberInformation: 'Canceled Member Information',
  order: 'Order Management',
  orderList: 'Order List',
  orderDetails: 'Order Details',
  acceptOrder: 'Accept Order Management',
  afterSaleList: 'AfterSale List',
  afterSaleDetails: 'AfterSale Details',
  outOfStockOrder: 'Out Of Stock Order',
  overtimeOrder: 'Overtime Order',
  orderSettings: 'Order Settings',
  marketingManagement: 'Marketing Management',
  couponActivities: 'Coupon Activities',
  issueCouponsManually: 'Issue Coupons Manually',
  automatedCouponIssuanceManagement: 'Auto Coupon Management',
  limitedTimeDirectDrop: 'Limited Time Direct Drop',
  buyGifts: 'Buy Gifts',
  issuedCoupon: 'Issued Coupon Management',
  destroyLogs: 'Destroyed Logs',
  items: 'Items Management',
  information: 'Information Management',
  smsM: 'SMS Management',
  bCategory: 'Background Category Management',
  fCategory: 'Front Desk Category Management',
  fCategoryDetail: 'Edit Category',
  productAttributeManagement: 'Product Attribute Management',
  attributeCategoryManagement: 'Attribute Category Management',
  productInformationManagement: 'Product Information Management',
  productDescriptionManagement: 'Product Description Management',
  productVariantManangement: 'Product Variant Management',
  productBrandManagement: 'Product Brand Management',
  productPolicyManagement: 'Product Policy Management',
  productDetails: 'Product Details',
  productTagManagement: 'Product Tags Management',
  store: 'Store Management',
  storeGroup: 'Store Group Management',
  storeInfo: 'Store Information Management',
  storeInfoDetail: 'Store Information Detail',
  offlinePointsDetail: 'Offline Points Details',
  createStore: 'Create Store',
  manuallyAdjustPoints: 'Manually Adjust Points',
  productManagement: 'Store Product Management',
  promotionManagement: 'Store Promotion Setting',
  storeIntroManagement: 'Store Introduction Setting',
  viewTriggerDetail: 'View Trigger Details',
  outgoingDocument: 'Outcoming Goods Search',
  incomingDocument: 'Incoming Goods Search',
  warehouseJob: 'Warehouse Job Management',
  warehouseManagement: 'Warehouse Management',
  inventoryManagement: 'Inventory Management',
  warehouseAdministration: 'Warehouse Administration',
  setting: 'System Setting Management',
  baseSettingManagement: 'Base Setting Management',
  appVersionManagement: 'APP Version Management',
  PlatformFeeManagement: 'Platform Fee Management',
  pdaAppVersionManagement: 'PDA App Version Management',
  shelfNodeManagement: 'Shelf Management',
  shelfPositionManagement: 'Shelf Position Management',
  pickingcartManagement: 'Picking Cart and Basket Management',
  message: 'Message Management',
  websiteMessageManagement: 'Website Message Management',
  mailMessageManagement: 'Mail Message Management',
  periodicMessageManagement: 'Periodic Message Management',
  messageCenter: 'Message Center',
  financialSystem: 'Financial System',
  settlementCycle: 'Settlement Cycle',
  settlementAccount: 'Settlement Account',
  billingStatementList: 'Billing Statement List',
  afterSalesListDetails: 'After-sales List Details',
  afterSalesProductDetail: 'After-sales Product Details',
  settlementTaskManagement: 'Settlement Task Management',
  settlementOrderManagement: 'Settlement Order Management',
  settlementOrderItemManagement: 'Settlement Order Product Detail',
  orderDaySummary: 'Order Day Summary',
  settlementTaskList: 'Settlement Task List',
  pointsFlowReport: 'Points Flow Report',
  paymentSummary: 'Payment Summary',
  pointsBudget: 'Points Budget',
  couponPromotion: 'Coupon Promotion List',
  settlementReport: 'Settlement Report',
  settlementSetting: 'Settlement Setting',
  settlementManagement: 'Settlement Management',
  pointsBudgetManagement: 'Points Management',
};
