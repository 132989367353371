/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type templateRemoveBody = number;
export type templateRemoveOptions = Record<string, any>;

export type templateRemoveResponse = Promise<defs.product.Response<void>>;

/**
 * @desc 删除功能区模板
 */
export function request(
  body: templateRemoveBody,
  options?: templateRemoveOptions,
): templateRemoveResponse {
  const host = getEnvHost();
  const url = host + '/product/bo/function/template/remove';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
