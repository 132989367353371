// 会员等级配置
export default {
  id: 'ID',
  enName: '英文名称',
  cnName: '简体中文',
  twName: '繁体中文',
  levelSymbol: '等级标识',
  levelType: '等级类型',
  memberBenefits: '会员权益',
  pointBenefits: '积分权益',
  upgradeRules: '升级规则',
  updater: '更新人',
  updateTime: '更新时间',
  status: '状态',
  operations: '操作',
  config: '配置',
  edit: '编辑',
  freeMembership: '免费会员',
  paidMembership: '付费会员',
  ruleName: '规则名称',
  ruleLogic: '规则逻辑',
  monthlyCoupon: '每月优惠券',
  monthlyIssuedCoupon: '每月发放优惠券数量',
  birthdayCoupon: '生日礼券',
  coupon: '优惠券',
  sameDayFreeShipping: '当日达免运',
  freeShippingThreshold: '免运门槛',
  expressFreeShipping: '快递免运',
  storePickup: '门店自提',
  switch: '是否开启',
  enjoyPromotions: '优享促销活动',
  enjoyVIPPreSaleRights: '尊享VIP预售权益',
  cumulativeOrderPaymentAmount: '累计订单支付金额',
  purchaseThreshold: '购买门槛',
  nextLevel: '下一级',
  orderRewards: '订单奖励',
  orderReview: '订单评论',
  inviteFriends: '邀请好友',
  emailSubscription: '邮箱订阅',
  smsSubscription: '短信订阅',
  perfectPersonalInformation: '完善个人信息',
  followSocialPlatformAccounts: '关注社交平台账号',
  purchasingRewardFullTimesPerMonth: '每月购买满次数得奖励',
  firstPurchaseReward: '首次购买奖励',
  rewardEvery$1Spent: '每消费$1奖励值',
  rewardEveryPost: '每发布1条奖励值',
  monthlyUpperLimit: '月上限值',
  perInviteReward: '每邀请1个奖励值',
  reward: '奖励值',
  perFellowReward: '每关注1个奖励值',
  purchases: '购买次数',
  newLevel: '新建等级',
  updateLevel: '编辑等级',
  on: '开启',
  off: '关闭',
  couponPlaceholder: '请选择优惠券',
  amountPlaceholder: '请输入金额',
  quantityPlaceholder: '请输入数量',
  nextLevelPlaceholder: '请选择下一级',
  enNameRule: '请输入英文字符组合',
};
