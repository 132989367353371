import { Tabs } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import { mergeArray } from '@/utils';
import ExcelLoad from './ExcelLoad';
import SelectedTable from './SelectedTable';
import TableSelect from './TableSelect';
import styles from './index.less';

type IProps = {
  onChang?: (
    products: defs.product.BackendProduct[],
    fileIds?: number[],
    batchId?: string,
  ) => void;
  onFileIdsChange?: (fileIds: number[]) => void;
  data?: defs.product.BackendProduct[];
  disabled?: boolean;
  params?: Record<string, any>;
  level?: number;
};

const mergeTableData = mergeArray<defs.product.BackendProduct>(
  (a, b) => a.variantId === b.variantId,
);

const SelectCard = (props: IProps) => {
  const {
    onChang,
    data,
    params,
    disabled = false,
    onFileIdsChange,
    level,
  } = props;
  const [tableV, setTableV] = useState<defs.product.BackendProduct[]>(
    data || [],
  );
  const [batchId, setBatchId] = useState<string | undefined>();
  const handleSelect = (values: defs.product.BackendProduct[]) => {
    setTableV(values);
    if (onChang) {
      onChang(values);
    }
  };
  const handleTableRemove = (newValue: defs.product.BackendProduct) => {
    const values = tableV.filter((i) => i.upc !== newValue.upc);
    setTableV(values);
    if (onChang) {
      onChang(values);
    }
  };
  const handleFiles = (files?: defs.promotion.Product[], batchId?: string) => {
    if (onFileIdsChange) {
      // console.log(files);
      if (files?.length) {
        const newList = files.map((el) => {
          return {
            ...el,
            variantId: el.upc,
            articleNumber: el.id,
            productNameEN: el.name,
            productId: el.id,
          };
        });
        const { result: composedList } = mergeTableData(tableV, newList);
        if (onChang) {
          onChang([...composedList], undefined, undefined);
        }
        setTableV([...composedList]);
      } else {
        if (onChang) {
          onChang([], undefined, batchId);
        }
        setBatchId(batchId);
      }
    }
  };
  const tabs = [
    {
      label: t<string>(`components.goodsSelect.title0002`),
      children: (
        <TableSelect
          onChang={handleSelect}
          data={tableV}
          disabled={disabled}
          params={params}
        />
      ),
      key: 'TableSelect',
    },
    {
      label: t<string>(`components.goodsSelect.title0013`),
      children: (
        <ExcelLoad onChange={handleFiles} disabled={disabled} level={level} />
      ),
      key: 'EXCELExport',
    },
  ];
  return (
    <div className={styles.selectCard}>
      {!disabled && (
        <div className={styles.selectCardItem}>
          <Tabs defaultActiveKey="TableSelect" items={tabs} />
        </div>
      )}
      <div
        className={styles.selectCardItem}
        style={disabled ? { width: '100%' } : {}}
      >
        <SelectedTable
          handleRemove={handleTableRemove}
          data={tableV}
          batchId={batchId}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
export default SelectCard;
