export default {
  title0001: '版本号',
  title0002: '新建版本',
  title0003: 'PDA 版本号',
  title0004: 'PDA 下载地址',
  title0005: '强制更新',
  title0006: '版本描述',
  title0007: '更新人',
  title0008: '更新时间',
  title0009: '操作',
  title0010: '编辑',
  title0011: '限输入200字符',
};
