export default {
  title0001: '新建启动页',
  title0002: '编辑启动页',
  title0003: '下架后前台将不可见，是否确认下架？',
  title0004: '上架成功',
  title0005: '操作',
  title0006: '编辑',
  title0007: '覆盖业务',
  title0008: 'O2O区域',
  title0009: '启动页名称',
  title0010: '内容',
  title0011: '内容类型',
  title0012: '有效期',
  title0013: '创建人',
  title0014: '创建时间',
  title0015: '更新人',
  title0016: '更新时间',
  title0017: '状态',
  title0018: '上传内容',
  title0019: '选取文件',
  title0020: '内容名称',
  title0021: '限制输入12个字',
  title0022: '有效时间',
  title0023: '确认提交',
  title0024: '图片上传jpg/png/gif文件；视频上传MP4，时长{{num}}秒',
  title0025: '跳转链接',
};
