/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type importFileFormData = FormData;
export type importFileOptions = Record<string, any>;

export type importFileResponse = Promise<defs.search.Response<boolean>>;

/**
 * @desc 导入商品搜索词 合并导入
 */
export function request(
  formData: importFileFormData,
  options?: importFileOptions,
): importFileResponse {
  const host = getEnvHost();
  const url = host + '/search/bo/search_tag/product/import';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
