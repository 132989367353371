export default {
  acceptOrder: 'Accept Order',
  top: 'Top',
  orderNo: 'Order No',
  paymentOrderNo: 'Payment Order NO',
  userID: 'User ID',
  userPhoneNO: 'User Phone',
  memberCardNo: 'Member Card NO',
  deliveryMethod: 'Delivery Method',
  orderStatus: 'Order Status',
  orderChannel: 'Order Channel',
  store: 'Store',
  orderTime: 'Order Time',
  operations: 'Out of stock order details',
  operations1: 'Preview out of stock order details',
  amountReceivable: 'Amount Receivable',
  afterSalesOrderNO: 'After Sales Order NO',
  afterSale: 'After Sales',
  title0001: 'Product Name',
  title0002: 'UPC',
  title0003: 'Product ID',
  title0004: 'Category',
  title0005: 'Product Type',
  title0006: 'Order Quantity',
  title0007: 'Picking Quantity',
  title0008: 'Order Item Amount',
  title0009: 'Picking Item Amount',
  title0010: 'Picking Result',
  title0011: 'Products',
  title0012: 'Min Amount Receivable',
  title0013: 'Max Amount Receivable',
};
