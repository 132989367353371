export default {
  title0001: '商品标题',
  title0002: 'Article Number',
  title0003: 'UPC编码',
  title0004: '后台分类',
  title0005: '状态',
  title0006: '批量查询',
  title0007: '导入限购设置',
  title0008: '商品ID',
  title0009: '商品繁体标题',
  title0010: '商品英文标题',
  title0011: '缩略图',
  title0012: '所属后台分类',
  title0013: 'UPC ',
  title0014: '商品明细',
  title0015: '更新时间',
  title0016: '查询类型',
  title0017: '每行一个编码',
  title0018: '查看子UPC',
  title0019: '子UPC',
  title0020: '导入',
  title0021: '选择导入文件',
  title0022: '选择文件',
  title0023: '支持上传xls/xlsx文件',
  title0024: '点此下载导入模板',
  title0025: '导入完成',
  title0026: '本次导入成功 {{success}} 条，失败 {{error}} 条。',
  title0027: '点此下载失败记录数据',
  title0028: '启用',
  title0029: '禁用',
  title0030: '查看明细',
  title0031: '查看类型',
};
