export default {
  acceptOrder: 'Accept Order',
  top: 'Top',
  orderNo: 'Order No',
  paymentOrderNo: 'Payment Order NO',
  userID: 'User ID',
  userPhoneNO: 'User Phone',
  memberCardNo: 'Member Card NO',
  deliveryMethod: 'Delivery Method',
  orderStatus: 'Order Status',
  orderChannel: 'Order Channel',
  store: 'Store',
  orderTime: 'Order Time',
  operations: 'Operations',
  amountReceivable: 'Amount Receivable',
  afterSalesOrderNO: 'After Sales Order NO',
  afterSale: 'After Sales',
  estDeliveredTime: 'Delivered Time',
  batchToast: 'orders are received',
};
