export default {
  id: '分组ID',
  groupName: '分组名称',
  storeCount: '门店数量',
  storeType: '门店类型',
  updater: '更新人',
  updateTime: '更新时间',
  operations: '操作',
  edit: '编辑',
  status: '状态',
  create: '新增',
  storeID: '门店ID',
  storeName: '门店名称',
  storeDetail: '门店明细',
  confirm: '你确定要进行此操作吗？',
  storeTag: '门店标签',
};
