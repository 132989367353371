export default {
  title0001: '新建分类',
  title0002: '编辑分类',
  title0003: '分类名称',
  title0004: '分类状态',
  title0005: '操作',
  title0006: '编辑',
  title0007: '分类ID',
  title0008: '简体中文分类名称',
  title0009: '繁体中文分类名称',
  title0010: '英文分类名称',
  title0011: '内容数',
  title0012: '更新人',
  title0013: '更新时间',
  title0014: '状态',
  title0015: '内容分类ID',
  title0016: '请输入，限10个字',
  title0017: '请输入，限50个字符',
  title0018: '限输入字母+数字组合',
  title0019: '启用成功',
  title0020: '将禁用，请确认',
  title0021: '启用',
  title0022: '禁用',
};
