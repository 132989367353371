export default {
  roleName: 'Role Name',
  roleStatus: 'Role Status',
  newRole: 'New Role',
  editRole: 'Edit Role',
  associatedUsers: 'Associated Users',
  validityPeriod: 'Validity Period',
  updater: 'Updater',
  updatedTime: 'Updated Time',
  organizationPermissions: 'Organization Permissions',
  operationPermissions: 'Operation Permissions',
  dataPermissions: 'Data Permissions',
  configure: 'Configure',
  assign: 'Assign',
  relatedPersonnelDetails: 'Related Personnel Details',
  longTerm: 'Long Term',
  shortTerm: 'Short Term',
  range: 'Range',
  organization: 'Organizations',
  extendPermissions: 'Inheritance Permissions',
  operationPermissionSettings: 'Operation Permission Settings',
  dataPermissionSettings: 'Data Permission Settings',
  menu: 'Menu',
  store: 'Store',
  warehouse: 'Warehouse',
  bizModule: 'BizModules',
  lastLoginTime: 'Last Login Time',
  remove: 'Remove',
  enable: 'Enable',
  disable: 'Disable',
  disableRoleTip:
    'After the role is disabled, the corresponding user will no longer have the corresponding permission. Are you sure?',
  batchExport: 'Batch Export',
  import: 'Import',
  roleList: 'role list',
  importData: 'Import Data',
  platform: 'Platform',
  longTermEffective: 'Long Term Effective',
  save: 'Save',
};
