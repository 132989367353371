// 注册会员管理
export default {
  id: '会员 ID',
  nickname: '会员昵称',
  avatar: '头像',
  phone: '手机号码',
  gender: '性别',
  birthday: '生日',
  email: '邮箱',
  language: '设置语言',
  inviter: '邀请人',
  memberLevel: '会员等级',
  paidMember: '付费会员',
  registerSource: '注册来源',
  points: '积分',
  registerTime: '注册时间',
  status: '状态',
  operations: '操作',
  accountStatus: '账号状态',
  inviterPhoneNo: '邀请人手机号码',
  disableAccountTip: '禁用后该用户将不再能登录使用，是否确定禁用？',
  enable: '停用召回',
  disable: '停用',
  disableCycle: '禁用周期',
  permanentlyDisabled: '永久禁用',
  shortTermDisabled: '短期禁用',
  inputPlaceholder: '请输入天数',
  detail: '查看明细',
  pointDetails: '积分明细',
  pointsBalance: '积分余额',
  occurredTime: '发生时间',
  projectType: '事项类型',
  pointsIncrease: '积分增加',
  validUntil: '有效期至',
  pointsDecrease: '积分减少',
  balance: '余额',
  remark: '备注',
  freeMember: '免费会员',
  memberCardNO: '会员卡号',
  preMemberCardNo: '请输入起始会员卡号',
  sufMemberCardNo: '请输入终止会员卡号',
  cardNoSourceName: '会员卡号来源',
  phoneAndEmail: '历史手机号 & 邮箱',
  negativePointsCount: '负积分次数',
  disabledTime: '禁用时间',
  pointsChangeChannel: '积分变更渠道',
  storeNO: '门店编号',
  currentPointsBalance: '当前积分余额',
  freeze: '冻结',
  unfreeze: '解冻',
  logout: '注销',
  view: '查看',
  disableRecall: '停用召回',
  operationTime: '操作时间',
  operator: '操作人',
  operationReason: '操作原因',
  logoutReason: '注销原因',
  operationRecords: '操作记录',
  logoutTip: `注销后：
1.用户不能在线上使用该账户进行登录。
2.用户在线下门店将不能使用该账户进行积分。
3.用户账户中的积分将全部被清零。
4.账户交易记录、身份信息、账户信息将被清空。
`,
  checkLogoutTip: '此账户存在处理中的业务，暂不能进行注销操作！',
  orders: '该用户账户中存在以下处理中订单',
  afterSale: '该用账户中存在以下处理中售后单',
  orderNO: '订单号',
  afterSalesOrderNO: '售后单号',
  orderStatus: '订单状态',
  afterSalesOrderStatus: '售后单号',
  freezeCycle: '冻结周期',
  timeRange: '时间范围',
  reminderTime: '提醒时间',
  checkFreezeTip: '此账户存在处理中的业务，暂不能进行冻结操作！',
  freezeTip:
    '账户冻结后，用户不能使用该账户在线上登录，用户不能使用该账户中的积分卡在线下积分，是否确定冻结？',
  freezeForever: '永久冻结',
  shortTermFreeze: '短期冻结',
  days: '天',
  disableTip: '停用后该用户将不再能登录使用，是否确定禁用？',
  disableOrderTip: '此账户存在处理中的业务，暂不能注销！',
  bind: '绑定',
  unbind: '解绑',
  membershipCardNOAndOperation: '会员卡号&操作',
  verify: '验证',
  sendVerificationCode: '发送验证码',
  historicalPhoneNO: '历史手机号',
  historicalEmail: '历史邮箱',
};
