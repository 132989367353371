export default {
  taskName: 'Task Name',
  taskCode: 'Task Code',
  createTime: 'Create Time',
  autoCouponManagement: 'Auto Coupon Management',
  startTime: 'Start Time',
  endTime: 'End Time',
  addAutoCouponTask: 'Add Auto Coupon Task',
  editAutoCouponTask: 'Edit Auto Coupon Task',
  search: 'Search',
  reset: 'Reset',
  couponTaskDescription: 'Coupon Task Description',
  triggerEvent: 'Trigger Event',
  taskStatus: 'Task Status',
  action: 'Action',
  edit: 'Edit',
  log: 'Log',
  disable: 'Disable',
  enable: 'Enable',
  taskDescriptionBrief: 'Task Description Brief',
  selectCouponsToIssue: 'Select Coupons to Issue',
  selectedCoupons: 'Selected Coupons',
  numberOfCouponsToIssue: 'Number of Coupons to Issue',
  pieces: 'pieces',
  coupon: 'Coupon',
  delete: 'Delete',
  submit: 'Submit',
  cancel: 'Cancel',
  operatorName: 'Operator Name',
  operationName: 'Operation Name',
  operationTime: 'Operation Time',
  active: 'Active',
  inactive: 'Inactive',
  pleaseEnterAQuantity: 'Please enter a quantity',
  sheet: 'Sheet',
};
