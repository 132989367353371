/// <reference path="../../api.d.ts" />
import sendRequest, { getEnvHost } from '@/utils/request';

export type templateInsertBody = defs.product.FunctionTemplateVo;
export type templateInsertOptions = Record<string, any>;

export type templateInsertResponse = Promise<defs.product.Response<void>>;

/**
 * @desc 新增功能区模板 不传Id
 */
export function request(
  body: templateInsertBody,
  options?: templateInsertOptions,
): templateInsertResponse {
  const host = getEnvHost();
  const url = host + '/product/bo/function/template/insert';
  const fetchOption = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...options,
  };

  return sendRequest(url, fetchOption);
}
